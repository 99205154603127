import React, { useState } from "react";
//Styles
import Styles from "./styles";
//Components
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import AddVenueModal from "../../../Modals/AddUpdateVenue/AddVenue";
import UpdateVenueModal from "../../../Modals/AddUpdateVenue/UpdateVenue";
import { useToastModal } from "../../../../context/ToastModalContext";

const CompanyVenues = ({
  companyId,
  rowToggle,
  activeRowId,
  venues,
  createVenue,
  companyName,
  updateVenue,
  deleteVenue,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
}) => {
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const [showUpdateVenueModal, setShowUpdateVenueModal] = useState(false);
  const [venueObj, setVenueObj] = useState({});

  const { addToast, addModal } = useToastModal();

  const handleClickAddVenue = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setShowAddVenueModal((prev) => !prev);
    } else {
      addToast("You must be a Company Admin to add a Venue!", "error");
    }
  };
  const handleClickUpdateVenue = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, venue.companyId, venue.id)) {
      setVenueObj({ venueName: venue.venueName, venueId: venue.id });
      setShowUpdateVenueModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to update Venue!", "error");
    }
  };
  const handleClickDeleteVenue = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, venue.companyId, venue.id)) {
      addModal(`Delete ${venue.venueName} ? `, false, () => {
        deleteVenue({
          variables: {
            id: venue.id,
          },
        });
      });
    } else {
      addToast("You must be a Venue Admin to delete Venue!", "error");
    }
  };

  return (
    rowToggle &&
    companyId === activeRowId && (
      <div>
        {[...venues]
          .sort((a, b) => (a.venueName > b.venueName ? 1 : b.venueName > a.venueName ? -1 : 0))
          .map((venue, i) => {
            return (
              <Styles.VenueRowContainer key={venue.id}>
                <Styles.VenuesRow lastRow={venues.length - 1 === i ? +true : +false}>
                  {!venue.venueVoid ? (
                    <Styles.VenueName>{venue.venueName}</Styles.VenueName>
                  ) : (
                    <Styles.VenueName>
                      {venue.venueName}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          fontFamily: "PoppinsRegular",
                          color: "#d22f2f",
                        }}
                      >
                        (deleted)
                      </span>
                    </Styles.VenueName>
                  )}

                  <Styles.ActionIconsContainer>
                    <Styles.EditIcon onClick={() => handleClickUpdateVenue(venue)} />

                    {/* <Styles.DeleteIcon /> */}
                    {!venue.venueVoid && (
                      <Styles.DeleteIcon onClick={() => handleClickDeleteVenue(venue)} />
                    )}
                  </Styles.ActionIconsContainer>
                </Styles.VenuesRow>
              </Styles.VenueRowContainer>
            );
          })}
        <Styles.AddVenueBtnContainer>
          <BlueBtn label={"Add Venue"} width={165} handleClick={handleClickAddVenue} />
        </Styles.AddVenueBtnContainer>
        <AddVenueModal
          showModal={showAddVenueModal}
          setShowModal={setShowAddVenueModal}
          createVenue={createVenue}
          companyId={companyId}
          title={`Add Venue to ${companyName}`}
        />
        <UpdateVenueModal
          showModal={showUpdateVenueModal}
          setShowModal={setShowUpdateVenueModal}
          updateVenue={updateVenue}
          companyId={companyId}
          venueObj={venueObj}
          title={"Update Venue"}
        />
      </div>
    )
  );
};

export default CompanyVenues;

import React, { useState, useEffect } from "react";
//Styles
import Styles from "./styles";
import { useParams, useOutletContext } from "react-router-dom";
//Icons
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera_icon.svg";
//Components

import CountCard from "../../../components/Shared/CountCard";
import { useLazyQuery } from "@apollo/client";

import { LIST_VENUES } from "../../../graphql/graph";

import VenuesAreasCameras from "./VenuesAreasCameras";
import DockerControllers from "./DockerControllers";
import Brokers from "./Brokers";
import SpinnerComponent from "../../../components/Loader";
import Training from "./Training";
import ApiManager from "./ApiManager";

const CompanyDashboard = () => {
  const { companyId } = useParams();
  const [companiesList] = useOutletContext();
  const [camerasCount, setCamerasCount] = useState("");
  const [venuesCount, setVenuesCount] = useState("");
  const [inferenceCount, setInferenceCount] = useState(0);
  const [currentDashboardCompany, setCurrentDashboardCompany] = useState([]);
  const [visibleVenueIds, setVisibleVenueIds] = useState([]);

  // console.log("companiesList", companiesList);

  const [getVenues, { data: venuesData, loading: venuesDataLoading }] = useLazyQuery(LIST_VENUES, {
    context: { clientName: "graph" },
  });

  // console.log("venuesData", venuesData);

  const venuesList = venuesData?.listVenues?.data || [];

  useEffect(() => {
    if (venuesData?.listVenues?.data) {
      const newVisibleVenueIds = venuesData?.listVenues?.data.map((venue) => {
        return {
          id: venue.id,
          isSelected: true,
        };
      });
      console.log(newVisibleVenueIds);
      setVisibleVenueIds(newVisibleVenueIds);
    }
  }, [venuesData]);

  useEffect(() => {
    if (companyId && companiesList) {
      const currentCompany = companiesList.find((company) => company.id === companyId);
      setCurrentDashboardCompany(currentCompany);

      handleVenueFetch(currentCompany);
    }
  }, [companiesList, companyId]);

  const handleVenueFetch = async (currentCompany) => {
    // get all venue ids and fetch venue data
    const venueIds = currentCompany?.Venues?.map((venue) => venue.id);

    // console.log("handleVenueFetch venueIds", venueIds);

    if (venueIds) {
      await getVenues({
        variables: {
          venueIds,
        },
      });
    }
  };

  useEffect(() => {
    if (currentDashboardCompany) {
      setCamerasCount(currentDashboardCompany.machineCount);
      setVenuesCount(currentDashboardCompany?.Venues?.length);
      setInferenceCount(currentDashboardCompany?.inferenceCount);
      // console.log({ currentDashboardCompany });
    }
  }, [currentDashboardCompany]);

  const handleVenueSelect = (venueId) => {
    const updatedVisibleVenueIds = visibleVenueIds.map((venue) => {
      if (venue.id === venueId) {
        return {
          ...venue,
          isSelected: !venue.isSelected,
        };
      }
      return venue;
    });
    setVisibleVenueIds(updatedVisibleVenueIds);
  };

  const renderVenueCards = () => {
    if (venuesList.length <= 1) {
      return null;
    }
    return venuesList.map((venue) => {
      // find venue in visibleVenueIds
      const visibleVenue = visibleVenueIds.find((visibleVenue) => visibleVenue.id === venue.id);
      return (
        <Styles.VenueCard
          key={venue.id}
          active={visibleVenue ? visibleVenue.isSelected : true}
          onClick={() => handleVenueSelect(venue.id)}
        >
          {venue.venueName}
        </Styles.VenueCard>
      );
    });
  };

  const renderCards = () => {
    return (
      <Styles.CardsContainer>
        <CountCard title={"No of venues"} count={venuesCount} blueBack />
        <CountCard
          title={"Total No of Cameras"}
          count={camerasCount ? camerasCount : 0}
          icon={<CameraIcon />}
          blueBack
        />
        <CountCard
          title={"Total No of Inferences"}
          count={inferenceCount ? inferenceCount : 0}
          icon={<CameraIcon />}
          blueBack
        />
      </Styles.CardsContainer>
    );
  };

  // console.log("CompanyDashboard", venuesData, dockersData);

  const companyName = currentDashboardCompany?.companyName || "";

  // console.log("venuesList", venuesList);

  return (
    <Styles.CompanyDashboardContainer>
      <Styles.Header>
        <Styles.Title>{companyName}</Styles.Title>
      </Styles.Header>
      {renderCards()}
      <DockerControllers venuesList={venuesList} />
      <Styles.VenueCardContainer>{renderVenueCards()}</Styles.VenueCardContainer>
      {venuesDataLoading ? (
        <Styles.SpinnerContainer>
          <SpinnerComponent width={"5rem"} height={"5rem"} />
        </Styles.SpinnerContainer>
      ) : (
        <VenuesAreasCameras venuesList={venuesList} visibleVenueIds={visibleVenueIds} />
      )}
      <Brokers />
      <Training venuesList={venuesList} />
      <ApiManager />
    </Styles.CompanyDashboardContainer>
  );
};

export default CompanyDashboard;

import styled from "styled-components/macro";

const styles = {
  CompanyDashboardContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    cursor: pointer;
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 22px;
    color: #455a64;
    margin: 0;
  `,
  SubTitle: styled.h3`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
  CardsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `,
  DockerContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  VenueCardContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  `,
  OuterContainer: styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #f3f2f2;
    box-shadow: 0px 3.89036px 9.72591px rgb(0 0 0 / 10%);
    border-radius: 2px;
    border: 1px solid #c4c4c4;
    padding: 10px;
  `,
  Line: styled.div`
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 1px;
    background-color: #c4c4c4;
    margin-top: 10px;
  `,
  VenueContainer: styled.div`
    width: 100%;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  `,
  VenueName: styled.h3`
    font-family: "Poppins700";
    font-size: 16px;
    color: #455a64;
  `,
  AreaContainer: styled.div`
    padding-left: 25px;
  `,
  AreaName: styled.h3`
    font-family: "Poppins700";
    font-size: 15px;
    color: #455a64;
  `,
  RowHeader: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CamerasRows: styled.div`
    padding-left: 30px;
    display: flex;
    overflow-x: auto;
    // overflow-y: hidden;
  `,
  CheckboxNameContainer: styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
  `,
  RowIconsContainer: styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
  `,
  ActionButtonsWrapper: styled.div`
    display: flex;
    gap: 1rem;
  `,
  SmallButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
  `,
  IconButton: styled.div`
    margin-left: 10px;
  `,
  VenueCard: styled.div`
    background: #ffffff;
    box-shadow: 0px 4.68571px 11.7143px rgb(0 0 0 / 10%);
    font-family: "Poppins700";
    font-size: 12px;
    line-height: 16px;
    color: #464646;
    border-radius: 5px;
    border: ${(props) => (props.active ? "2px solid #999" : "none")};
    width: 150px;
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
  `,
  SpinnerContainer: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Section: styled.div`
    margin-bottom: 1rem;
  `,
  TableTitle: styled.h3`
    font-family: Poppins600;
    font-size: 16px;
    color: #455a64;
    margin: 0;
  `,
  Actions: styled.div`
    display: flex;
    gap: 10px;
    flex-display: flex-col;
    flex-grow: 1;
    justify-content: flex-end;
  `,
};

export default styles;

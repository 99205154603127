import React from "react";
//Styles
import Styles from "./styles";

const LicencesTable = ({ numberOfVenueCameras }) => {
  return (
    <Styles.LicencesTable>
      <Styles.TableHeader>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Active Licences</Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Inactive Licences</Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Total</Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
      </Styles.TableHeader>
      <Styles.TableRow>
        <Styles.TableRowEntriesContainer>
          <Styles.TableRowEntries>{numberOfVenueCameras}</Styles.TableRowEntries>
        </Styles.TableRowEntriesContainer>
        <Styles.TableRowEntriesContainer>
          <Styles.TableRowEntries>{/*  {numberOfVenueCameras} */}0</Styles.TableRowEntries>
        </Styles.TableRowEntriesContainer>
        <Styles.TableRowEntriesContainer>
          <Styles.TableRowEntries>{numberOfVenueCameras}</Styles.TableRowEntries>
        </Styles.TableRowEntriesContainer>
      </Styles.TableRow>
    </Styles.LicencesTable>
  );
};

export default LicencesTable;

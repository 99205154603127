import React from "react";
// Styles
import Styled from "./QuestionForm.styled";
// Components
import FormField from "../FormField/FormField";

const QuestionForm = ({ control, handleAddQuestion }) => {
  return (
    <Styled.InputsContainer>
      <Styled.InputsInnerContainer>
        <FormField
          control={control}
          name="fieldName"
          label="Field Name"
          placeholder="e.g. client_name"
        />
        <FormField
          control={control}
          name="fieldDescription"
          label="Field Description"
          placeholder="e.g. Name of the client"
        />
      </Styled.InputsInnerContainer>

      <Styled.AddQuestionBtn type="button" onClick={handleAddQuestion}>
        Add Question
      </Styled.AddQuestionBtn>
    </Styled.InputsContainer>
  );
};

export default QuestionForm;

import React, { useState, useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const InviteUserToVenueModal = ({
  showModal,
  setShowModal,
  title,
  venueId,
  companyId,
  users,
  /* userId, */
  inviteUserToVenueMutation,
}) => {
  const [selectedUserOnChange, setSelectedUserOnChange] = useState("");
  const [addUserByEmail, setAddUserByEmail] = useState(false);

  //needed inside to dynamically set required field based on modal type
  const inviteUserToVenueSchema = yup.object().shape({
    /*  user: users
      ? yup.string().required("User is a required field.")
      : yup.string().notRequired(),
    userEmail: yup
      .string()
      .email("Please type a valid email.")
      .required("Email is a required field."), */
    permissionType: yup.string().required("Permission name is a required field."),
  });

  const {
    register,
    handleSubmit,
    reset,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inviteUserToVenueSchema),
  });

  useEffect(() => {
    if (selectedUserOnChange === "newUser") {
      setAddUserByEmail(true);
    } else if (selectedUserOnChange !== "newUser" && addUserByEmail) {
      unregister("newUser");
      setAddUserByEmail(false);
    }
  }, [addUserByEmail, selectedUserOnChange, unregister]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (inviteUserToVenueFormData) => {
    inviteUserToVenueMutation({
      variables: {
        companyId,
        userEmail: selectedUserOnChange === "newUser" ? inviteUserToVenueFormData.userEmail : null,
        userId: selectedUserOnChange === "newUser" ? null : inviteUserToVenueFormData.user,
        permissionType: "BY_VENUE",
        venueIds: JSON.stringify([
          {
            id: venueId,
            permissionType: inviteUserToVenueFormData.permissionType,
          },
        ]),
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={title}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Select/Add user</Styles.Label>
            <Styles.Select
              name="user"
              id="user"
              {...register("user")}
              defaultValue=""
              onChange={(e) => setSelectedUserOnChange(e.target.value)}
            >
              <option value="" disabled>
                Select user
              </option>
              <option value="newUser">Invite new user (by email) +</option>
              {users?.map((user) => {
                return (
                  <option value={user.userId} key={user.userId}>
                    {user.userFirstName}
                    {user.userLastName}
                  </option>
                );
              })}
            </Styles.Select>
            <Styles.SubmitError>{errors.user?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          {addUserByEmail ? (
            <Styles.InputAndErrorContainer>
              <Styles.Label>Email</Styles.Label>
              <Styles.Input
                type="text"
                name="userEmail"
                placeholder="New user email "
                {...register("userEmail")}
              />
              <Styles.SubmitError>{errors.userEmail?.message}</Styles.SubmitError>
            </Styles.InputAndErrorContainer>
          ) : null}

          <Styles.InputAndErrorContainer>
            <Styles.Select
              name="permissionType"
              id="permissionType"
              {...register("permissionType")}
              defaultValue=""
            >
              <option value="" disabled>
                Select venue permission
              </option>
              <option value="ADMIN">Admin</option>
              <option value="VIEW_ONLY">View Only</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.permissionType?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <Styles.SubmitInput type="submit" id="submit" value="Invite" />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default InviteUserToVenueModal;

import styled from "styled-components/macro";

const styles = {
  FormWrapper: styled.div`
    background-color: #ffffff;
    padding: 20px;
    margin: 3rem auto;
    max-width: 750px;
    border: 1px solid #ddd;
    border-radius: 5px;
  `,
  FormHeading: styled.h1`
    font-family: "Poppins700";
    font-size: 30px;
    color: #464646;
    text-align: center;
    padding: 10px 10px;
    margin-bottom: 30px;
  `,
  Form: styled.form`
    margin-top: 3rem;
  `,
  ErrorMessage: styled.span`
    color: red;
    font-size: 12px;
  `,
  SubmitInput: styled.input`
    font-family: "Poppins600";
    font-size: 14px;
    text-decoration: none;
    background-color: #e6866e;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 40px;
    width: 210px;
    border: none;
    border-radius: 100px;
  `,
};

export default styles;

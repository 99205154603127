import React, { useState, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import Styles from "./styles";
import FileAssetCard from "../FileAssetCard";
import ProgressBar from "../ProgressBar";
import { getUri } from "../../configs/configEnviroment";

const defaultFileTypes = [
  "PT",
  "PTH",
  "H5",
  "CKPT",
  "ONNX",
  "WEIGHTS",
  "TFLITE",
  "PB",
  "BIN",
  "ENGINE",
  "TRT",
  "XML",
  "CFG",
  "JSON",
  "PNG",
  "ZIP",
];

// eslint-disable-next-line react/display-name
const FileUpload = forwardRef((props, ref) => {
  const {
    title,
    fileTypes,
    deleteFile,
    selectedFiles,
    setSelectedFiles,
    selectedFilesError,
    setSelectedFilesError,
    multiple = true,
  } = props;
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (file) => {
    if (multiple) {
      setSelectedFiles((prev) => [...prev, ...file]);
    } else {
      setSelectedFiles([file]);
    }

    setSelectedFilesError("");
  };

  const handleFileDelete = async (file) => {
    const { id, name } = file;
    if (!id) {
      setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.name !== name));
    } else {
      deleteFile(id);
    }
  };

  useImperativeHandle(ref, () => ({
    async handleModelFileUpload(modelVersionId) {
      const formData = new FormData();
      formData.append("modelVersionId", modelVersionId);
      selectedFiles.forEach((file) => {
        if (!file.id) {
          formData.append("models", file);
        }
      });
      if (formData.getAll("models").length === 0) {
        return;
      }
      const uploadUrl = `https://${getUri()}/upload/model`;
      return await handleFileUpload(formData, uploadUrl);
    },

    async handleAnnotationFileUpload(annotationRequestId) {
      const formData = new FormData();
      formData.append("annotationRequestId", annotationRequestId);
      selectedFiles.forEach((file) => {
        if (!file.id) {
          formData.append("file", file);
        }
      });
      const uploadUrl = `https://${getUri()}/upload/annotationData`;
      return await handleFileUpload(formData, uploadUrl);
    },

    async handlePdfFileUpload() {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        if (!file.id && file.type === "application/pdf") {
          formData.append("file", file);
        }
      });
      const uploadUrl = `https://${getUri()}/upload/inferenceFile`;
      return await handleFileUpload(formData, uploadUrl);
    },
  }));

  const handleFileUpload = async (formData, uploadUrl) => {
    const token = localStorage.getItem("access_token");
    const config = {
      method: "post",
      url: uploadUrl,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    };

    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <Styles.Container>
      <Styles.Label>{title || "File Upload"}</Styles.Label>
      <FileUploader
        maxSize={500}
        onTypeError={(type) => alert(`File type ${type} not allowed`)}
        multiple={multiple}
        handleChange={handleFileChange}
        name="file"
        types={fileTypes || defaultFileTypes}
      >
        <Styles.DragAndDropContainer>
          <Styles.Label>Drag and drop your {multiple ? "files" : "file"} here</Styles.Label>
          <Styles.DragAndDropFileContainer>
            {[...selectedFiles].map((file) => (
              <FileAssetCard
                key={file.name || file.id}
                file={file}
                handleFileDelete={handleFileDelete}
              />
            ))}
          </Styles.DragAndDropFileContainer>
        </Styles.DragAndDropContainer>
      </FileUploader>
      <Styles.SubmitError>{selectedFilesError}</Styles.SubmitError>
      {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
    </Styles.Container>
  );
});

export default FileUpload;

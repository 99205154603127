import React, { useContext } from "react";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
//Context
import { AuthContext } from "../../../context/authContext";

const NavDropdown = ({ setDropdown, userRole }) => {
  const { handleLogOut } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClickLogOut = () => {
    handleLogOut();
    setDropdown(false);
    navigate("/");
  };

  /* const dashboardNavigationRoute = userRole === "SUPERADMIN" ? "padme/manage-groups" : "padme/admin-dashboard"; */

  return (
    <Styles.DropDownContainer>
      <Styles.DropDownLink to="manage-companies" bordertop={+true}>
        <Styles.DashboardIcon />
        <Styles.LinkText>Dashboard</Styles.LinkText>
      </Styles.DropDownLink>
      <Styles.DropDownLink to="settings/profile-settings">
        <Styles.ProfileIcon />
        <Styles.LinkText>My Profile</Styles.LinkText>
      </Styles.DropDownLink>
      <Styles.DropDownItemContainer borderbottom={+true} onClick={handleClickLogOut}>
        <Styles.LogoutIcon />
        <Styles.DropDownItem>Logout</Styles.DropDownItem>
      </Styles.DropDownItemContainer>
    </Styles.DropDownContainer>
  );
};

export default NavDropdown;

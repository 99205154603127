import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { containerGrayScrollbar } from "../../styles/globalStyles";

const styles = {
  PageContainer: styled.div`
    display: flex;
    justify-content: center;
    background: radial-gradient(
      circle at 0% 51%,
      rgba(55, 158, 165, 0.12) 0%,
      rgba(229, 229, 229, 1) 100%
    );
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 4rem;
    @media screen and (max-width: 950px) {
      padding: 0 2rem 0 0;
    }
  `,
  DashboardContainer: styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    @media screen and (max-width: 950px) {
      flex-direction: column;
    }
  `,

  ToggleMenuContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ showSideMenu }) => (showSideMenu ? "flex-end" : "flex-start")};
    padding: 0.5rem 0.5rem 0.5rem 0;
    cursor: pointer;
    height: 2rem;
    @media screen and (max-width: 950px) {
      padding: 0.5rem;
    }
  `,

  SideMenuContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ showSideMenu }) => (showSideMenu ? "250px" : "35px")};
    border-right: 1px solid #c4c4c4;

    @media screen and (max-width: 950px) {
      position: absolute;
      background: ${({ showSideMenu }) =>
        showSideMenu
          ? `radial-gradient(
        circle at 0% 51%,
        rgba(55, 158, 165, 0.12) 0%,
        rgba(229, 229, 229, 1) 100%
      )`
          : "none"};
      background-color: ${({ showSideMenu }) => (showSideMenu ? "#ffffff" : "none")};
      height: ${({ showSideMenu }) => (showSideMenu ? "100%" : "none")};
      overflow-y: auto;
      ${containerGrayScrollbar}
      border-right: ${({ showSideMenu }) => (showSideMenu ? "1px solid #c4c4c4" : "none")};
      width: ${({ showSideMenu }) => (showSideMenu ? "250px" : "45px")};
      z-index: 900;
    }
  `,
  SideMenu: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    display: ${({ showSideMenu }) => (showSideMenu ? "block" : "none")};
  `,
  MainOutletContainer: styled.div`
    min-height: calc(100vh - 105px);
    width: 100%;
    padding: 2rem 0 2rem 2rem;
    box-sizing: border-box;
  `,
  LinkContainer: styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    width: ${(props) => (props.activeSubtab ? "80%" : "100%")};
    background: ${(props) =>
      props.activetab ? "#5ca6b3" : props.activeSubtab ? "#FFFFFF" : "none"};
    border: ${(props) => (props.activeSubtab ? "0.780669px solid #FFFFFF" : "none")};
    border-radius: ${(props) =>
      props.activeTab ? "8px" : props.activeSubtab ? "7.80669px" : "none"};
    box-shadow: ${(props) =>
      props.activeSubtab ? "0px 1.56134px 3.90335px 2.34201px rgba(0, 0, 0, 0.05)" : "none"};

    & :hover {
      color: ${(props) => (props.activetab ? "none" : "#000000")};
    }
  `,
  Link: styled(Link)`
    font-family: PoppinsRegular;
    font-size: 14px;
    font-weight: ${({ venueactive }) => venueactive && "bold"};
    color: ${({ activetab, venuenotactive, venueactive }) =>
      activetab ? "#FFFFFF" : venuenotactive ? "#909090" : venueactive ? "#5CA6B3" : "#455a64"};
    text-decoration: none;
    padding: ${({ isvenue }) => (isvenue ? "0 1.5rem" : "0 0.5rem")};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `,
  IconAndLink: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  SearchAndAddContainer: styled.div`
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
  `,
};

export default styles;

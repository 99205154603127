import styled from "styled-components/macro";

const styles = {
  FormContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    padding: 4rem;
    @media screen and (max-width: 768px) {
      padding: 4rem 3rem;
    }
    @media screen and (max-width: 480px) {
      padding: 2rem 1.5rem;
    }
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  `,
  Input: styled.input`
    border: 1px solid #c4c4c4;
    height: 35px;
    background: transparent;
    font-family: "Roboto400";
    font-size: 16px;
    padding: 0 1rem;
    color: #37474f;
    outline: none;
    @media screen and (max-width: 480px) {
      font-size: 17px;
      height: 43px;
    }
  `,
  SubmitInput: styled.input`
    background: #5ca6b3;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
    font-family: "Roboto400";
    font-size: 20px;
    color: #ffffff;
    width: 50%;
    height: 45px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      width: 75%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `,
  Title: styled.h2`
    font-family: "Poppins700";
    font-weight: 700;
    font-size: 40px;
    color: #37474f;
    margin: 0;
    padding: 0;
  `,
  Paragraph: styled.p`
    font-family: "Roboto400";
    font-size: ${({ backtosignin }) => (backtosignin ? "18px" : "21px")};
    color: #000000;
  `,
  SubmitServerError: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
    color: #ff3333;
  `,
  SubmitError: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
    color: #ff3333;
  `,
  SubmitSuccess: styled.div`
    display: flex;
    justify-content: center;
    font-family: PoppinsRegular;
    font-size: 14px;
    margin-bottom: 1rem;
    color: green;
  `,
};

export default styles;

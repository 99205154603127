import styled from "styled-components/macro";
import BackgroundGradientImg from "../../../assets/images/padme_new_bg.png";

const styles = {
  PageContainer: styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 105px);
    background-image: url(${BackgroundGradientImg});
    background-size: 100% 100%;
    /* background: radial-gradient(
      circle at 36% 0%,
      rgba(0, 188, 212, 0.05) 0%,
      rgba(239, 239, 239, 1) 100%
    ); */
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 4rem;
    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
    @media screen and (max-width: 480px) {
      padding: 0 1rem;
    }
  `,
  AuthWrapper: styled.div`
    display: flex;
    height: 100%;
    justify-content: ${(props) => (props.isSignIn ? "center" : "flex-start")};
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  `,
  Column: styled.div`
    width: ${(props) => (props.leftColumn ? "45%" : "55%")};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: ${(props) => (props.isSignIn ? "center" : "flex-start")};
    padding: ${(props) => (props.leftColumn ? "0" : "3rem 0")};
    @media screen and (max-width: 1024px) {
      width: 100%;
      display: ${({ leftColumn }) => (leftColumn ? "none" : "block")};
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    z-index: 10;
    position: relative;
    top: ${({ signIn, validate }) => (signIn ? "90px" : validate ? "180px" : "125px")};
    @media screen and (max-width: 1024px) {
      display: none;
    }
    width: 100%;
  `,
};

export default styles;

import styled from "styled-components/macro";

const styles = {
  FieldContainer: styled.div``,
  InputLabel: styled.label`
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
    margin-bottom: 20px;
  `,
  Input: styled.input`
    font-size: 14px;
    background-color: #ffffff;
    color: #37474f;
    padding: 0 0.5rem;
    width: 100%;
    height: 35px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &::placeholder {
      color: #d1cdcd;
      font-style: italic;
    }
  `,
};

export default styles;

import React, { useState, useEffect } from "react";
//Styles
import Styles from "./styles";
//Tools
import { Outlet, useLocation, useParams, useOutletContext } from "react-router-dom";
//Components
import { useToastModal } from "../../../context/ToastModalContext";
//GraphQl
import { useQuery, useMutation } from "@apollo/client";
import {
  LIST_AREAS,
  UPDATE_AREA,
  LIST_USER_BY_VENUE,
  LIST_USER_BY_COMPANY,
  INVITE_USER_TO_VENUE,
  REMOVE_USER_FROM_VENUE,
  UPDATE_USER_VENUE_PERMISSIONS,
} from "../../../graphql/graph";

const VenueManagement = () => {
  const [companiesList] = useOutletContext();
  const [activeLocation, setActiveLocation] = useState("");
  const [usersByVenueList, setUsersByVenueList] = useState([]);
  const [usersByCompanyList, setUsersByCompanyList] = useState([]);
  const [areasAndcamerasOutContext, setAreasAndcamerasOutContext] = useState([]);
  const [numberOfVenueCameras, setNumberOfVenueCameras] = useState([]);
  const { venueId, venueName, companyId } = useParams();
  const location = useLocation();

  const [
    inviteUserToVenueMutation,
    //TODO Handle graphql loading
    {
      data: inviteUserToVenueData,
      /* loading: addUserToVenueLoading, */
      error: inviteUserToVenueError,
    },
  ] = useMutation(INVITE_USER_TO_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_USER_BY_VENUE,
        context: { clientName: "graph" },
        variables: {
          venueId: venueId,
        },
      },
    ],
  });

  const {
    //TODO Handle graphql error
    data: usersByVenueData,
    loading: usersByVenueDataLoading,
    /* error: usersByVenueDataError, */
  } = useQuery(LIST_USER_BY_VENUE, {
    context: { clientName: "graph" },
    variables: { venueId },
  });

  const {
    //TODO Handle graphql error+loading
    data: usersByCompanyData,
    /* loading: usersByCompanyLoading,
    error: usersByCompanyError, */
  } = useQuery(LIST_USER_BY_COMPANY, {
    context: { clientName: "graph" },
    variables: { companyId },
  });

  const {
    //TODO Handle graphql error
    data: areasData,
    loading: areasLoading,
    /* error: areasError, */
  } = useQuery(LIST_AREAS, {
    context: { clientName: "graph" },
    variables: {
      venueId: venueId,
    },
  });

  const [
    updateArea,
    //TODO Handle graphql data+loading+error
    /* {
      data: updateAreaData,
      loading: updateAreaLoading,
      error: updateNameGraphqlError,
    }, */
  ] = useMutation(UPDATE_AREA, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_AREAS,
        context: { clientName: "graph" },
        variables: {
          venueId: venueId,
        },
      },
    ],
  });

  const [
    removeUserVenuePermissions,
    //TODO: Handle GraphQl loading (if needed)
    {
      data: removeUserVenuePermissionsData,
      /* loading: removeUserVenuePermissionsLoading, */
      error: removeUserVenuePermissionsError,
    },
  ] = useMutation(REMOVE_USER_FROM_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_USER_BY_VENUE,
        context: { clientName: "graph" },
        variables: {
          venueId: venueId,
        },
      },
    ],
  });

  const [
    updateUserVenuePermissions,
    {
      data: updateUserVenuePermissionsData,
      error: updateUserVenuePermissionsError,
      /* TODO: GraphQL loading */
    },
  ] = useMutation(UPDATE_USER_VENUE_PERMISSIONS, {
    context: { clientName: "graph" },
  });

  const handleActiveTab = (route) => {
    if (activeLocation.includes(route)) {
      return +true;
    }
  };

  useEffect(() => {
    if (usersByVenueData) {
      setUsersByVenueList(usersByVenueData.listUserByVenue.data);
    }
  }, [usersByVenueData]);

  useEffect(() => {
    if (usersByCompanyData) {
      setUsersByCompanyList(usersByCompanyData.listUserByCompany.data);
    }
  }, [usersByCompanyData]);

  useEffect(() => {
    if (areasData) {
      setAreasAndcamerasOutContext(areasData.listArea.area);
    }
  }, [areasData]);

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  //Set total number of cameras in venue
  useEffect(() => {
    if (areasAndcamerasOutContext) {
      const numberOfMachinesPerArea = [];
      areasAndcamerasOutContext.map((area) => {
        return numberOfMachinesPerArea.push(area.Machines.length);
      });
      setNumberOfVenueCameras(
        numberOfMachinesPerArea.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0),
      );
    }
  }, [areasAndcamerasOutContext]);

  const { addToast } = useToastModal();

  useEffect(() => {
    if (inviteUserToVenueData) {
      addToast("User invited successfully!", "success");
    }
    if (inviteUserToVenueError) {
      addToast("Something went wrong!", "error");
    }
  }, [inviteUserToVenueData, inviteUserToVenueError]);

  useEffect(() => {
    if (updateUserVenuePermissionsData) {
      addToast("User venue permission updated successfully!", "success");
    }
    if (updateUserVenuePermissionsError) {
      addToast("Something went wrong!", "error");
    }
  }, [updateUserVenuePermissionsData, updateUserVenuePermissionsError]);

  useEffect(() => {
    if (removeUserVenuePermissionsData) {
      addToast("User removed successfully!", "success");
    }
    if (removeUserVenuePermissionsError) {
      addToast("Something went wrong!", "error");
    }
  }, [removeUserVenuePermissionsData, removeUserVenuePermissionsError]);

  return (
    <>
      <Styles.Header>
        <Styles.Title>{venueName}</Styles.Title>
      </Styles.Header>
      <Styles.NavAndOutletContainer>
        <Styles.VenueNavigationContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("venue-dashboard")}>
            <Styles.Link to="venue-dashboard">Dashboard</Styles.Link>
          </Styles.LinkContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("venue-cameras")}>
            <Styles.Link to="venue-cameras">Cameras</Styles.Link>
          </Styles.LinkContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("venue-users")}>
            <Styles.Link to="venue-users">Users</Styles.Link>
          </Styles.LinkContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("venue-licenses")}>
            <Styles.Link to="venue-licenses">Licenses</Styles.Link>
          </Styles.LinkContainer>
        </Styles.VenueNavigationContainer>
        <Styles.SecOutletContainer>
          <Outlet
            context={[
              areasAndcamerasOutContext,
              areasLoading,
              updateArea,
              usersByVenueList,
              usersByCompanyList,
              usersByVenueDataLoading,
              inviteUserToVenueMutation,
              numberOfVenueCameras,
              removeUserVenuePermissions,
              updateUserVenuePermissions,
              companiesList,
            ]}
          />
        </Styles.SecOutletContainer>
      </Styles.NavAndOutletContainer>
    </>
  );
};

export default VenueManagement;

import styled from "styled-components/macro";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import noImageIcon from "../../assets/images/no-image-icon.png";
import noDockerIcon from "../../assets/images/no-docker-icon.png";

const styles = {
  CardContainer: styled.div`
    display: flex;
    margin-right: 10px;
  `,
  SmallCardContainer: styled.div`
    display: flex;
    cursor: pointer;
    width: 102px;
    flex-direction: column;
    background-color: white;
    border: 1px solid #c4c4c4;
  `,
  SmallCard: styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    background: #b0bec5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.img ? `url(${props.img})` : `url(${noImageIcon})`)};
    &:hover,
    &:focus {
      ${(props) => !props.hasDockerId && "background-image: url(" + noDockerIcon + ")"};
    }
  `,
  CameraName: styled.span`
    font-family: "Poppins500";
    font-size: 10px;
    color: #191919;
    text-align: center;
    padding: 2px;
    word-break: break-word;
  `,
  StatusCircle: styled.span`
    height: 18px;
    width: 18px;
    margin: 4px;
    background-color: ${(props) => (props.active ? "#28BC4E" : "#EC5F5D")};
    border-radius: 50%;
    display: inline-block;
    animation: blinker 1.5s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    }
  `,
  StatusContainer: styled.div`
    height: 33%;
    display: flex;
    justify-content: flex-end;
  `,
  NameContainer: styled.div`
    border-top: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  CheckboxAndIconContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 5px;
  `,
  EditIcon: styled(EditIcon)`
    margin-top: 5px;
    width: 13px;
    height: 15px;
    cursor: pointer;
  `,
};

export default styles;

import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";
import { HiOutlineFolderDownload } from "react-icons/hi";

const styles = {
  DownLoadIcon: styled(HiOutlineFolderDownload)`
    cursor: pointer;
    color: white;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    right: 7px;
  `,
  DisplayCameraContainer: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: ${({ hasVideo }) => (hasVideo ? "center" : "flex-start")}; */
    justify-content: flex-start;
    background: #ffffff;
    padding: 2rem;
    gap: 2rem;
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1150px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  CameraTitle: styled.h3`
    font-family: "Poppins700";
    font-size: 20px;
    color: #464646;
    margin: 0;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  MaskingWrapperImg: styled.div`
    width: ${({ width }) => `${width || 960}px`};
    height: ${({ height }) => `${height || 540}px`};
    position: absolute;
  `,
  PreviewWrapper: styled.div`
    display: flex;
    background: #c4c4c4;
    width: 100%;
    height: 100%;
    min-height: 240px;
    position: relative;
  `,
  MaskingWrapper: styled.div`
    position: relative;
    width: ${({ width }) => `${width || 960}px`};
  `,
  CloseButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    color: #464646;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    color: #c4c4c4;
  `,
  SpinnerContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  `,
  ErrorContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    padding: 2rem 0;
  `,
  ErrorMessage: styled.p`
    font-family: "PoppinsRegular";
    text-align: center;
    font-size: ${({ red }) => (red ? "17px" : "14px")};
    color: ${({ red }) => (red ? "#ff3333" : "#000000")};
    margin: 0;
  `,
  LoadingMessage: styled.p`
    font-family: "PoppinsRegular";
    font-size: 1rem;

    animation: bounce 2s ease-in-out infinite;

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
        text-shadow: 0px 10px rgba(0, 0, 0, 0.1);
      }
      100% {
        transform: translateY(0);
      }
    }
  `,
  RetrieveThumbBtnsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  RetrieveThumbnailBtn: styled.button`
    font-family: Poppins600;
    font-size: 14px;
    color: #5ca6b3;
    background-color: #ffffff;
    color: #ffffff;
    background-color: #5ca6b3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
  `,
  LoaderContainer: styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
  `,
  PreviewAndOutputContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  `,
  LabelContainer: styled.div`
    background-color: #37474f;
    padding: 0.25rem 1rem;
    width: fit-content;
    font-family: "PoppinsRegular";
    font-size: 12px;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    flex-direction: row;
    display: flex;
  `,
  Column: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  TextWarning: styled.div`
    margin-left: 10px;
    font-size: 14px;
    color: orange;
  `,
  TextCritical: styled.div`
    margin-left: 10px;
    font-size: 15px;
    color: red;
  `,
  LivePreviewCanvas: styled.canvas`
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;
  `,
  LivePreviewMasks: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-out;
  `,
  LivePreviewExtra: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
};

// define this style here so we can reference LivePreviewCanvas and LivePreviewMasks
styles.PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.videoStarted ? "100%" : `${props.height}px`)};

  & ${styles.LivePreviewCanvas} {
    opacity: ${(props) => (props.videoStarted ? "1" : "0")};
  }
  ${styles.LivePreviewMasks} {
    opacity: ${(props) => (props.videoStarted ? "1" : "0")};
  }
`;

export default styles;

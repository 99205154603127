import React, { useEffect, useState } from "react";
// Styles
import Styles from "./styles";
// Tools
import { useParams, useNavigate } from "react-router-dom";
// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import { PROCESS_INFERENCE, RETRIEVE_INFERENCE } from "../../graphql/user";
// Components
import SpinnerComponent from "../../components/Loader";

const InferenceResult = () => {
  const [retrievedInference, setRetrievedInference] = useState(null);
  const { inferenceCode } = useParams();
  const navigate = useNavigate();

  const [
    processInferenceMutation,
    {
      data: processInferenceData,
      loading: processInferenceLoading,
      error: processInferenceError, //TODO Handle graphql error
    },
  ] = useMutation(PROCESS_INFERENCE, {
    context: { clientName: "user" },
  });

  const {
    data: retrievedInferenceData,
    loading: retrieveInferenceLoading,
    error: retrieveInferenceError,
  } = useQuery(RETRIEVE_INFERENCE, {
    variables: { inferenceCode: processInferenceData?.processInference?.result?.inferenceCode },
    skip: !processInferenceData,
    context: { clientName: "user" },
  });

  useEffect(() => {
    processInferenceMutation({ variables: { inferenceCode: inferenceCode } });
  }, [inferenceCode, processInferenceMutation]);

  useEffect(() => {
    if (retrievedInferenceData) {
      setRetrievedInference(retrievedInferenceData.retrieveInference.data);
    }
  }, [retrievedInferenceData]);

  const handleClickBackBtn = () => {
    navigate("/");
  };

  return (
    <Styles.SectionContainer>
      <Styles.AlignmentContainer>
        {processInferenceLoading || retrieveInferenceLoading ? (
          <Styles.HeaderContainer>
            <h1>Processing Data</h1>
            <SpinnerComponent width={100} height={100} />
          </Styles.HeaderContainer>
        ) : (
          <Styles.Container>
            {processInferenceError || retrieveInferenceError ? (
              <Styles.ErrorMessage>
                Sorry, something went wrong. Please try another model.
              </Styles.ErrorMessage>
            ) : (
              <>
                <Styles.ResultTitle>Inference Results</Styles.ResultTitle>
                {retrievedInference && (
                  <>
                    <Styles.ResultItem>
                      <span>User Email:</span> {retrievedInference.userEmail}
                    </Styles.ResultItem>
                    <Styles.ResultItem>
                      <span>Inference Result:</span> {retrievedInference.inferenceResult}
                    </Styles.ResultItem>

                    <Styles.AnswersContainer>
                      <Styles.AnswersHeading>Answers:</Styles.AnswersHeading>

                      {JSON.parse(retrievedInference.inferenceAnswers)?.map((answer, index) => (
                        <Styles.AnswerItem key={index}>
                          <>
                            <span>{answer.description}: </span>
                            <p>{answer.value}</p>
                          </>
                        </Styles.AnswerItem>
                      ))}
                    </Styles.AnswersContainer>
                  </>
                )}
              </>
            )}

            <Styles.BackButtonContainer>
              <button onClick={handleClickBackBtn}>Back to Models</button>
            </Styles.BackButtonContainer>
          </Styles.Container>
        )}
      </Styles.AlignmentContainer>
    </Styles.SectionContainer>
  );
};

export default InferenceResult;

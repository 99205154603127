import React from "react";
//Styles
import Styles from "./styles";
//Images
import PadmeLogo from "../../assets/images/PadmeLogo.svg";

const Footer = () => {
  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  return (
    <Styles.FooterContainer>
      <Styles.FooterWrapper>
        <Styles.FooterContent>
          <Styles.BrandIconsCopyContainer>
            <Styles.BrandContainer>
              <Styles.BrandLink href="http://padme.ai" target="blank" rel="noreferrer">
                <img src={PadmeLogo} alt="Padme Logo" style={{ width: "40px" }} />
                <Styles.PadmeText to="/">Padmé</Styles.PadmeText>
              </Styles.BrandLink>
              <Styles.CopyP>
                <Styles.Anchor href="https://lotuslabs.ai" target="blank" rel="noreferrer">
                  A Lotus Labs, Inc
                </Styles.Anchor>{" "}
                product.
              </Styles.CopyP>
            </Styles.BrandContainer>
            <Styles.CopyContainer>
              <Styles.CopyP>© {getYear()} PADMÉ All rights reserved.</Styles.CopyP>
            </Styles.CopyContainer>
          </Styles.BrandIconsCopyContainer>
        </Styles.FooterContent>
      </Styles.FooterWrapper>
    </Styles.FooterContainer>
  );
};

export default Footer;

import React from "react";
//Styles
import Styles from "./styles";
//Context
import { useOutletContext } from "react-router-dom";
//Components
import LicencesTable from "./LicencesTable";
/* import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn"; */

const VenueLicenses = () => {
  const [, , , , , , , numberOfVenueCameras] = useOutletContext();
  return (
    <Styles.ManageVenueLicencesContainer>
      <Styles.Header>
        <Styles.Title>Manage Licences</Styles.Title>
        {/* <BlueBtn label={"Add Licence"} handleClick={() => {}} /> */}
      </Styles.Header>
      <LicencesTable numberOfVenueCameras={numberOfVenueCameras} />
    </Styles.ManageVenueLicencesContainer>
  );
};

export default VenueLicenses;

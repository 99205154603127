import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import AddUpdateApiModal from "../../Modals/AddUpdateApi/index.jsx";
import ApiTable from "./ApiTable.jsx";

import { LIST_APIS } from "../../../graphql/graph";

import Styles from "./styles";

const ApiManager = () => {
  const { companyId } = useParams();
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestToUpdate, setRequestToUpdate] = useState(null);

  const [getApiList, { data: apiData }] = useLazyQuery(LIST_APIS, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    if (companyId) {
      getApiList({
        variables: {
          companyId,
        },
      });
    }
  }, [companyId]);

  const handleToggleContainer = () => {
    setShowTable(!showTable);
  };

  const handleApiModal = () => {
    setShowModal(!showModal);
  };

  const onCloseModal = () => {
    setRequestToUpdate(null);
  };

  const handleUpdateItem = (item) => {
    setRequestToUpdate(item);
    setShowModal(true);
  };

  const apiList = apiData?.listApi?.data || [];

  return (
    <Styles.CompanyDashboardContainer>
      <Styles.Header>
        <Styles.SubTitle>Api</Styles.SubTitle>
        <Styles.IconButton onClick={handleToggleContainer}>
          {showTable ? <ChevronDown /> : <ChevronUp />}
        </Styles.IconButton>
      </Styles.Header>
      <Styles.DockerContainer>
        {showTable && (
          <Styles.Section>
            <Styles.Header>
              <Styles.TableTitle>Api Keys</Styles.TableTitle>
              <Styles.Actions>
                <BlueBtn label={"Add Api Key"} handleClick={handleApiModal} />
              </Styles.Actions>
            </Styles.Header>
            <ApiTable data={apiList} updateItem={handleUpdateItem} />
          </Styles.Section>
        )}
      </Styles.DockerContainer>
      {showModal && (
        <AddUpdateApiModal
          companyId={companyId}
          showModal={showModal}
          setShowModal={setShowModal}
          apiToUpdate={requestToUpdate}
          setApiToUpdate={setRequestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
    </Styles.CompanyDashboardContainer>
  );
};

export default ApiManager;

import React, { useEffect, useState } from "react";
//Styles
import Styles from "./styles";
//Icons
import { ReactComponent as CameraIcon } from "../../../../assets/icons/camera_icon.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/icons/users_icon.svg";
import { ReactComponent as LicensesIcon } from "../../../../assets/icons/licenses_icon.svg";
//Context
import { useOutletContext } from "react-router-dom";
//Components
import Card from "../../../../components/Shared/Card";

const VenueDashboard = () => {
  const [, , , , usersByVenueList, , , numberOfVenueCameras] = useOutletContext();
  /* const [camerasCardRowInfo, setCamerasCardRowInfo] = useState([]); */
  /* const [numberOfVenueCameras, setNumberOfVenueCameras] = useState([]); */
  const [numberOfUsersByVenue, setNumberOfUsersByVenue] = useState([]);

  useEffect(() => {
    if (usersByVenueList) {
      setNumberOfUsersByVenue(usersByVenueList.length);
    }
  }, [usersByVenueList]);

  return (
    <Styles.VenueDashboard>
      <Styles.CardsContainer>
        <Card
          title={"Cameras"}
          count={numberOfVenueCameras}
          icon={<CameraIcon />}
          /* cardRowInfo={camerasCardRowInfo} */
        />
        <Card title={"Users"} count={numberOfUsersByVenue} icon={<UsersIcon />} />
        <Card title={"Licenses"} count={numberOfVenueCameras} icon={<LicensesIcon />} />
      </Styles.CardsContainer>
    </Styles.VenueDashboard>
  );
};

export default VenueDashboard;

import styled from "styled-components/macro";

const styles = {
  SearchContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 160px;
    height: 30px;
    background: #ffffff;
    border: 1.2px solid #cfcfcf;
    box-shadow: inset 0px 2.4px 6px 3.6px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
  `,
  SearchInput: styled.input`
    padding-left: 7px;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: 100%;
    outline: none;
    background-color: transparent;
    font-size: 15px;
    border: 1px solid transparent;
    font-family: PoppinsRegular;
    font-size: 14px;
    color: #455a64;
  `,
  IconContainer: styled.div`
    position: relative;
    margin-left: auto;
  `,
};

export default styles;

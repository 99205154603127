import React from "react";

//Styles
import Styles from "./styles";
//Components
import Checkbox from "../Checkbox";

const ACTIVE_INFERENCE_THRESHOLD = 5 * 60;

const SmallCameraCard = ({
  camera,
  onClick,
  areaId,
  handleCameraCheckboxSelect,
  handleClickUpdateCamera,
}) => {
  let cameraUri;
  const cameraName = camera.machineName;

  // check if thumbnail is available
  if (camera.thumbnail && camera.thumbnail.machineThumbnailURL) {
    cameraUri = camera.thumbnail.machineThumbnailURL;
  }

  const handleOnClick = () => {
    onClick(camera);
  };

  // handle inference status
  const renderStatusIcon = () => {
    if (!camera.machineStatus) {
      return <Styles.StatusContainer />;
    }

    // inference active lets calculate time since last ping
    let inferenceStatus = false;

    if (camera.machineLastPingTime) {
      const lastPing = parseInt(camera.machineLastPingTime);
      const currentTime = Math.floor(Date.now() / 1000);
      const diffInMinutes = currentTime - lastPing;
      if (diffInMinutes < ACTIVE_INFERENCE_THRESHOLD) {
        inferenceStatus = true;
      }
    }
    return (
      <Styles.StatusContainer>
        <Styles.StatusCircle active={inferenceStatus} />
      </Styles.StatusContainer>
    );
  };

  return (
    <Styles.CardContainer>
      <Styles.CheckboxAndIconContainer>
        <Checkbox
          name={camera.id}
          id={areaId}
          onChange={(e) => handleCameraCheckboxSelect(camera.venueId, camera.areaId, camera.id, e)}
          checked={!!camera.isChecked}
        />
        <Styles.EditIcon
          onClick={() => {
            handleClickUpdateCamera(camera);
          }}
        />
      </Styles.CheckboxAndIconContainer>
      <Styles.SmallCardContainer>
        <Styles.SmallCard onClick={handleOnClick} img={cameraUri} hasDockerId={camera.dockerId}>
          {renderStatusIcon()}
        </Styles.SmallCard>
        <Styles.NameContainer>
          <Styles.CameraName>{cameraName}</Styles.CameraName>
        </Styles.NameContainer>
      </Styles.SmallCardContainer>
    </Styles.CardContainer>
  );
};

export default SmallCameraCard;

import styled from "styled-components/macro";
//Icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/garbage_gray_icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow_down_gray.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow_up_gray.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings_wheel_gray.svg";

const styles = {
  //Icons
  DeleteIcon: styled(DeleteIcon)`
    cursor: pointer;
  `,
  EditIcon: styled(EditIcon)`
    cursor: pointer;
  `,
  ArrowDown: styled(ArrowDown)`
    cursor: pointer;
  `,
  ArrowUp: styled(ArrowUp)`
    cursor: pointer;
  `,
  SettingsIcon: styled(SettingsIcon)`
    cursor: pointer;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: row;
  `,
  //Styles
  ManageModelsContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    /* border-bottom: 1px solid #c4c4c4; */
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin-top: 1rem;
  `,
  TableTitle: styled.h3`
    font-family: Poppins600;
    font-size: 18px;
    color: #455a64;
    margin-top: 1rem;
    margin-bottom: 60px;
  `,
  TableSubTitle: styled.div`
    font-family: Poppins600;
    font-size: 14px;
    color: grey;
  `,
  Table: styled.table`
    background: #ffffff;
    table-layout: fixed;
    width: 100%;
    overflow-y: scroll;
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0.75rem;
  `,
  TableHeaderEntries: styled.th`
    width: 100%;
    text-align: left;
    font-family: "Poppins700";
    font-size: 14px;
    color: #263238;
    padding: 1%;
    flex-basis: ${(props) => (props.narrow ? 0 : null)};
  `,
  TableRow: styled.tbody`
    width: 100%;
  `,
  TableRowEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    &:nth-child(even) {
      background-color: #efefef;
    }
  `,
  TableRowEntries: styled.td`
    width: 100%;
    font-family: "PoppinsRegular";
    font-size: 14px;
    color: #455a64;
    text-align: ${(props) => props.align || "left"};
    flex-basis: ${(props) => (props.narrow ? 0 : null)};
    padding: 0.75%;
    cursor: pointer;
  `,
  RowActionsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: 1rem;
  `,
  ActionIconsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  StatusCircle: styled.span`
    height: 12px;
    width: 12px;
    background-color: ${(props) => (props.active ? "#28BC4E" : "#EC5F5D")};
    border-radius: 50%;
    display: inline-block;
    /* animation: blinker 1.5s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  } */
  `,
  StatusContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  Section: styled.div``,
};

export default styles;

import React, { useContext, useState, useEffect } from "react";
//Styles
import Styles from "./styles";
//Icons
import { FaBars } from "react-icons/fa";
//Images
import PadmeLogo from "../../assets/images/PadmeLogo.svg";
//Components
import NavDropdown from "./NavDropdown";
import { Button } from "../Shared/Buttons/Button";
import ProfileImage from "../ProfileImage";
//Context
import { AuthContext } from "../../context/authContext";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ toggle }) => {
  const [dropdown, setDropdown] = useState(false);
  const [activeLocation, setActiveLocation] = useState("");
  const { isAuthenticated, userRole } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <>
      <Styles.Nav>
        <Styles.NavbarContainer>
          <Styles.BrandLink href="http://padme.ai" target="blank" rel="noreferrer">
            <img src={PadmeLogo} alt="Padme Logo" style={{ width: "40px" }} />
            <Styles.PadmeText>Padmé</Styles.PadmeText>
          </Styles.BrandLink>
          <Styles.MobileIcon onClick={toggle}>
            <FaBars />
          </Styles.MobileIcon>
          <Styles.NavMenu>
            <Styles.NavItem>
              <Styles.TalkToUsBtn
                href="https://www.padme.ai/talk-to-us"
                target="blank"
                rel="noreferrer"
              >
                TALK TO US
              </Styles.TalkToUsBtn>
            </Styles.NavItem>
            {!isAuthenticated ? (
              <Styles.NavItem activeTab={activeLocation === "/signin" ? true : null}>
                <Button to="/signin" dark_text="true">
                  Sign In
                </Button>
              </Styles.NavItem>
            ) : (
              <>
                <Styles.NavItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                  <Styles.ProfileContainer>
                    <ProfileImage size={42} />
                    <Styles.DropdownIcon />
                  </Styles.ProfileContainer>
                  {dropdown && <NavDropdown setDropdown={setDropdown} userRole={userRole} />}
                </Styles.NavItem>
              </>
            )}
          </Styles.NavMenu>
        </Styles.NavbarContainer>
      </Styles.Nav>
    </>
  );
};

export default Navbar;

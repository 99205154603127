import React from "react";
import { useParams, useNavigate } from "react-router-dom";

//Styles
import Styles from "./styles";
import DefaultDashBtn from "../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../components/Loader";

const Drawer = ({ handleClickAddVenue, companiesLoading, companiesList, searchTerm }) => {
  const { companyId, venueId } = useParams();
  const navigate = useNavigate();

  const handleNavigateToVenue = (companyId, venueId) => {
    navigate(`/manage-venues/${companyId}/${venueId}/venue-dashboard`);
  };

  const handleGotoCompany = (companyId) => {
    navigate(`/manage-companies/${companyId}`);
  };

  return (
    <Styles.CompaniesContainer>
      {companiesLoading ? (
        <SpinnerComponent />
      ) : (
        companiesList
          .filter((company) => {
            if (searchTerm === "") {
              return company;
            } else if (company.companyName.toLowerCase().includes(searchTerm.toLowerCase())) {
              return company;
            }
            return false;
          })
          .map((company) => {
            return (
              <React.Fragment key={company.id}>
                <Styles.CompaniesLinkContainer
                  activeSubtab={companyId === company.id ? true : null}
                >
                  <Styles.LinkButton onClick={() => handleGotoCompany(company.id)}>
                    {company.companyName}
                  </Styles.LinkButton>
                </Styles.CompaniesLinkContainer>
                {[...company.Venues]
                  .sort((a, b) =>
                    a.venueName > b.venueName ? 1 : b.venueName > a.venueName ? -1 : 0,
                  )
                  .map((venue) => {
                    return (
                      <Styles.VenuesLinkContainer
                        key={venue.id}
                        activeSubtab={venueId === venue.id}
                      >
                        <Styles.LinkButton
                          onClick={() => handleNavigateToVenue(company.id, venue.id)}
                          venuenotactive={venueId !== venue.id}
                          isvenue
                          venueactive={venueId === venue.id}
                        >
                          {venue.venueName}
                        </Styles.LinkButton>
                      </Styles.VenuesLinkContainer>
                    );
                  })}
                {companyId === company.id ? (
                  <Styles.AddVenueContainer>
                    <DefaultDashBtn
                      sidebarSize
                      label="Add Venue"
                      handleClick={() => handleClickAddVenue(company.companyName)}
                    />
                  </Styles.AddVenueContainer>
                ) : null}
              </React.Fragment>
            );
          })
      )}
    </Styles.CompaniesContainer>
  );
};

export default Drawer;

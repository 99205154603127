import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  CompaniesContainer: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
  `,
  CompaniesLinkContainer: styled.div`
    height: 30px;
    width: 90%;
    display: flex;
    align-items: center;
    background: ${(props) => (props.activeSubtab ? "#FFFFFF" : "none")};
    border: ${(props) => (props.activeSubtab ? "0.8px solid #FFFFFF" : "none")};
    border-radius: ${(props) => (props.activeSubtab ? "7.8px" : "none")};
    box-shadow: ${(props) =>
      props.activeSubtab ? "0px 1.56134px 3.90335px 2.34201px rgba(0, 0, 0, 0.05)" : "none"};
    border-bottom: ${(props) => (props.activeSubtab ? "none" : " 0.5px solid #C4C4C4")};
    & :hover {
      background: #ffffff;
      border-radius: 7.8px;
    }
  `,
  LinkContainer: styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    width: ${(props) => (props.activeSubtab ? "80%" : "100%")};
    background: ${(props) =>
      props.activetab ? "#5ca6b3" : props.activeSubtab ? "#FFFFFF" : "none"};
    border: ${(props) => (props.activeSubtab ? "0.780669px solid #FFFFFF" : "none")};
    border-radius: ${(props) =>
      props.activeTab ? "8px" : props.activeSubtab ? "7.80669px" : "none"};
    box-shadow: ${(props) =>
      props.activeSubtab ? "0px 1.56134px 3.90335px 2.34201px rgba(0, 0, 0, 0.05)" : "none"};

    & :hover {
      color: ${(props) => (props.activetab ? "none" : "#000000")};
    }
  `,
  LinkButton: styled.div`
    font-family: PoppinsRegular;
    font-size: 14px;
    font-weight: ${({ venueactive }) => venueactive && "bold"};
    color: ${({ activetab, venuenotactive, venueactive }) =>
      activetab ? "#FFFFFF" : venuenotactive ? "#909090" : venueactive ? "#5CA6B3" : "#455a64"};
    text-decoration: none;
    padding: ${({ isvenue }) => (isvenue ? "0 1.5rem" : "0 0.5rem")};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  AddVenueContainer: styled.div`
    padding: 0.5rem 0;
    width: 90%;
  `,
  VenuesLinkContainer: styled.div`
    height: 30px;
    width: 90%;
    display: flex;
    align-items: center;
    border-radius: ${(props) => (props.activeSubtab ? "7.80669px" : "none")};
    & :hover {
      color: #5ca6b3;
      font-weight: bold;
    }
  `,
};

export default styles;

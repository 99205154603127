import React, { useState } from "react";
import Styles from "../Settings/ModelManager/styles";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import { timeStampToDateString } from "../../../configs/helpers";
import { basicStatus } from "../../../configs/configEnviroment";

const Row = ({ item, updateItem }) => {
  const [isCopied, setIsCopied] = useState(false);
  const startDate = timeStampToDateString(item.createdAt);
  const lastUseDate = timeStampToDateString(item.lastUsed);

  const copyKey = async (text) => {
    if ("clipboard" in navigator) {
      // addToast("Output Link Copied successfully!", "success");
      setIsCopied(true);
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  return (
    <Styles.TableRowEntriesContainer>
      <Styles.TableRowEntries>{item.apiName}</Styles.TableRowEntries>
      <Styles.TableRowEntries narrow="true">
        <div onClick={() => copyKey(item.apiKey)}>{!isCopied ? "[Copy Link]" : "[Copied]"}</div>
      </Styles.TableRowEntries>
      <Styles.TableRowEntries>{startDate}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{lastUseDate}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{basicStatus[item.apiStatus]}</Styles.TableRowEntries>
      <Styles.TableRowEntries align="center" narrow="true">
        <BlueBtn width={"70px"} label="Edit" handleClick={() => updateItem(item)} />
      </Styles.TableRowEntries>
    </Styles.TableRowEntriesContainer>
  );
};

const ApiTable = ({ data, updateItem }) => {
  const renderNestedRows = () => {
    return data.map((item) => {
      return (
        <Styles.TableRow key={item.id}>
          <Row item={item} updateItem={updateItem} />
        </Styles.TableRow>
      );
    });
  };

  return (
    <Styles.Table>
      <Styles.TableHeader>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Name</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries narrow="true">Key</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Created</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Last used</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Status</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries narrow="true"></Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
      </Styles.TableHeader>
      {renderNestedRows()}
    </Styles.Table>
  );
};

export default ApiTable;

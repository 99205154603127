import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  FooterContainer: styled.footer`
    position: relative;
    bottom: 0;
    height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3b3b3b;
  `,
  FooterWrapper: styled.div`
    width: 100%;
    max-width: 1440px;
    height: 100%;
    padding: 0 40px;
    @media screen and (max-width: 480px) {
      padding: 0 20px;
    }
  `,
  FooterContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    @media screen and (max-width: 480px) {
      align-items: center;
    }
  `,
  NavLinksContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7rem;
    @media screen and (max-width: 480px) {
      gap: 3rem;
    }
  `,
  BrandIconsCopyContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
      gap: 3rem;
    }
  `,
  BrandLink: styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 20px;
  `,
  PadmeText: styled.span`
    font-family: Poppins800;
    font-size: 40px;
    line-height: 35px;
    color: #ffffff;
    padding-top: 13px;
  `,
  CopyContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 480px) {
      align-items: center;
    }
  `,
  CopyP: styled.p`
    font-family: Poppins500;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  `,
  BrandContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  `,
  Anchor: styled.a`
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
  `,
};

export default styles;

import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,

  NavAndOutletContainer: styled.div``,

  VenueNavigationContainer: styled.div`
    display: flex;
  `,
  SecOutletContainer: styled.div`
    background: #f3f2f2;
    box-shadow: 0px 3.89036px 9.72591px rgb(0 0 0 / 10%);
    border-radius: 0 10px 0 0;
    /* min-height: 681px; */
    /*  min-height: 600px; */
    height: 100%;
    /* max-width: 1031px; */
    padding: 2rem;
  `,

  LinkContainer: styled.div`
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.activeTab ? "#5CA6B3" : "#cfcfcf")};
    border: ${(props) => (props.activeTab ? "1px solid #5CA6B3" : "1px solid #b5b5b5")};
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
  `,
  Link: styled(Link)`
    font-family: PoppinsRegular;
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
    color: #ffffff;
  `,
};

export default styles;

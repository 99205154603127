import React, { useEffect } from "react";
import Styles from "../styles";
//Tools
import { useMutation } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

import { UPDATE_VENUE } from "../../../graphql/graph";

const updateVenueSchema = yup.object().shape({
  venueName: yup.string().required("Venue name is a required field."),
});

const UpdateVenueModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  venueObj,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateVenueSchema),
    },
    { defaultValues: venueObj },
  );

  const [updateVenue] = useMutation(UPDATE_VENUE, {
    context: { clientName: "graph" },
  });

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(venueObj);
  }, [reset, venueObj]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (venueFormData) => {
    updateVenue({
      variables: {
        venueName: venueFormData.venueName,
        id: venueObj.venueId,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <ModalWithChildren
      showModal={showModal}
      setShowModal={setShowModal}
      title={title}
      handleModalForwardClick={handleModalForwardClick}
    >
      <Styles.Form onSubmit={handleSubmit(submitForm)}>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Venue Name</Styles.Label>
          <Styles.Input type="text" name="venueName" {...register("venueName")} />
          <Styles.SubmitError>{errors.venueName?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>
        <Styles.ButtonsContainer>
          <DefaultDashBtn
            whitebg
            orangeborder
            orangecolor
            smallsize
            label="Cancel"
            handleClick={handleClickCancelBtn}
          />
          <Styles.SubmitInput type="submit" id="submit" value="Update" />
        </Styles.ButtonsContainer>
      </Styles.Form>
    </ModalWithChildren>
  );
};

export default UpdateVenueModal;

import React, { useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const updateCompanyPermissionSchema = yup.object().shape({
  permissionType: yup.string().required("Permission name is a required field."),
});

const UpdateCompanyPermissionModal = ({
  showModal,
  setShowModal,
  title,
  updateUserCompanyPermissions,
  userCompanyVariables,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateCompanyPermissionSchema),
    },
    { defaultValues: userCompanyVariables },
  );

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(userCompanyVariables);
  }, [reset, userCompanyVariables]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (updateCompanyPermissionFormData) => {
    updateUserCompanyPermissions({
      variables: {
        id: userCompanyVariables.id,
        permissionType: updateCompanyPermissionFormData.permissionType,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={title}>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Permission Type</Styles.Label>
            <Styles.Select
              name="permissionType"
              id="permissionType"
              {...register("permissionType")}
              defaultValue=""
            >
              <option value="" disabled>
                Select company permission
              </option>
              <option value="ADMIN">Admin</option>
              <option value="VIEW_ONLY">View Only</option>
              <option value="BY_VENUE">By Venue</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.permissionType?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <Styles.SubmitInput type="submit" id="submit" value="Update" />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default UpdateCompanyPermissionModal;

import React, { useEffect, useContext } from "react";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Styles
import Styles from "./styles";
//Components
import { FormGroup } from "../../Shared/FormGroup";
//Context
import { AuthContext } from "../../../context/authContext";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const updateNameSchema = yup.object().shape({
  userFirstName: yup.string().required("First Name is a required field."),
  userLastName: yup.string().required("Last Name is a required field."),
});

const UpdateProfile = (props) => {
  const {
    updateProfile,
    updateProfileData,
    updateProfileSuccess,
    setUpdateProfileSuccess,
    updateProfileError,
    setUpdateProfileError,
    /* loggedUserAttributes */
  } = props;

  const { loggedUserAttributes } = useContext(AuthContext);

  //React-Hook-Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(updateNameSchema),
    },
    { defaultValues: loggedUserAttributes },
  );

  //Effects
  useEffect(() => {
    if (updateProfileData) {
      reset();
    }
  }, [updateProfileData, reset]);

  useEffect(() => {
    if (errors.firstName?.message || errors.lastName?.message) {
      setUpdateProfileSuccess(false);
      setUpdateProfileError("");
    }
  }, [errors, setUpdateProfileSuccess, setUpdateProfileError]);

  //Nedded to preloaded data https://react-hook-form.com/kr/v6/api/
  useEffect(() => {
    reset(loggedUserAttributes);
  }, [reset, loggedUserAttributes]);

  //Handlers
  const handleUpdateProfile = (updateNameFormData) => {
    updateProfile({
      variables: {
        /* id: loggedUserId, */
        userFirstName: updateNameFormData.userFirstName.trim(),
        userLastName: updateNameFormData.userLastName.trim(),
        userPhone: updateNameFormData.userPhone.trim(),
      },
    });
  };

  usePreventEnterAction(handleUpdateProfile);

  return (
    <Styles.FormAndTitleContainer>
      <Styles.Title>Update Your Profile</Styles.Title>
      <Styles.Form onSubmit={handleSubmit(handleUpdateProfile)}>
        <FormGroup labelText={"First Name"} errorMessage={errors.firstName?.message} profileForm>
          <Styles.Input type="text" name="userFirstName" {...register("userFirstName")} />
        </FormGroup>
        <FormGroup labelText={"Last Name"} errorMessage={errors.lastName?.message} profileForm>
          <Styles.Input type="text" name="userLastName" {...register("userLastName")} />
        </FormGroup>
        <FormGroup labelText={"Phone Number"} errorMessage={errors.userPhone?.message} profileForm>
          <Styles.Input type="text" name="userPhone" {...register("userPhone")} />
        </FormGroup>
        {updateProfileSuccess ? (
          <Styles.SubmitSuccess>Profile updated successfully!</Styles.SubmitSuccess>
        ) : updateProfileError ? (
          <Styles.SubmitError>{updateProfileError}</Styles.SubmitError>
        ) : null}
        <Styles.SubmitInput type="submit" id="submitProfile" value="Update Profile" />
      </Styles.Form>
    </Styles.FormAndTitleContainer>
  );
};

export default UpdateProfile;

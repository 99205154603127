import styled from "styled-components/macro";

const styles = {
  DockerCard: styled.div`
    background: #ffffff;
    box-shadow: 0px 4.68571px 11.7143px rgb(0 0 0 / 10%);
    border-radius: 5px;
    width: 150px;
    height: 50px;
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Title: styled.h2`
    font-family: "Poppins700";
    font-size: 12px;
    line-height: 16px;
    color: #464646;
    margin: 0px;
  `,
  IconContainer: styled.div`
    padding-right: 5px;
  `,
  StatusCircle: styled.span`
    height: 12px;
    width: 12px;
    background-color: ${(props) => (props.active ? "#28BC4E" : "#EC5F5D")};
    border-radius: 50%;
    display: inline-block;
    /* animation: blinker 1.5s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    } */
  `,
  StatusContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
};

export default styles;

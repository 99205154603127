import styled from "styled-components/macro";

const styles = {
  Table: styled.table`
    text-align: center;
    width: 100%;
    margin: 40px 0;
    border-collapse: collapse;
  `,
  TableHeader: styled.th`
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    border: 1px solid #dddddd;
  `,
  TableRow: styled.tr`
    font-size: 17px;
    height: 40px;
  `,
  TableCell: styled.td`
    vertical-align: middle;
    border: 1px solid #dddddd;
  `,
  DeleteQuestionBtn: styled.button`
    font-family: "Poppins500";
    font-size: 14px;
    text-align: center;
    background-color: #ff3333;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    padding: 5px 10px;
    cursor: pointer;
  `,
};

export default styles;

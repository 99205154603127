import React, { useState, useContext } from "react";
//Styles
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Styles from "./styles";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
import BrokerCard from "../../../components/Shared/BrokerCard";
import AddUpdateBrokerModal from "../../Modals/AddUpdateBroker";
import { useToastModal } from "../../../context/ToastModalContext";
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import { AuthContext } from "../../../context/authContext";
import { LIST_BROKERS } from "../../../graphql/graph";
import { ReactComponent as AddIcon } from "../../../assets/icons/add_orange_icon.svg";

const Brokers = () => {
  const { companyId } = useParams();
  const { addToast } = useToastModal();
  const { loggedUserAttributes } = useContext(AuthContext);
  const [showBrokers, setShowBrokers] = useState(false);
  const [brokerToUpdate, setBrokerToUpdate] = useState(null);
  const [showAddBrokerModal, setShowAddBrokerModal] = useState(false);

  const { data: brokersData } = useQuery(LIST_BROKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const brokersList = brokersData?.listBroker?.data || [];

  const handleClickAddUpdateBroker = (broker) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setBrokerToUpdate(broker);
      setShowAddBrokerModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to add or update Broker!", "error");
    }
  };

  const handleToggleBrokerContainer = () => {
    setShowBrokers(!showBrokers);
  };

  const renderBrokers = () => {
    if (!showBrokers) {
      return null;
    }
    if (!brokersList.length) {
      return <BrokerCard title="No Brokers" />;
    }
    return brokersList.map((broker) => {
      return (
        <BrokerCard
          key={broker.id}
          title={broker.brokerName}
          updateBroker={() => handleClickAddUpdateBroker(broker)}
        />
      );
    });
  };

  const renderAddBroker = () => {
    if (!showBrokers) {
      return null;
    }
    return (
      <BrokerCard
        title="Add New broker"
        updateBroker={() => handleClickAddUpdateBroker(null)}
        icon={AddIcon}
      />
    );
  };

  return (
    <Styles.CompanyDashboardContainer>
      <Styles.Header>
        <Styles.SubTitle>Brokers</Styles.SubTitle>
        <Styles.IconButton onClick={handleToggleBrokerContainer}>
          {showBrokers ? <ChevronDown /> : <ChevronUp />}
        </Styles.IconButton>
      </Styles.Header>
      <Styles.DockerContainer>
        {renderBrokers()}
        {renderAddBroker()}
      </Styles.DockerContainer>
      {showAddBrokerModal && (
        <AddUpdateBrokerModal
          showModal={showAddBrokerModal}
          setShowModal={setShowAddBrokerModal}
          setBrokerToUpdate={setBrokerToUpdate}
          brokerToUpdate={brokerToUpdate}
          companyId={companyId}
        />
      )}
    </Styles.CompanyDashboardContainer>
  );
};

export default Brokers;

import React from "react";
//Styles
import Styles from "./styles";
//Components

const BrokerCard = ({ title, updateBroker, icon: Icon }) => {
  return (
    <Styles.DockerCard
      style={{
        cursor: "pointer",
      }}
      onClick={updateBroker}
    >
      <Styles.Title>{title}</Styles.Title>
      {Icon && <Icon />}
    </Styles.DockerCard>
  );
};

export default BrokerCard;

import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Styles from "../styles";

import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import ObjectSelector from "../../../components/ObjectSelector";

import {
  CREATE_TRAINING_REQUEST,
  UPDATE_TRAINING_REQUEST,
  START_TRAINING_REQUEST,
  DEPLOY_TRAINING_REQUEST,
  DELETE_TRAINING_REQUEST,
  STOP_RENTED_INSTANCE,
  LIST_TRAINING_REQUESTS,
  GET_RENTEDGPU_BY_REQUEST_ID,
  LIST_AVAILABLE_GPU_INSTANCES,
  LIST_MODELS_ADMIN,
  LIST_FILES,
} from "../../../graphql/graph";
import { DashboardContext } from "../../../context/DashboardContext";
import { useToastModal } from "../../../context/ToastModalContext";
import RadioButton from "../../../components/RadioButton";
import Checkbox from "../../../components/Checkbox";
import { rentedStatus, mtStatus } from "../../../configs/configEnviroment";

const inputFormSchema = yup.object().shape({
  modelVersionId: yup.string().required("Model has to be selected"),
  mtName: yup.string().when("mtNewModel", {
    is: (mtNewModel) => mtNewModel === 1,
    then: yup.string().required("Name is required"),
  }),
});

const StartTrainingRequest = ({
  companyId,
  showModal,
  setShowModal,
  requestToUpdate,
  onCloseModal,
  cameraMap,
  collectionAnnotationData,
}) => {
  const [checkedAnnotationIds, setCheckedAnnotationIds] = useState([]);
  const [showGPUSearch, setShowGPUSearch] = useState(false);
  const { addToast, addModal } = useToastModal();
  const { objectDetectionItems } = useContext(DashboardContext);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inputFormSchema),
    defaultValues: {
      modelId: "",
      modelVersionId: "",
      mtDetectionItems: [],
      mtManualReview: 1,
      mtNotes: "",
      mtName: "",
      mtNewModel: 1,
    },
  });

  const [getFiles, { data: getFilesData }] = useLazyQuery(LIST_FILES, {
    context: { clientName: "graph" },
  });

  const [
    deleteTrainingMutation,
    {
      error: deleteTrainingError,
      loading: deleteTrainingLoading,
      data: deleteTrainingData,
      reset: deleteTrainingReset,
    },
  ] = useMutation(DELETE_TRAINING_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
  });

  const [
    deployTrainingMutation,
    {
      error: deployTrainingError,
      loading: deployTrainingLoading,
      data: deployTrainingData,
      reset: deployTrainingReset,
    },
  ] = useMutation(DEPLOY_TRAINING_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
  });

  const [
    stopRentedInstanceMutation,
    {
      error: stopRentedInstanceError,
      loading: stopRentedInstanceLoading,
      data: stopRentedInstanceData,
      reset: stopRentedInstanceReset,
    },
  ] = useMutation(STOP_RENTED_INSTANCE, {
    context: { clientName: "graph" },
  });

  const [
    startTrainingMutation,
    {
      error: startTrainingError,
      loading: startTrainingLoading,
      data: startTrainingData,
      reset: startTrainingReset,
    },
  ] = useMutation(START_TRAINING_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
  });

  const [
    addTrainingMutation,
    { error: addTrainingError, loading: addTrainingLoading, data: addTrainingData },
  ] = useMutation(CREATE_TRAINING_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
  });

  const [
    updateTrainingMutation,
    { error: updateTrainingError, loading: updateTrainingLoading, data: updateTrainingData },
  ] = useMutation(UPDATE_TRAINING_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
  });

  const [searchAvailableGPUs, { data: availableGPUData }] = useLazyQuery(
    LIST_AVAILABLE_GPU_INSTANCES,
    {
      context: "graph",
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    },
  );

  const [getRentedGPUSByRequestId, { data: rentedGPUsData }] = useLazyQuery(
    GET_RENTEDGPU_BY_REQUEST_ID,
    {
      context: "graph",
    },
  );

  const { data: modelData } = useQuery(LIST_MODELS_ADMIN, {
    context: "graph",
    variables: { companyId },
  });

  const watchAll = watch();

  const modelOptions =
    modelData?.listModelAdmin?.data.filter((row) => row.modelBaseModel === watchAll.mtNewModel) ||
    [];

  const getFilesDataOptions = getFilesData?.listFiles?.data || [];

  const availableGPUDataOptions = availableGPUData?.searchVastAIGPUInstances?.data || [];

  const rentedGPUDataOptions = rentedGPUsData?.getRentedGPUsByRequestId?.data || [];

  const loading = addTrainingLoading;

  const trainingStatus = requestToUpdate?.mtStatus || 0;

  const isUpdate = !!requestToUpdate;

  useEffect(() => {
    if (requestToUpdate) {
      setValue("modelVersionId", requestToUpdate.modelVersionId);
      setValue("mtNewModel", requestToUpdate.mtNewModel);
      setValue("mtName", requestToUpdate.mtName);
      setValue("mtDetectionItems", JSON.parse(requestToUpdate.mtDetectionItems));
      setValue("mtManualReview", requestToUpdate.mtManualReview);
      setValue("mtNotes", requestToUpdate.mtNotes);

      // loop ModelTrainingAnnotations to push id
      requestToUpdate.ModelTrainingAnnotations.forEach((annotation) => {
        setCheckedAnnotationIds((prev) => [...prev, annotation.annotationRequestId]);
      });

      getRentedGPUSByRequestId({ variables: { id: requestToUpdate.id } });

      getFiles({ variables: { modelTrainingRequestId: requestToUpdate.id } });

      // set correct showGPUSearch
      if (requestToUpdate.mtStatus >= 0 && requestToUpdate.mtStatus < 3) {
        setShowGPUSearch(true);
      }
    }
  }, [requestToUpdate, setShowGPUSearch]);

  useEffect(() => {
    if (startTrainingData) {
      addToast("GPU started successfully!", "success");
      startTrainingReset();
      handleCloseModal();
    }
    if (startTrainingError) {
      addToast(`Something went wrong! GPU did not create. ${startTrainingError.message}`, "error");
      startTrainingReset();
    }
    if (stopRentedInstanceError) {
      addToast(
        `Something went wrong! Training did not stop. ${stopRentedInstanceError.message}`,
        "error",
      );
    }
    if (stopRentedInstanceData) {
      addToast("Instance stopped successfully!", "success");
      handleCloseModal();
    }
    if (deleteTrainingData) {
      addToast("Training deleted successfully!", "success");
      handleCloseModal();
    }
    if (deleteTrainingError) {
      addToast(
        `Something went wrong! Training did not delete. ${deleteTrainingError.message}`,
        "error",
      );
      deleteTrainingReset();
    }
    if (deployTrainingData) {
      addToast("Training deployed successfully!", "success");
      handleCloseModal();
    }
    if (deployTrainingError) {
      addToast(
        `Something went wrong! Training did not deploy. ${deployTrainingError.message}`,
        "error",
      );
      deployTrainingReset();
    }
  }, [
    startTrainingError,
    startTrainingData,
    stopRentedInstanceError,
    stopRentedInstanceData,
    deleteTrainingData,
    deleteTrainingError,
    deployTrainingError,
    deployTrainingData,
  ]);

  useEffect(() => {
    if (addTrainingData) {
      addToast("Training created successfully!", "success");
      handleCloseModal();
    }
    if (addTrainingError) {
      addToast(
        `Something went wrong! Training did not create. ${addTrainingError.message}`,
        "error",
      );
    }
    if (updateTrainingData) {
      addToast("Training updated successfully!", "success");
      handleCloseModal();
    }
    if (updateTrainingError) {
      addToast(
        `Something went wrong! Training did not update. ${updateTrainingError.message}`,
        "error",
      );
    }
  }, [addTrainingData, addTrainingError, updateTrainingData, updateTrainingError]);

  const handleObjectChange = (objects) => {
    setValue("mtDetectionItems", objects || []);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setValue(name, parseInt(value, 10));
  };

  const handleCloseModal = () => {
    reset();
    setShowModal((prev) => !prev);
    onCloseModal();
  };

  const handleCheckBoxChange = (annotationId, event) => {
    const { checked: isChecked } = event.target;
    if (isChecked) {
      setCheckedAnnotationIds((prev) => [...prev, annotationId]);
    } else {
      setCheckedAnnotationIds((prev) => prev.filter((id) => id !== annotationId));
    }
  };

  const handleClickDeleteBtn = async () => {
    addModal("Cancel Training Request?", false, () => {
      deleteTrainingMutation({ variables: { id: requestToUpdate.id } });
    });
  };

  const handleClickTrainAgainBtn = async () => {
    addModal("This training has completed, are you sure you want to train again?", false, () => {
      setShowGPUSearch(true);
    });
  };

  const renderCheckboxRow = (caRow) => {
    const completeDate = new Date(parseInt(caRow.crCollectionCompleteDate) * 1000);

    // find if its checked
    const currentAnnotationId = checkedAnnotationIds.find(
      (id) => id === caRow.AnnotationRequest.id,
    );
    if (trainingStatus > 0 && !currentAnnotationId) {
      return null;
    }

    return (
      <Styles.ContainerRow key={caRow.AnnotationRequest.id}>
        <Styles.Label>
          <Checkbox
            id={caRow.AnnotationRequest.id}
            onChange={(e) => handleCheckBoxChange(caRow.AnnotationRequest.id, e)}
            checked={!!currentAnnotationId}
            disabled={trainingStatus > 0}
          />
          {cameraMap[caRow.machineId]} - {completeDate.toDateString()}
        </Styles.Label>
      </Styles.ContainerRow>
    );
  };

  const selectGPUForTraining = (gpu) => {
    // if mtStatus 3 then confirm retrain
    addModal(`Train model using ${gpu.gpu_name},${gpu.geolocation}?`, false, () => {
      startTrainingMutation({
        variables: { id: requestToUpdate.id, gpuOfferId: gpu.id, gpuMachineId: gpu.machine_id },
        refetchQueries: [{ query: LIST_TRAINING_REQUESTS, variables: { companyId } }],
      });
    });
  };

  const handleAvailableGPUSearch = () => {
    searchAvailableGPUs();
  };

  const stopSelectedGPU = (gpu) => {
    addModal("Stop training instance?", false, () => {
      stopRentedInstanceMutation({ variables: { id: gpu.id, rentedGPUsNotes: "user stop" } });
    });
  };

  const handleDownload = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const renderRentedGPUList = () => {
    if (!rentedGPUDataOptions || !rentedGPUDataOptions.length) return null;
    return rentedGPUDataOptions.map((gpu) => {
      // create start time
      const startTime = new Date(parseInt(gpu.rentedGPUsStartedDate));
      return (
        <Styles.ContainerRow key={gpu.id}>
          <Styles.Label title={gpu.rentedGPUsProvider}>
            {gpu.rentedGPUsStatus < 2 && (
              <Styles.LabelButton onClick={() => stopSelectedGPU(gpu)}>[stop] </Styles.LabelButton>
            )}
            GPU-{startTime.toDateString()}-{rentedStatus[gpu.rentedGPUsStatus]}
          </Styles.Label>
        </Styles.ContainerRow>
      );
    });
  };

  const renderModelFiles = () => {
    if (!getFilesDataOptions || !getFilesDataOptions.length) return null;
    return getFilesDataOptions.map((modelFile) => {
      return (
        <Styles.ContainerRow key={modelFile.id}>
          <Styles.Label>
            <Styles.LabelButton onClick={() => handleDownload(modelFile.fileSignedUrl)}>
              [download]
            </Styles.LabelButton>{" "}
            {modelFile.fileAssetUrl}
          </Styles.Label>
        </Styles.ContainerRow>
      );
    });
  };

  const renderChartFile = () => {
    if (!getFilesDataOptions || !getFilesDataOptions.length) return null;
    return getFilesDataOptions
      .filter((modelFile) => modelFile.fileAssetUrl.includes("chart"))
      .map((modelFile) => {
        return (
          <Styles.ContainerRow key={modelFile.id}>
            <Styles.ImageCard src={modelFile.fileSignedUrl} />
          </Styles.ContainerRow>
        );
      });
  };

  const renderAvailableGPUList = () => {
    if (!availableGPUDataOptions || !availableGPUDataOptions.length) return null;
    return availableGPUDataOptions.map((gpu) => {
      return (
        <Styles.ContainerRow key={gpu.id}>
          <Styles.Label title={gpu.dph_total}>
            <Styles.LabelButton onClick={() => selectGPUForTraining(gpu)}>
              [select]
            </Styles.LabelButton>{" "}
            {gpu.gpu_name}, {gpu.gpu_ram / 1000}
            gig {gpu.geolocation}
          </Styles.Label>
        </Styles.ContainerRow>
      );
    });
  };

  const renderAnnotationList = () => {
    if (!collectionAnnotationData || !collectionAnnotationData.length) return null;
    return collectionAnnotationData.map((caRow) => {
      if (caRow.AnnotationRequest) {
        return renderCheckboxRow(caRow);
      }
      return null;
    });
  };

  const handleDeploy = () => {
    addModal("Deploy model?", false, () => {
      deployTrainingMutation({ variables: { id: requestToUpdate.id } });
    });
  };

  const submitForm = async (form) => {
    console.log("submitForm", form, checkedAnnotationIds);
    if (loading) return;

    // use form.modelVersionId to find model.id
    let modelId;
    let mtModel;

    modelOptions.forEach((model) => {
      model.ModelVersions.forEach((ver) => {
        if (ver.id === form.modelVersionId) {
          modelId = model.id;
          mtModel = model.modelType;
        }
      });
    });

    if (requestToUpdate) {
      updateTrainingMutation({
        variables: {
          id: requestToUpdate.id,
          modelId,
          mtModel,
          mtName: form.mtName,
          mtNewModel: form.mtNewModel,
          modelVersionId: form.modelVersionId,
          mtDetectionItems: JSON.stringify(form.mtDetectionItems),
          annotationIds: JSON.stringify(checkedAnnotationIds),
          mtManualReview: form.mtManualReview,
          mtNotes: form.mtNotes,
        },
      });
    } else {
      addTrainingMutation({
        variables: {
          companyId,
          modelId,
          mtModel,
          mtName: form.mtName,
          mtNewModel: form.mtNewModel,
          modelVersionId: form.modelVersionId,
          mtDetectionItems: JSON.stringify(form.mtDetectionItems),
          annotationIds: JSON.stringify(checkedAnnotationIds),
          mtManualReview: form.mtManualReview,
          mtNotes: form.mtNotes,
        },
      });
    }
  };

  const renderModelOptions = () => {
    return modelOptions.map((model) => {
      return model.ModelVersions.map((modelVersion) => {
        return (
          <option value={modelVersion.id} key={modelVersion.id}>
            {model.modelName}, Ver.{modelVersion.modelVersionNo}
          </option>
        );
      });
    });
  };

  const renderGPUSearch = () => {
    if (!showGPUSearch) return null;

    return (
      <Styles.InputAndErrorContainer>
        <Styles.Label>
          Search for Available GPUs{" "}
          <Styles.LabelButton onClick={handleAvailableGPUSearch}>[Search]</Styles.LabelButton>
        </Styles.Label>
        <Styles.ListContainer>{renderAvailableGPUList()}</Styles.ListContainer>
      </Styles.InputAndErrorContainer>
    );
  };

  usePreventEnterAction(submitForm);

  const objectSuggestions = objectDetectionItems.map((item) => ({
    value: item.objectDetectionIndex,
    label: item.objectDetectionLabel,
  }));

  const modalTitle = isUpdate ? "Model Training Request" : "New Model Training Request";
  const trainingStatusText = `Training Status: ${mtStatus[requestToUpdate?.mtStatus]} ${new Date(
    parseInt(requestToUpdate?.mtStartedDate),
  ).toDateString()}`;

  return (
    <ModalWithChildren
      width={600}
      showModal={showModal}
      setShowModal={handleCloseModal}
      title={modalTitle}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.CheckboxAndIconContainer>
          <Styles.Label>
            <RadioButton
              name="mtNewModel"
              value="1"
              onChange={handleRadioChange}
              checked={watchAll.mtNewModel}
              disabled={trainingStatus > 0}
            />
            Train new model
          </Styles.Label>
        </Styles.CheckboxAndIconContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Name your model</Styles.Label>
          <Styles.Input
            type="text"
            placeholder="Model Name"
            disabled={trainingStatus > 0 || !watchAll.mtNewModel}
            {...register("mtName")}
          />
          <Styles.SubmitError>{errors.mtNotes?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.CheckboxAndIconContainer>
          <Styles.Label>
            <RadioButton
              name="mtNewModel"
              value="0"
              onChange={handleRadioChange}
              checked={!watchAll.mtNewModel}
              disabled={trainingStatus > 0}
            />
            Retrain existing model
          </Styles.Label>
        </Styles.CheckboxAndIconContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Select model to train/retrain</Styles.Label>
          <Styles.Select {...register("modelVersionId")} disabled={trainingStatus > 0}>
            <option value="" key="" disabled>
              Select model
            </option>
            {renderModelOptions()}
          </Styles.Select>
          <Styles.SubmitError>{errors.modelVersionId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Object Detection List</Styles.Label>
          <Styles.ComboSelect>
            <ObjectSelector
              values={watchAll.mtDetectionItems}
              onChange={handleObjectChange}
              suggestions={objectSuggestions}
              disabled={trainingStatus > 0}
            />
          </Styles.ComboSelect>
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Select annotated data for training</Styles.Label>
          <div>{renderAnnotationList()}</div>
        </Styles.InputAndErrorContainer>
        {renderGPUSearch()}
        {trainingStatus >= 1 && (
          <Styles.InputAndErrorContainer>
            <Styles.Label>Rented GPUs</Styles.Label>
            <Styles.ListContainer>{renderRentedGPUList()}</Styles.ListContainer>
          </Styles.InputAndErrorContainer>
        )}
        {trainingStatus >= 1 && (
          <Styles.InputAndErrorContainer>
            <Styles.Label>{trainingStatusText}</Styles.Label>
            <Styles.ListContainer>
              {renderChartFile()}
              {renderModelFiles()}
            </Styles.ListContainer>
          </Styles.InputAndErrorContainer>
        )}
        {loading ? (
          <>
            <Styles.SpinnerContainer>
              <SpinnerComponent width={"5rem"} height={"5rem"} />
            </Styles.SpinnerContainer>
          </>
        ) : (
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Close"
              handleClick={handleCloseModal}
            />
            {trainingStatus === 0 && (
              <Styles.SubmitInput
                type="submit"
                id="submit"
                value={isUpdate ? "Update" : "Create"}
              />
            )}
            {trainingStatus === 3 && (
              <Styles.SubmitInput type="button" value="Accept & Deploy" onClick={handleDeploy} />
            )}
          </Styles.ButtonsContainer>
        )}
      </Styles.Form>
      {trainingStatus === 1 && (
        <Styles.Button onClick={handleClickDeleteBtn}>[Cancel training request]</Styles.Button>
      )}
      {trainingStatus === 3 && (
        <Styles.Button onClick={handleClickTrainAgainBtn}>[Train again]</Styles.Button>
      )}
    </ModalWithChildren>
  );
};

export default StartTrainingRequest;

import React, { useEffect, useContext, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { ReactComponent as ViewOff } from "../../../../assets/icons/view_off.svg";
import { ReactComponent as ViewOn } from "../../../../assets/icons/view_on.svg";

import { LIST_MODELS_ADMIN } from "../../../../graphql/graph.js";
import ModelTables from "./ModelTables.jsx";
import AddUpdateModelModal from "../../../Modals/AddUpdateModel";
import AddModelVersion from "../../../Modals/AddModelVersion";
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import { AuthContext } from "../../../../context/authContext";
import RoundButton from "../../../../components/Shared/Buttons/RoundButton";

import Styles from "./styles";

const ModelManager = () => {
  const { isSuperAdmin } = useContext(AuthContext);
  const [modelData, setModelData] = useState([]);
  const [modelToUpdate, setModelToUpdate] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [showAddUpdateModelModal, setShowAddUpdateModelModal] = useState(false);
  const [showAddModelVersionModal, setShowAddModelVersionModal] = useState(false);

  const [getModels, { data: modelResults }] = useLazyQuery(LIST_MODELS_ADMIN, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    getModels({ variables: { listAll: 1 } });
  }, []);

  useEffect(() => {
    if (modelResults) {
      setModelData(modelResults.listModelAdmin.data);
    }
  }, [modelResults]);

  const handleClickAddModelVersion = (row) => {
    setModelToUpdate({
      ...row.model,
    });
    setShowAddModelVersionModal(true);
  };

  const handleClickUpdateModel = (row) => {
    setModelToUpdate({
      ...row.model,
      modelVersion: row.modelVersion,
    });
    setShowAddUpdateModelModal(true);
  };

  const handleClickAddModel = () => {
    setShowAddUpdateModelModal(true);
  };

  const onCloseModal = () => {
    setShowAddUpdateModelModal(false);
    setShowAddModelVersionModal(false);
    setModelToUpdate(null);
  };

  if (!isSuperAdmin) {
    return null;
  }

  const filteredModelData = viewAll
    ? modelData
    : modelData.filter((model) => model.modelStatus === 1);

  return (
    <Styles.ManageModelsContainer>
      <Styles.Header>
        <Styles.Title>Model Manager</Styles.Title>
        <Styles.RowActionsContainer>
          <RoundButton
            boxshadow
            icon={viewAll ? <ViewOff /> : <ViewOn />}
            handleClick={() => setViewAll((prev) => !prev)}
            tooltipText={viewAll ? "View All Models" : "View Active Models"}
          />
          <BlueBtn label={"Add Model"} handleClick={handleClickAddModel} />
        </Styles.RowActionsContainer>
      </Styles.Header>
      <ModelTables
        dataRows={filteredModelData}
        addVersion={handleClickAddModelVersion}
        updateModel={handleClickUpdateModel}
      />

      {showAddUpdateModelModal && (
        <AddUpdateModelModal
          showModal={showAddUpdateModelModal}
          setShowModal={onCloseModal}
          modelToUpdate={modelToUpdate}
          setModelToUpdate={setModelToUpdate}
        />
      )}
      {showAddModelVersionModal && (
        <AddModelVersion
          showModal={showAddModelVersionModal}
          setShowModal={onCloseModal}
          modelToUpdate={modelToUpdate}
          setModelToUpdate={setModelToUpdate}
        />
      )}
    </Styles.ManageModelsContainer>
  );
};

export default ModelManager;

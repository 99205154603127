import React, { useRef } from "react";
import Styles from "./styles";
//Components
import DefaultDashBtn from "../Buttons/DefaultDashBtn";

const ModalDefault = ({
  showModal,
  closeModal,
  title,
  handleModalForwardClick,
  infoModal,
  subtitle,
}) => {
  const modalRef = useRef();

  const closeModalRef = (e) => {
    if (modalRef.current === e.target) {
      closeModal();
    }
  };

  return (
    <>
      {showModal ? (
        <Styles.Background onClick={closeModalRef} ref={modalRef}>
          <Styles.ModalWrapper showModal={showModal}>
            <Styles.CloseModalButton aria-label="Close modal" onClick={() => closeModal()} />
            <Styles.ModalContent>
              <Styles.Title>{title}</Styles.Title>
              {subtitle ? <Styles.SubTitle>{subtitle}</Styles.SubTitle> : null}
              <Styles.ButtonsContainer>
                {infoModal ? (
                  <DefaultDashBtn
                    orangebg
                    smallsize
                    label="Ok"
                    handleClick={() => handleModalForwardClick()}
                  />
                ) : (
                  <>
                    <DefaultDashBtn
                      whitebg
                      orangeborder
                      orangecolor
                      smallsize
                      label="No"
                      handleClick={() => closeModal()}
                    />
                    <DefaultDashBtn
                      orangebg
                      smallsize
                      label="Yes"
                      handleClick={() => handleModalForwardClick()}
                    />
                  </>
                )}
              </Styles.ButtonsContainer>
            </Styles.ModalContent>
          </Styles.ModalWrapper>
        </Styles.Background>
      ) : null}
    </>
  );
};

export default ModalDefault;

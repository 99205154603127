import React, { useContext } from "react";
//Styles
import Styles from "./styles";
//Components
import UpdateProfileAndPass from "../../../../components/BackOffice/UpdateProfile";
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
//Context
import { AuthContext } from "../../../../context/authContext";

const UserProfile = () => {
  const { loggedUserAttributes } = useContext(AuthContext);

  return (
    <Styles.UserProfileContainer>
      <Styles.Header>
        <Styles.Title>{loggedUserAttributes.userFirstName} Profile</Styles.Title>
        <BlueBtn label="Delete Profile" />
      </Styles.Header>
      <UpdateProfileAndPass loggedUserAttributes={loggedUserAttributes} />
    </Styles.UserProfileContainer>
  );
};

export default UserProfile;

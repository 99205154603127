import React, { useState, useContext, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client/react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import Checkbox from "../../../components/Checkbox";
import FileUpload from "../../../components/FileUpload";
import ObjectSelector from "../../../components/ObjectSelector";
import { useToastModal } from "../../../context/ToastModalContext";
import { modelTypes } from "../../../configs/configEnviroment";
import { jsonParse } from "../../../configs/helpers";

import {
  LIST_MODELS_ADMIN,
  CREATE_MODEL,
  UPDATE_MODEL,
  UPDATE_FILE_ASSET,
} from "../../../graphql/graph";
import { DashboardContext } from "../../../context/DashboardContext";

//needed inside to dynamically set required field based on modal type
const inputFormSchema = yup.object().shape({
  modelName: yup.string().required("Model name is a required field."),
});

const AddUpdateModelModal = ({ showModal, setShowModal, modelToUpdate, setModelToUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFilesError, setSelectedFilesError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const uploaderRef = useRef();

  const { objectDetectionItems, companiesList } = useContext(DashboardContext);

  const objectSuggestions = objectDetectionItems.map((item) => ({
    value: item.objectDetectionIndex,
    label: item.objectDetectionLabel,
  }));

  const isUpdate = modelToUpdate ? true : false;

  const { register, watch, setValue, handleSubmit, reset, formState } = useForm(
    {
      resolver: yupResolver(inputFormSchema),
    },
    {
      defaultValues: {
        modelName: "",
        modelDescription: "",
        modelStatus: 1,
        modelVersionStatus: 1,
        modelBaseModel: 0,
        modelVersionNotes: "",
        companyId: null,
        modelDetectionObjects: [],
      },
    },
  );

  const handleObjectChange = (objects) => {
    // console.log("objects", objects);
    setValue("modelDetectionObjects", objects || []);
  };

  const modelWatch = watch();

  // const modelStatus = watch("modelStatus");
  // const modelBaseModel = watch("modelBaseModel");
  // const modelVersionStatus = watch("modelVersionStatus");
  const modelDetectionObjects = watch("modelDetectionObjects", []);

  const { errors } = formState;

  useEffect(() => {
    // if user selects company id, set base model to false
    if (modelWatch.companyId && modelWatch.modelBaseModel === 1) {
      setValue("modelBaseModel", 0);
    }
  }, [modelWatch]);

  useEffect(() => {
    reset(modelToUpdate);
  }, [reset, modelToUpdate]);

  useEffect(() => {
    // set manual registration
    register("modelStatus");
    register("modelBaseModel");
    register("modelVersionStatus");

    if (modelToUpdate) {
      console.log("modelToUpdate", modelToUpdate);

      // set model status
      setValue("modelStatus", modelToUpdate.modelStatus);
      setValue("modelBaseModel", modelToUpdate.modelBaseModel);

      const { modelVersion } = modelToUpdate;
      setValue("modelVersionStatus", modelVersion.modelVersionStatus);
      setValue("modelVersionNotes", modelVersion.modelVersionNotes);

      // set object detection
      setValue("modelDetectionObjects", jsonParse(modelToUpdate.modelDetectionObjects));

      setSelectedFiles(modelVersion.FileAssets);
    } else {
      setValue("modelStatus", 1);
      setValue("modelVersionStatus", 1);
      setValue("modelBaseModel", 1);
    }
  }, [modelToUpdate]);

  const [addModelMutation] = useMutation(CREATE_MODEL, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_MODELS_ADMIN,
        context: { clientName: "graph" },
        variables: { listAll: 1 },
      },
    ],
  });

  const [updateModelMutation] = useMutation(UPDATE_MODEL, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_MODELS_ADMIN,
        context: { clientName: "graph" },
        variables: { listAll: 1 },
      },
    ],
  });

  const [updateFileAssetMutation] = useMutation(UPDATE_FILE_ASSET, {
    context: { clientName: "graph" },
  });

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked ? 1 : 0;
    if (target.name === "modelStatus") {
      setValue("modelStatus", value);
    } else if (target.name === "modelBaseModel") {
      setValue("modelBaseModel", value);

      // remove company id if base model is selected
      if (value) {
        setValue("companyId", null);
      }
    } else {
      setValue("modelVersionStatus", value);
    }
  };

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  const handleFileUpload = async (modelVersionId) => {
    console.log(uploaderRef.current);
    if (uploaderRef && uploaderRef.current) {
      await uploaderRef.current.handleModelFileUpload(modelVersionId);
    }
  };

  //Handle submit Form, create model
  const submitForm = async (inputForm) => {
    // console.log("inputForm", inputForm, modelToUpdate);

    if (loading) return;

    setLoading(true);

    if (modelToUpdate) {
      // upload new files if any
      if (selectedFiles.length > 0) {
        await handleFileUpload(modelToUpdate.modelVersion.id);
      }

      await updateModelMutation({
        variables: {
          id: modelToUpdate.id,
          modelName: inputForm.modelName,
          modelDescription: inputForm.modelDescription,
          modelStatus: inputForm.modelStatus,
          modelBaseModel: inputForm.modelBaseModel,
          modelDetectionObjects: JSON.stringify(inputForm.modelDetectionObjects),
          modelType: inputForm.modelType,
          modelVersionId: modelToUpdate.modelVersion.id,
          modelVersionNotes: inputForm.modelVersionNotes,
          modelVersionStatus: inputForm.modelVersionStatus,
          companyId: inputForm.companyId,
        },
      });
    } else {
      if (selectedFiles.length === 0) {
        setSelectedFilesError("Please select at least one file");
        setLoading(false);
        return;
      }

      const addResult = await addModelMutation({
        variables: {
          modelName: inputForm.modelName,
          modelDescription: inputForm.modelDescription,
          modelStatus: inputForm.modelStatus,
          modelBaseModel: inputForm.modelBaseModel,
          modelDetectionObjects: JSON.stringify(inputForm.modelDetectionObjects),
          modelType: inputForm.modelType,
          modelVersionStatus: inputForm.modelVersionStatus,
          modelVersionNotes: inputForm.modelVersionNotes,
          companyId: inputForm.companyId,
        },
      });

      console.log("addResult", addResult);

      const ModelVersions = addResult.data?.createModel?.data?.ModelVersions;

      const modelVersionId = ModelVersions[0].id;

      // add model will create version as well, upload file once id is obtained
      await handleFileUpload(modelVersionId);
    }
    setTimeout(async () => {
      setLoading(false);
      reset();
      setShowModal((prev) => !prev);
      setModelToUpdate(null);
    }, 1000);
  };

  const { addModal } = useToastModal();

  const handleExistingFileDelete = (fileId) => {
    addModal("Delete file?", false, () => {
      confirmDeleteFile(fileId);
    });
  };

  const confirmDeleteFile = async (fileId) => {
    // or remove via mutation
    await updateFileAssetMutation({
      variables: {
        id: fileId,
        fileAssetVoid: 1,
      },
    });

    const newFiles = selectedFiles.filter((existingFile) => existingFile.id !== fileId);
    setSelectedFiles(newFiles);
  };

  usePreventEnterAction(submitForm);

  const modalTitle = isUpdate ? "Update Model" : "Add Model";
  const modelVersionNo = isUpdate ? modelToUpdate.modelVersion?.modelVersionNo : 1;

  // console.log("modelWatch", modelWatch);

  return (
    <ModalWithChildren
      width={600}
      showModal={showModal}
      setShowModal={setShowModal}
      title={modalTitle}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Model Name</Styles.Label>
          <Styles.Input type="text" name="modelName" {...register("modelName")} />
          <Styles.SubmitError>{errors.modelName?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Model Type</Styles.Label>
          <Styles.Select name="modelType" id="modelType" {...register("modelType")}>
            {modelTypes.map((defaultType) => (
              <option value={defaultType.value} key={defaultType.value}>
                {defaultType.label}
              </option>
            ))}
          </Styles.Select>
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Description</Styles.Label>
          <Styles.TextArea type="text" name="modelDescription" {...register("modelDescription")} />
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Object Detection List</Styles.Label>
          <Styles.ComboSelect>
            <ObjectSelector
              values={modelDetectionObjects}
              onChange={handleObjectChange}
              suggestions={objectSuggestions}
            />
          </Styles.ComboSelect>
        </Styles.InputAndErrorContainer>
        <Styles.TwoColumnContainer>
          <Styles.CheckboxAndIconContainer>
            <Styles.Label>Base Model</Styles.Label>
            <Styles.Label>
              <Checkbox
                name="modelBaseModel"
                id="modelBaseModel"
                onChange={handleCheckboxChange}
                checked={modelWatch.modelBaseModel}
                disabled={isUpdate}
              />
              Base Model Only
            </Styles.Label>
          </Styles.CheckboxAndIconContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Company Model only</Styles.Label>
            <Styles.Select
              name="companyId"
              id="companyId"
              {...register("companyId")}
              disabled={isUpdate}
            >
              <option value={null}>No Company selected</option>
              {companiesList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.companyName}
                </option>
              ))}
            </Styles.Select>
          </Styles.InputAndErrorContainer>
        </Styles.TwoColumnContainer>
        <Styles.TwoColumnContainer>
          <Styles.CheckboxAndIconContainer>
            <Styles.Label>Model Status</Styles.Label>
            <Styles.Label>
              <Checkbox
                name="modelStatus"
                id="modelStatus"
                onChange={handleCheckboxChange}
                checked={modelWatch.modelStatus}
              />
              Enabled
            </Styles.Label>
          </Styles.CheckboxAndIconContainer>
        </Styles.TwoColumnContainer>
        <Styles.Label>Model Version {modelVersionNo}</Styles.Label>
        <FileUpload
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          selectedFilesError={selectedFilesError}
          setSelectedFilesError={setSelectedFilesError}
          deleteFile={handleExistingFileDelete}
          ref={uploaderRef}
        />
        <Styles.SubmitError>{selectedFilesError}</Styles.SubmitError>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Version Description</Styles.Label>
          <Styles.TextArea
            type="text"
            name="modelVersionNotes"
            {...register("modelVersionNotes")}
          />
        </Styles.InputAndErrorContainer>
        <Styles.CheckboxAndIconContainer>
          <Styles.Label>Version Status</Styles.Label>
          <Styles.Label>
            <Checkbox
              name="modelVersionStatus"
              id="modelVersionStatus"
              onChange={handleCheckboxChange}
              checked={modelWatch.modelVersionStatus}
            />
            Enabled
          </Styles.Label>
        </Styles.CheckboxAndIconContainer>
        {loading ? (
          <>
            <Styles.SpinnerContainer>
              <SpinnerComponent width={"5rem"} height={"5rem"} />
            </Styles.SpinnerContainer>
          </>
        ) : (
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <Styles.SubmitInput type="submit" id="submit" value={modalTitle} />
          </Styles.ButtonsContainer>
        )}
      </Styles.Form>
    </ModalWithChildren>
  );
};

export default AddUpdateModelModal;

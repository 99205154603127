import styled from "styled-components/macro";
//Icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/garbage_gray_icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow_down_gray.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow_up_gray.svg";
import { Link } from "react-router-dom";

const styles = {
  //Icons
  DeleteIcon: styled(DeleteIcon)`
    cursor: pointer;
  `,
  EditIcon: styled(EditIcon)`
    cursor: pointer;
  `,
  ArrowDown: styled(ArrowDown)`
    cursor: pointer;
  `,
  ArrowUp: styled(ArrowUp)`
    cursor: pointer;
  `,
  //Styles
  CompanySettingsContainer: styled.div`
    display: flex;
    flex-direction: column;
    background: white;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    /* border-bottom: 1px solid #C4C4C4; */
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
  Table: styled.div`
    display: flex;
    flex-direction: column;
  `,

  TableHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #b0bec5;
    /* padding: 0.5rem 0; */
    :first-child {
      padding-left: 1rem;
    }
    @media screen and (max-width: 480px) {
      :first-child {
        padding-left: 0;
      }
      flex-direction: column;
      gap: 1rem;
    }
  `,
  TableHeaderCols: styled.div`
    display: flex;
    justify-content: center;
  `,
  TableHeaderText: styled.span`
    font-family: "Poppins700";
    font-size: 14px;
    color: #455a64;
  `,
  TableRow: styled.div``,
  CompanyContainer: styled.div`
    background: #ffffff;
    height: 68px;
    border-bottom: ${(props) => (props.rowToggle ? "none" : "1px solid #b0bec5")};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    cursor: pointer;
  `,
  CompanyName: styled.span`
    font-family: "PoppinsRegular";
    font-size: 14px;
    color: #455a64;
  `,
  ActionIconsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
  `,
  VenueRowContainer: styled.div`
    background: #f1f1f1;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
  `,
  VenuesRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #c4c4c4;
    border-bottom: ${(props) => (props.lastRow ? "none" : "1px solid #b0bec5")};
  `,
  VenueName: styled.h4`
    margin: 0;
    color: #888888;
    font-family: "Poppins700";
    font-size: 14px;
  `,
  AddVenueBtnContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0;
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Link: styled(Link)`
    font-family: PoppinsRegular;
    font-size: 14px;
    font-weight: ${({ venueactive }) => venueactive && "bold"};
    color: ${({ activetab, venuenotactive, venueactive }) =>
      activetab ? "#FFFFFF" : venuenotactive ? "#909090" : venueactive ? "#5CA6B3" : "#455a64"};
    text-decoration: none;
    padding: ${({ isvenue }) => (isvenue ? "0 1.5rem" : "0 0.5rem")};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `,
};

export default styles;

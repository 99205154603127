import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import StartCaptureRequest from "../../Modals/StartCaptureRequest/index.jsx";
import StartAnnotationRequest from "../../Modals/StartAnnotationRequest/index.jsx";
import StartTrainingRequest from "../../Modals/StartTrainingRequest/index.jsx";
import AnnotationRequestsTable from "./AnnotationRequestsTable.jsx";
import TrainingRequestsTable from "./TrainingRequestsTable.jsx";

import { COMBINED_LIST_COLLECTION_AND_TRAINING_REQUESTS } from "../../../graphql/graph";

import Styles from "./styles";

const Training = ({ venuesList }) => {
  const { companyId } = useParams();
  const [showTraining, setShowTraining] = useState(false);
  const [showCaptureModal, setShowCaptureModal] = useState(false);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [requestToUpdate, setRequestToUpdate] = useState(null);

  const [getCollectionRequests, { data: combinedRequestData }] = useLazyQuery(
    COMBINED_LIST_COLLECTION_AND_TRAINING_REQUESTS,
    {
      context: { clientName: "graph" },
    },
  );

  const handleCollectionRequestsFetch = async (companyId) => {
    await getCollectionRequests({
      variables: {
        companyId,
      },
    });
  };

  useEffect(() => {
    if (companyId) {
      handleCollectionRequestsFetch(companyId);
    }
  }, [companyId]);

  const handleToggleTrainingContainer = () => {
    setShowTraining(!showTraining);
  };

  const handleTrainNewModel = () => {
    setShowTrainingModal(!showTrainingModal);
  };

  const handleStartCapture = () => {
    setShowCaptureModal(!showCaptureModal);
  };

  const handleUpdateItem = (item) => {
    // if collection is status 3, show annotation instead
    if (item.crCollectionStatus === 3) {
      setRequestToUpdate(item);
      setShowAnnotationModal(true);
    } else {
      setRequestToUpdate(item);
      setShowCaptureModal(true);
    }
  };

  const handleUpdateTrainingItem = (item) => {
    setRequestToUpdate(item);
    setShowTrainingModal(true);
  };

  const onCloseModal = async () => {
    setRequestToUpdate(null);
    await handleCollectionRequestsFetch(companyId);
  };

  const collectionAnnotationData = combinedRequestData?.listCollectionRequest?.data || [];
  const collectionTrainingData = combinedRequestData?.listTrainingRequest?.data || [];

  const cameraMap = venuesList.reduce((prev, curr) => {
    curr.Areas.forEach((area) => {
      area.Machines.forEach((machine) => {
        prev[machine.id] = `${curr.venueName} - ${area.areaName} - ${machine.machineName}`;
      });
    });
    return prev;
  }, {});

  // console.log("collectionTrainingData", collectionTrainingData);

  return (
    <Styles.CompanyDashboardContainer>
      <Styles.Header>
        <Styles.SubTitle>Training</Styles.SubTitle>
        <Styles.IconButton onClick={handleToggleTrainingContainer}>
          {showTraining ? <ChevronDown /> : <ChevronUp />}
        </Styles.IconButton>
      </Styles.Header>
      <Styles.DockerContainer>
        {showTraining && (
          <>
            <Styles.Section>
              <Styles.Header>
                <Styles.TableTitle>Collection and Annotation Requests</Styles.TableTitle>
                <Styles.Actions>
                  <BlueBtn label={"Start capture request"} handleClick={handleStartCapture} />
                </Styles.Actions>
              </Styles.Header>
              <AnnotationRequestsTable
                requestsList={collectionAnnotationData}
                updateItem={handleUpdateItem}
                cameraMap={cameraMap}
              />
            </Styles.Section>
            <Styles.Section>
              <Styles.Header>
                <Styles.TableTitle>Training Requests</Styles.TableTitle>
                <Styles.Actions>
                  <BlueBtn label={"Train new model"} handleClick={handleTrainNewModel} />
                </Styles.Actions>
              </Styles.Header>
              <TrainingRequestsTable
                requestsList={collectionTrainingData}
                updateItem={handleUpdateTrainingItem}
              />
            </Styles.Section>
          </>
        )}
      </Styles.DockerContainer>
      {showCaptureModal && (
        <StartCaptureRequest
          companyId={companyId}
          showModal={showCaptureModal}
          venuesList={venuesList}
          setShowModal={setShowCaptureModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
      {showAnnotationModal && (
        <StartAnnotationRequest
          companyId={companyId}
          showModal={showAnnotationModal}
          venuesList={venuesList}
          setShowModal={setShowAnnotationModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
      {showTrainingModal && (
        <StartTrainingRequest
          companyId={companyId}
          showModal={showTrainingModal}
          cameraMap={cameraMap}
          collectionAnnotationData={collectionAnnotationData}
          setShowModal={setShowTrainingModal}
          requestToUpdate={requestToUpdate}
          onCloseModal={onCloseModal}
        />
      )}
    </Styles.CompanyDashboardContainer>
  );
};

export default Training;

import styled from "styled-components/macro";

const styles = {
  AuthContainer: styled.div`
    width: 80%;
    /* height: 100%; */
    /* margin: 2rem 0; */
    border-radius: 30px;
    background-color: #ffffff;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  FormContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 3rem 4rem;
    @media screen and (max-width: 768px) {
      padding: 3rem;
    }
    @media screen and (max-width: 480px) {
      padding: 2rem 1.5rem;
    }
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
    gap: 1rem;
  `,
  Input: styled.input`
    border: 1px solid #c4c4c4;
    height: 35px;
    background: ${(props) => (props.disabled ? "#ececec" : "transparent")};
    font-family: "Roboto400";
    font-size: 18px;
    padding: 0 1rem;
    color: #37474f;
    outline: none;
    @media screen and (max-width: 480px) {
      font-size: 17px;
      height: 43px;
    }
  `,
  TextArea: styled.textarea`
    border: 1px solid #c4c4c4;
    font-family: "Roboto400";
    font-size: 21px;
    padding: 1rem;
    color: #37474f;
    height: 7rem;
    resize: vertical;
    min-height: 1.5rem;
    max-height: 7rem;
    outline: none;
    @media screen and (max-width: 480px) {
      font-size: 17px;
    }
  `,
  SubmitInput: styled.input`
    background: #5ca6b3;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
    font-family: "Roboto400";
    font-size: 20px;
    color: #ffffff;
    width: 50%;
    height: 45px;
    cursor: pointer;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `,
  Title: styled.h1`
    font-family: "Poppins700";
    font-weight: 700;
    font-size: 40px;
    color: #37474f;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 480px) {
      font-size: 44px;
    }
    @media screen and (max-width: 480px) {
      line-height: 54px;
    }
  `,
  Paragraph: styled.p`
    font-family: "Roboto400";
    font-size: 18px;
    color: #37474f;
    margin: 0;
    padding: 0;
    display: ${({ forgotPassword }) => (forgotPassword ? "flex" : null)};
    justify-content: ${({ forgotPassword }) => (forgotPassword ? "center" : null)};
    margin: ${({ forgotPassword }) => (forgotPassword ? "1rem 0" : null)};
  `,
  SubmitServerError: styled.div`
    display: flex;
    font-family: "Roboto400";
    font-size: 14px;
    margin-bottom: 0.8rem;
    color: #ff3333;
  `,
  IconWrapper: styled.div`
    position: absolute;
    right: 15px;
    top: 35px;
    cursor: pointer;
    @media screen and (max-width: 480px) {
      right: 10px;
      top: 36px;
    }
  `,
  LoadingAndErrorContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
    border-radius: 30px;
    background-color: #ffffff;
  `,
};

export default styles;

// Styles
import Styles from "./styles";

export const FormGroup = (props) => {
  const { labelText, children, errorMessage, profileForm } = props;
  return (
    <Styles.FormWrapper>
      <Styles.Label profileForm={profileForm}>{labelText}</Styles.Label>
      {children}
      {errorMessage ? <Styles.SubmitError>{errorMessage}</Styles.SubmitError> : null}
    </Styles.FormWrapper>
  );
};

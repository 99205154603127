import React, { useState } from "react";
//Styles
import Styles from "./styles";
//Components
import CompanyVenues from "./CompanyVenues";
import UpdateCompanyModal from "../../../Modals/AddUpdateCompany/UpdateCompany";
import { useToastModal } from "../../../../context/ToastModalContext";

const CompanyRow = ({
  company,
  createVenue,
  updateVenue,
  deleteVenue,
  updateCompany,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
}) => {
  const [rowToggle, setRowToggle] = useState(false);
  const [activeRowId, setActiveRowId] = useState("");
  const [companyObj, setCompanyObj] = useState({});
  const [showUpdateCompanyModal, setShowUpdateCompanyModal] = useState(false);

  const handleToggle = (rowId) => {
    setRowToggle(!rowToggle);
    setActiveRowId(rowId);
  };

  const { addToast } = useToastModal();

  const handleUpdateCompanyClick = (company) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, company.id)) {
      setCompanyObj({ id: company.id, companyName: company.companyName });
      setShowUpdateCompanyModal((prev) => !prev);
    } else {
      addToast("You must be a company Admin to update company!", "error");
    }
  };

  return (
    <>
      <Styles.CompanyContainer
        onClick={() => handleToggle(company.id)}
        rowToggle={rowToggle ? +true : +false}
      >
        <div>
          <Styles.CompanyName>{company.companyName}</Styles.CompanyName>
        </div>
        <Styles.ActionIconsContainer>
          <Styles.EditIcon onClick={() => handleUpdateCompanyClick(company)} />
          {/* <Styles.DeleteIcon /> */}
          {rowToggle && company.id === activeRowId ? (
            <Styles.ArrowUp
              onClick={() => handleToggle(company.id)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Styles.ArrowDown
              onClick={() => handleToggle(company.id)}
              style={{ cursor: "pointer" }}
            />
          )}
          <UpdateCompanyModal
            showModal={showUpdateCompanyModal}
            setShowModal={setShowUpdateCompanyModal}
            companyObj={companyObj}
            updateCompany={updateCompany}
            title={"Update Company Name"}
          />
        </Styles.ActionIconsContainer>
      </Styles.CompanyContainer>
      <CompanyVenues
        companyId={company.id}
        activeRowId={activeRowId}
        rowToggle={rowToggle}
        venues={company.Venues}
        createVenue={createVenue}
        companyName={company.companyName}
        updateVenue={updateVenue}
        deleteVenue={deleteVenue}
        checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
        loggedUserAttributes={loggedUserAttributes}
      />
    </>
  );
};

export default CompanyRow;

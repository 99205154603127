import React from "react";
//Styles
import Styles from "./styles";
//Components

const CountCard = ({ title, count, blueBack }) => {
  return (
    <Styles.Card blueBack={blueBack}>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Count>{count}</Styles.Count>
    </Styles.Card>
  );
};

export default CountCard;

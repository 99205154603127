import styled from "styled-components";

const styles = {
  Spinner: styled.svg`
    animation: rotate 1s linear infinite;
    /* margin: 40px; */
    width: ${({ width }) => width || 25}px;
    height: ${({ height }) => height || 25}px;

    & .path {
      stroke: ${({ color }) => color || "#5ca6b3"};
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  `,
};

export default styles;

import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

//Styles
import Styles from "./styles";

const Settings = () => {
  const { isSuperAdmin } = useContext(AuthContext);
  const [activeLocation, setActiveLocation] = useState("");
  /* const [companiesStateData] = useOutletContext(); */
  const location = useLocation();

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  const handleActiveTab = (route) => {
    if (activeLocation.includes(route)) {
      return +true;
    }
  };

  return (
    <>
      <Styles.NavAndOutletContainer>
        <Styles.SettingsNavigationContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("company-settings")}>
            <Styles.Link to="company-settings">Company</Styles.Link>
          </Styles.LinkContainer>
          {isSuperAdmin && (
            <Styles.LinkContainer activeTab={handleActiveTab("model-manager")}>
              <Styles.Link to="model-manager">Models</Styles.Link>
            </Styles.LinkContainer>
          )}
          <Styles.LinkContainer activeTab={handleActiveTab("user-settings")}>
            <Styles.Link to="user-settings">Users</Styles.Link>
          </Styles.LinkContainer>
          <Styles.LinkContainer activeTab={handleActiveTab("profile-settings")}>
            <Styles.Link to="profile-settings">My Profile</Styles.Link>
          </Styles.LinkContainer>
        </Styles.SettingsNavigationContainer>
        <Styles.SettingsOutletContainer>
          <Outlet />
        </Styles.SettingsOutletContainer>
      </Styles.NavAndOutletContainer>
    </>
  );
};

export default Settings;

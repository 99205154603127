import React, { useEffect, useCallback, useContext } from "react";
//Styles
import Styles from "../styles";
//Static
import { modalTitles } from "../../../configs/staticText";
//Tools
import { useNavigate } from "react-router-dom";
//Icons
import { ReactComponent as BenchmarkIcon } from "../../../assets/icons/benchmark_icon_white.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play_white_icon.svg";
import { ReactComponent as StopIcon } from "../../../assets/icons/stop_white_icon.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link-solid.svg";
import { ReactComponent as OutputFlowIcon } from "../../../assets/icons/output-flow.svg";

//Components
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import LivePreview from "./LivePreview";
import StaticPreview from "./StaticPreview";
import VideoOutput from "../VideoOutput";
import RoundButton from "../../../components/Shared/Buttons/RoundButton";
import MaskingModal from "../../Modals/Masking";
import { useToastModal } from "../../../context/ToastModalContext";
//Graphql
import {
  UPDATE_MACHINE_RESOLUTION,
  PREVIEW_RTSP_NOW,
  START_INFERENCE,
  STOP_INFERENCE,
} from "../../../graphql/graph";
import { useMutation } from "@apollo/client/react";
//Context
import { AuthContext } from "../../../context/authContext";
//Helpers
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import { DashboardContext } from "../../../context/DashboardContext";
import CopyWssModal from "../../Modals/CopyWssModal";

const VideoPreview = ({ selectedCamera }) => {
  const { loggedUserAttributes } = useContext(AuthContext);
  const { maskUpdated } = useContext(DashboardContext);

  const [showStaticPreviewPrompt, setShowStaticPreviewPrompt] = React.useState(false);
  const [displayOutput, setDisplayOutput] = React.useState(false);
  const [viewType, setViewType] = React.useState("LIVE");

  const [showMaskingModal, setShowMaskingModal] = React.useState(false);
  const [staticPreviewUrl, setStaticPreviewUrl] = React.useState(null);
  const [showOutput, setShowOutput] = React.useState(false);
  const [inferenceMaskingController, setInferenceMaskingController] = React.useState(false);
  const [showCopyModal, setShowCopyModal] = React.useState(false);

  const [outputBase64ToDownload, setOutputBase64ToDownload] = React.useState(null);

  const previousViewMode = React.useRef("LIVE");

  const navigate = useNavigate();
  const { addToast, addModal } = useToastModal();

  const [updateMachineResolution] = useMutation(UPDATE_MACHINE_RESOLUTION, {
    context: { clientName: "graph" },
  });

  const [previewRTSPNow] = useMutation(PREVIEW_RTSP_NOW, {
    context: { clientName: "graph" },
  });

  const [
    startInference,
    {
      data: startInferenceData,
      /* loading: updateMachineLoading, */
      error: startInferenceError,
    },
  ] = useMutation(START_INFERENCE, {
    context: { clientName: "graph" },
  });

  const [
    stopInference,
    {
      data: stopInferenceData,
      /* loading: updateMachineLoading, */
      error: stopInferenceError,
    },
  ] = useMutation(STOP_INFERENCE, {
    context: { clientName: "graph" },
  });

  //Handle start inference success/error
  useEffect(() => {
    if (startInferenceData) {
      addToast("Inference started successfully!", "success");
    }
    if (startInferenceError) {
      addToast("Something went wrong!", "error");
    }
  }, [startInferenceData, startInferenceError, maskUpdated]);

  //Handle stop inference success/error
  useEffect(() => {
    if (stopInferenceData) {
      addToast("Inference stopped successfully!", "success");
    }
    if (stopInferenceError) {
      addToast("Something went wrong!", "error");
    }
  }, [stopInferenceData, stopInferenceError]);

  useEffect(() => {
    if (inferenceMaskingController && selectedCamera.machineStatus > 0) {
      setInferenceMaskingController(false);
      addModal(
        modalTitles.updatedMasking,
        false,
        {
          subtitle: modalTitles.restartInferenceConfirmation,
        },
        async () => {
          const stopInferenceResponse = await stopInference({
            variables: {
              id: selectedCamera.id,
            },
          });
          stopInferenceResponse &&
            startInference({
              variables: {
                id: selectedCamera.id,
              },
            });
        },
      );
    }
  }, [selectedCamera.machineStatus, inferenceMaskingController]);

  const handleViewStatic = () => {
    setViewType("STATIC");
    setShowStaticPreviewPrompt(false);
    previewRTSPNow({
      variables: {
        id: selectedCamera.id,
      },
    });
  };

  const stopFeed = () => {
    navigate(-1);
  };

  const handleNoRtsp = () => {
    setViewType(null);
    setShowStaticPreviewPrompt(true);
  };

  const closeMaskingModel = () => {
    setViewType(previousViewMode.current);
    setShowMaskingModal(false);
  };

  const toggleOutput = () => {
    if (
      checkUserPermissionIsAdmin(
        loggedUserAttributes,
        selectedCamera.companyId,
        selectedCamera.venueId,
      )
    ) {
      setShowOutput(!showOutput);
    } else {
      addToast("You must be a venue Admin to start/stop output!", "error");
    }
  };

  const handleClickStartInference = () => {
    if (
      checkUserPermissionIsAdmin(
        loggedUserAttributes,
        selectedCamera.companyId,
        selectedCamera.venueId,
      )
    ) {
      // console.log("moving forward - Start Inferences API Call");
      addModal(modalTitles.startInferenceConfirmation, false, () => {
        startInference({
          variables: {
            id: selectedCamera.id,
          },
        });
      });
    } else {
      addToast("You must be a venue Admin to start inference!", "error");
    }
  };

  const handleClickStopInference = () => {
    if (
      checkUserPermissionIsAdmin(
        loggedUserAttributes,
        selectedCamera.companyId,
        selectedCamera.venueId,
      )
    ) {
      addModal(modalTitles.stopInferenceConfirmation, false, () => {
        stopInference({
          variables: {
            id: selectedCamera.id,
          },
        });
        setShowOutput(false);
      });
    } else {
      addToast("You must be a venue Admin to stop inference!", "error");
    }
  };

  const toggleCopyWssModal = () => {
    setShowCopyModal((prev) => !prev);
  };

  const renderOutputToggleButtons = () => {
    if (!selectedCamera.machineStatus) {
      // inference is offline
      return null;
    }

    return (
      <>
        <RoundButton
          cameraInferenceBtn
          tooltipText={"Copy Output Link"}
          icon={
            <LinkIcon
              style={{
                position: "absolute",
                right: "10px",
              }}
            />
          }
          handleClick={toggleCopyWssModal}
        />
        <BlueBtn
          label={showOutput ? "Hide Output" : "View Output"}
          width={171}
          handleClick={toggleOutput}
        />
      </>
    );
  };

  const openMaskingModal = () => {
    if (
      checkUserPermissionIsAdmin(
        loggedUserAttributes,
        selectedCamera.companyId,
        selectedCamera.venueId,
      )
    ) {
      if (showStaticPreviewPrompt && !staticPreviewUrl) {
        // static view type but no preview url, can't mask without it
        return addToast("You must have working RTSP link or RTSP preview to mask!", "error");
      }
      // stop preview
      previousViewMode.current = viewType;
      setViewType(null);
      setShowMaskingModal(true);
    } else {
      addToast("You must be a venue Admin to open masking tool!", "error");
    }
  };

  const handleOutputImgDownload = useCallback(() => {
    const imageUrl = `data:image/jpg;base64,${outputBase64ToDownload}`;
    const link = document.createElement("a");
    link.download = "last_output.jpg";
    link.href = imageUrl;
    link.click();
  }, [outputBase64ToDownload]);

  const renderNoRtspLink = () => {
    if (showStaticPreviewPrompt) {
      return (
        <Styles.ErrorContainer>
          <Styles.ErrorMessage red>Error: Unable to connect to RTSP.</Styles.ErrorMessage>
          <Styles.ErrorMessage>
            Would you like to retrieve a full resolution snapshot from the camera instead?
          </Styles.ErrorMessage>
          <Styles.RetrieveThumbBtnsContainer>
            <Styles.RetrieveThumbnailBtn onClick={handleViewStatic}>
              Yes
            </Styles.RetrieveThumbnailBtn>
            <Styles.RetrieveThumbnailBtn onClick={stopFeed}>No</Styles.RetrieveThumbnailBtn>
          </Styles.RetrieveThumbBtnsContainer>
        </Styles.ErrorContainer>
      );
    }
  };

  const cameraHasRTSPLink = selectedCamera.machineRTSPUrl && selectedCamera.dockerURL;

  // set wss url
  const wssUrl = `wss://${selectedCamera.dockerURL}.lotuslabs.co:9495/devices?sender=client&camera_id=${selectedCamera.id}&token=${selectedCamera.machineWssToken}`;

  return (
    <>
      <Styles.DisplayCameraContainer hasVideo={cameraHasRTSPLink ? true : false}>
        <Styles.CloseButton onClick={stopFeed} />
        <Styles.Header>
          <Styles.CameraTitle>{selectedCamera.machineName}</Styles.CameraTitle>
          <Styles.ButtonsContainer>
            {selectedCamera.machineStatus > 0 ? (
              <>
                <RoundButton
                  cameraInferenceBtn
                  tooltipText={"Stop Inference"}
                  icon={
                    <StopIcon
                      style={{
                        position: "absolute",
                        right: "13px",
                      }}
                    />
                  }
                  handleClick={handleClickStopInference}
                />
              </>
            ) : (
              <RoundButton
                cameraInferenceBtn
                tooltipText={"Start Inference"}
                icon={
                  <PlayIcon
                    style={{
                      position: "absolute",
                      fill: "#ffffff",
                      right: "10.5px",
                    }}
                  />
                }
                handleClick={handleClickStartInference}
              />
            )}
            <RoundButton
              cameraInferenceBtn
              tooltipText={"BenchMark - Coming soon!"}
              icon={<BenchmarkIcon />}
              handleClick={() => {}}
            />

            {showOutput && (
              <>
                <RoundButton
                  cameraInferenceBtn
                  tooltipText={"Download Last Output IMG"}
                  icon={<Styles.DownLoadIcon />}
                  handleClick={handleOutputImgDownload}
                />
                <RoundButton
                  cameraInferenceBtn
                  tooltipText={"Display output text"}
                  icon={<OutputFlowIcon />}
                  handleClick={() => {
                    setDisplayOutput(!displayOutput);
                  }}
                />
              </>
            )}
            {selectedCamera.machineStatus > 0 && renderOutputToggleButtons()}
            <BlueBtn label="Configure Masking" width={171} handleClick={openMaskingModal} />
          </Styles.ButtonsContainer>
        </Styles.Header>
        <Styles.PreviewAndOutputContainer>
          <Styles.Column>
            <Styles.LabelContainer>Preview</Styles.LabelContainer>
            <Styles.PreviewWrapper>
              {renderNoRtspLink()}
              {viewType === "LIVE" && !showStaticPreviewPrompt && (
                <LivePreview
                  selectedCamera={selectedCamera}
                  showOutput={showOutput}
                  updateMachineResolution={updateMachineResolution}
                  handleNoRtsp={handleNoRtsp}
                />
              )}
              {viewType === "STATIC" && (
                <StaticPreview
                  selectedCamera={selectedCamera}
                  updateMachineResolution={updateMachineResolution}
                  updateStaticUrl={setStaticPreviewUrl}
                  width={"100%"}
                  height={"100%"}
                />
              )}
            </Styles.PreviewWrapper>
          </Styles.Column>
          {showOutput && (
            <VideoOutput
              cameraId={selectedCamera.id}
              machineVectors={selectedCamera.machineVectors}
              setOutputBase64ToDownload={setOutputBase64ToDownload}
              displayOutput={displayOutput}
              wssUrl={wssUrl}
            />
          )}
        </Styles.PreviewAndOutputContainer>
      </Styles.DisplayCameraContainer>
      <MaskingModal
        title={"Masking Tool"}
        showModal={showMaskingModal}
        setShowModal={() => closeMaskingModel()}
        selectedCamera={selectedCamera}
        staticPreviewUrl={staticPreviewUrl}
      />
      {showCopyModal && (
        <CopyWssModal showModal={showCopyModal} setShowModal={setShowCopyModal} link={wssUrl} />
      )}
    </>
  );
};

export default VideoPreview;

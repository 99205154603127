import React from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const addUserToVenueSchema = yup.object().shape({
  venue: yup.string().required("Venue name is a required field."),
  permissionType: yup.string().required("Permission name is a required field."),
});

const AddVenueToUserModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  venues,
  companyId,
  userEmail,
  userId,
  inviteUserToVenueMutation,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserToVenueSchema),
  });

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  //Handle submit Form, create Venue
  const submitForm = (addUserToVenueFormData) => {
    inviteUserToVenueMutation({
      variables: {
        venueIds: JSON.stringify([
          {
            id: addUserToVenueFormData.venue,
            permissionType: addUserToVenueFormData.permissionType,
          },
        ]),
        companyId: companyId,
        userEmail: userEmail,
        userId: userId,
        permissionType: addUserToVenueFormData.permissionType,
      },
    });
    reset();
    setShowModal((prev) => !prev);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        handleModalForwardClick={handleModalForwardClick}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Select name="venue" id="venue" {...register("venue")} defaultValue="">
              <option value="" disabled>
                Select Venue
              </option>
              {venues?.map((venue) => {
                return (
                  <option value={venue.id} key={venue.id}>
                    {venue.venueName}
                  </option>
                );
              })}
            </Styles.Select>
            <Styles.SubmitError>{errors.venue?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.InputAndErrorContainer>
            <Styles.Select
              name="permissionType"
              id="permissionType"
              {...register("permissionType")}
              defaultValue=""
            >
              <option value="" disabled>
                Select venue permission
              </option>
              <option value="ADMIN">Admin</option>
              <option value="VIEW_ONLY">View Only</option>
            </Styles.Select>
            <Styles.SubmitError>{errors.permissionType?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Cancel"
              handleClick={handleClickCancelBtn}
            />
            <Styles.SubmitInput type="submit" id="submit" value="Add" />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddVenueToUserModal;

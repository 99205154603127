import styled from "styled-components/macro";

const styles = {
  SectionContainer: styled.section`
    min-height: calc(100vh - 105px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: radial-gradient(
      circle at 0% 51%,
      rgba(55, 158, 165, 0.12) 0%,
      rgba(229, 229, 229, 1) 100%
    );
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 900px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 1.5rem;
    }
    @media screen and (max-width: 480px) {
      padding: 1rem;
    }
  `,
  HeaderContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Container: styled.div`
    font-family: "Courier New", Courier, monospace;
    text-align: left;
    background: #ffffff;
    color: #333333;
    width: 100%;
    padding: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  `,
  ResultTitle: styled.h2`
    font-size: 20px;
    color: #333333;
    margin-bottom: 1rem;
  `,
  ResultItem: styled.p`
    font-size: 16px;
    margin-bottom: 0.5rem;
    span {
      font-weight: bold;
    }
  `,
  AnswersContainer: styled.div`
    text-align: left;
    margin-top: 1rem;
  `,
  AnswersHeading: styled.h3`
    font-size: 16px;
  `,
  AnswerItem: styled.div`
    background: #f9f9f9;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;

    p {
      display: inline-block;
      font-size: 12px;
    }
    span {
      font-weight: bold;
      font-size: 14px;
    }
  `,
  BackButtonContainer: styled.div`
    margin-top: 2rem;

    button {
      font-family: "Courier New", Courier, monospace;
      background: #333333;
      color: #ffffff;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background: #555555;
      }
    }
  `,
  ErrorMessage: styled.p`
    font-size: 16px;
    color: #ff3333;
  `,
};

export default styles;

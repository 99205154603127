import React from "react";
import Styles from "../Settings/ModelManager/styles";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import { crStatus, arStatus } from "../../../configs/configEnviroment";
import { timeStampToDateString } from "../../../configs/helpers";

const Row = ({ requestItem, updateItem, cameraMap }) => {
  const startDate = timeStampToDateString(
    requestItem.crStartNow ? requestItem.createdAt : requestItem.crStartTimestamp,
  );

  const collectionCompleteDate = timeStampToDateString(requestItem.crCollectionCompleteDate);

  const arCollectionCompleteDate = timeStampToDateString(
    requestItem.AnnotationRequest?.arCollectionCompleteDate,
  );

  const startTimeText = requestItem.crStartNow
    ? `Immediately ${startDate}`
    : startDate.toLocaleString();

  const crCollectionStatus = `${
    crStatus[requestItem.crCollectionStatus]
  } ${collectionCompleteDate}`;

  // if there is annotation
  const annotationStatus = requestItem.AnnotationRequest
    ? `${arStatus[requestItem.AnnotationRequest?.arStatus]} ${arCollectionCompleteDate}`
    : "";

  return (
    <Styles.TableRowEntriesContainer>
      <Styles.TableRowEntries>{cameraMap[requestItem.machineId]}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{startTimeText}</Styles.TableRowEntries>
      <Styles.TableRowEntries>
        {requestItem.crMaxFrames} frames/{requestItem.crInterval} sec
      </Styles.TableRowEntries>
      <Styles.TableRowEntries>{crCollectionStatus}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{annotationStatus}</Styles.TableRowEntries>
      <Styles.TableRowEntries align="center" narrow="true">
        <BlueBtn width={"70px"} label="Edit" handleClick={() => updateItem(requestItem)} />
      </Styles.TableRowEntries>
    </Styles.TableRowEntriesContainer>
  );
};

export const AnnotationRequestsTable = ({ requestsList, updateItem, cameraMap }) => {
  const renderNestedRows = () => {
    return requestsList.map((requestItem) => {
      return (
        <Styles.TableRow key={requestItem.id}>
          <Row requestItem={requestItem} updateItem={updateItem} cameraMap={cameraMap} />
        </Styles.TableRow>
      );
    });
  };

  return (
    <Styles.Table>
      <Styles.TableHeader>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Camera</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Start Date</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Max Frames/Interval</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Capture</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Annotation</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries narrow="true"></Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
      </Styles.TableHeader>
      {renderNestedRows()}
    </Styles.Table>
  );
};

export default AnnotationRequestsTable;

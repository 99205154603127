import styled from "styled-components/macro";

const styles = {
  FormWrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  Label: styled.label`
    font-family: "Roboto400";
    /* font-family: ${(props) => (props.profileForm ? "Poppins700" : "Roboto400")}; */
    font-size: ${(props) => (props.profileForm ? "14px" : "18px")};
    color: #37474f;
    padding-bottom: ${(props) => (props.profileForm ? "0.2rem" : "0.5rem")};
    @media screen and (max-width: 480px) {
      font-size: 17px;
      /*  padding-bottom: ${(props) => (props.profileForm ? "0.2rem" : "0.5rem")}; */
    }
  `,
  SubmitError: styled.p`
    font-family: "Roboto400";
    font-size: 14px;
    color: #ff3333;
  `,
};

export default styles;

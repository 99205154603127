import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

//Styles
import Styles from "./styles";

import VenuesAreasCameras from "../../CompanyDashboard/VenuesAreasCameras";

import { LIST_VENUES } from "../../../../graphql/graph";

const VenueCameras = () => {
  const { venueId } = useParams();
  const [venuesList, setVenuesList] = useState([]);

  const [getVenues, { data: venuesData, loading: venuesDataLoading }] = useLazyQuery(LIST_VENUES, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    if (venueId) {
      handleVenueFetch(venueId);
    }
  }, [venueId]);

  useEffect(() => {
    setVenuesList(
      venuesData?.listVenues?.data.map((venue) => ({
        ...venue,
        isSelected: true,
        isChecked: false,
      })) || [],
    );
  }, [venuesData?.listVenues?.data]);

  const handleVenueFetch = async (venueId) => {
    // get all venue ids and fetch venue data
    const venuesData = await getVenues({
      variables: {
        venueIds: [venueId],
      },
    });

    setVenuesList(
      venuesData?.data?.listVenues?.data.map((venue) => ({
        ...venue,
        isSelected: true,
        isChecked: false,
      })) || [],
    );
  };

  return (
    <Styles.CamerasMainContainer>
      <VenuesAreasCameras venuesList={venuesList} loading={venuesDataLoading} />
    </Styles.CamerasMainContainer>
  );
};

export default VenueCameras;

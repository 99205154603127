import React, { useRef, useState } from "react";
// Tools
import { useForm } from "react-hook-form";
// Styles
import Styled from "./DataExtraction.styled";
// Components
import QuestionForm from "./components/QuestionForm/QuestionForm";
import QuestionTable from "./components/QuestionTable/QuestionTable";
import InferenceEmailModal from "../../containers/Modals/InferenceEmail";
import FileUpload from "../../components/FileUpload";
import { useToastModal } from "../../context/ToastModalContext";
// Graphql
import { useMutation } from "@apollo/client";
import { CREATE_INFERENCE } from "../../graphql/user";

const DataExtraction = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesError, setSelectedFilesError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: {
      fieldName: "",
      fieldDescription: "",
    },
  });
  const uploaderRef = useRef();
  const { addToast } = useToastModal();

  const [createInferenceMutation] = useMutation(CREATE_INFERENCE, {
    context: { clientName: "user" },
  });

  const handleAddQuestion = () => {
    const { fieldName, fieldDescription } = getValues();
    if (fieldName && fieldDescription) {
      setQuestions([...questions, { name: fieldName, description: fieldDescription }]);
      reset({ fieldName: "", fieldDescription: "" });
    }
  };

  const handleDeleteQuestion = (index) => {
    setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
  };

  const onSubmit = () => {
    if (selectedFiles.length === 0) {
      setSelectedFilesError("Please select at least one file");
      return;
    }

    if (questions.length === 0) {
      addToast("Please add at least one question", "error");
      return;
    }

    setShowModal(true);
  };

  const handleConfirm = async (userEmail) => {
    try {
      const uploadResponse = await uploaderRef.current.handlePdfFileUpload();

      if (uploadResponse && uploadResponse.status) {
        const fileAssetId = uploadResponse.fileAssetId;

        await createInferenceMutation({
          variables: {
            userEmail,
            userUrl: "",
            inferenceModel: "DATA_EXTRACT",
            fileAssetId,
            inferenceQuestions: questions,
          },
        });

        addToast("File uploaded, please check your email!", "success");
        setShowModal(false);
      } else {
        addToast("File upload failed", "error");
      }
    } catch (error) {
      console.error("Error during file upload or inference creation:", error);
      addToast("Error during file upload or inference creation.", "error");
    }
  };

  return (
    <Styled.FormWrapper>
      <Styled.FormHeading>Data Extraction</Styled.FormHeading>

      <FileUpload
        title="Please select and upload a pdf file. Then ask some questions."
        ref={uploaderRef}
        fileTypes={["PDF"]}
        multiple={false}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        selectedFilesError={selectedFilesError}
        setSelectedFilesError={setSelectedFilesError}
      />

      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <QuestionForm control={control} handleAddQuestion={handleAddQuestion} />
        <QuestionTable questions={questions} handleDeleteQuestion={handleDeleteQuestion} />
        <Styled.SubmitInput type="submit" />
      </Styled.Form>

      {showModal && (
        <InferenceEmailModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleConfirm={handleConfirm}
        />
      )}
    </Styled.FormWrapper>
  );
};

export default DataExtraction;

import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSubscription } from "@apollo/client/react";

//Styles
import Styles from "./styles";

import { ReactComponent as StopOrangeIcon } from "../../../assets/icons/stop_orange_icon.svg";
import { ReactComponent as PlayOrangeIcon } from "../../../assets/icons/play_orange_icon.svg";
import { ReactComponent as GarbageOrangeIcon } from "../../../assets/icons/garbage_orange_icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add_orange_icon.svg";
import { ReactComponent as ExpandOrangeIcon } from "../../../assets/icons/expand_orange_arrows.svg";
import { ReactComponent as MinimizeOrangeIcon } from "../../../assets/icons/minimize_orange_arrows.svg";
import { ReactComponent as EditGrayIcon } from "../../../assets/icons/edit_icon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh_icon.svg";
import SmallCameraCard from "../../../components/SmallCameraCard";
import Checkbox from "../../../components/Checkbox";
import RoundButton from "../../../components/Shared/Buttons/RoundButton";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";

import { AuthContext } from "../../../context/authContext";
import { useToastModal } from "../../../context/ToastModalContext";
import { GraphQLHelperContext } from "../../../context/GraphQLHelperContext";
import UpdateVenueModal from "../../Modals/AddUpdateVenue/UpdateVenue";
import UpdateAreaModal from "../../Modals/UpdateArea";
import AddUpdateCameraModal from "../../../containers/Modals/AddUpdateCamera/AddUpdateCamera";

import { checkUserPermissionIsAdmin, getCheckedCameras } from "../../../configs/helpers";

import { MACHINE_STATUS_UPDATED_SUBSCRIPTION, GET_VENUE } from "../../../graphql/graph";

const VenuesAreasCameras = ({ venuesList, visibleVenueIds, loading }) => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { loggedUserAttributes } = useContext(AuthContext);
  const { startAllInference, stopAllInference, deleteMachines, deleteAreas, previewRTSP } =
    useContext(GraphQLHelperContext);
  const { addToast, addModal } = useToastModal();

  // Modals State
  const [showUpdateAreaModal, setShowUpdateAreaModal] = useState(false);
  const [showUpdateVenueModal, setShowUpdateVenueModal] = useState(false);
  const [showAddUpdateCameraModal, setShowAddUpdateCameraModal] = useState(false);
  const [areaNameObj, setAreaNameObj] = useState({});
  const [venueObj, setVenueObj] = useState({});
  const [createUpdateObj, setCreateUpdateObj] = useState({});
  const [venueListWithChecks, setVenueListWithChecks] = useState([]);

  const { data: machineStatusUpdated } = useSubscription(MACHINE_STATUS_UPDATED_SUBSCRIPTION, {
    variables: { companyId },
  });

  useEffect(() => {
    // if visibleVenueIds is undefined then show all
    if (!visibleVenueIds) {
      setVenueListWithChecks(venuesList);
      return;
    }

    const newVenueList = [];
    visibleVenueIds.forEach((venue) => {
      // loop through visibleVenueIds and set isSelected
      if (venue.isSelected) {
        const venueFound = venuesList.find((v) => v.id === venue.id);
        newVenueList.push(venueFound);
      }
    });
    setVenueListWithChecks(newVenueList);
  }, [venuesList, visibleVenueIds]);

  useEffect(() => {
    if (machineStatusUpdated && machineStatusUpdated.machineStatusUpdated) {
      // console.log("machineStatusUpdated", machineStatusUpdated.machineStatusUpdated);
      const { venueId, areaId, machineId, machineLastPingTime } =
        machineStatusUpdated.machineStatusUpdated;
      handleMachineTimeStampUpdate({ venueId, areaId, machineId, machineLastPingTime });
    }
  }, [machineStatusUpdated]);

  const handleMachineTimeStampUpdate = ({ venueId, areaId, machineId, machineLastPingTime }) => {
    const newVenueList = venueListWithChecks.map((venue) => {
      if (venue.id === venueId) {
        return {
          ...venue,
          Areas: venue.Areas.map((area) => {
            if (area.id === areaId) {
              return {
                ...area,
                Machines: area.Machines.map((machine) => {
                  if (machine.id === machineId) {
                    return {
                      ...machine,
                      machineLastPingTime,
                    };
                  }
                  return machine;
                }),
              };
            }
            return area;
          }),
        };
      }

      return venue;
    });

    setVenueListWithChecks(newVenueList);
  };

  const handleCheckBoxChange = (venueId, areaId, machineId, event) => {
    const { checked: isChecked } = event.target;
    console.log(
      `venueId: ${venueId}, areaId: ${areaId}, machineId: ${machineId}, isChecked: ${isChecked}`,
    );
    const newVenueList = venueListWithChecks.map((venue) => {
      // check all area and machine in venue
      if (venueId && !areaId && venue.id === venueId) {
        const { Areas } = venue;
        return {
          ...venue,
          Areas: Areas.map((area) => {
            const { Machines } = area;
            return {
              ...area,
              Machines: Machines.map((machine) => {
                return {
                  ...machine,
                  isChecked,
                };
              }),
              isChecked,
            };
          }),
          isChecked,
        };
      }

      // check area and all machine in area
      if (venueId && areaId && !machineId && venue.id === venueId) {
        const { Areas } = venue;

        return {
          ...venue,
          isChecked: false,
          Areas: Areas.map((area) => {
            if (area.id === areaId) {
              const { Machines } = area;
              return {
                ...area,
                Machines: Machines.map((machine) => {
                  return {
                    ...machine,
                    isChecked,
                  };
                }),
                isChecked,
              };
            }
            return area;
          }),
        };
      }

      // check only 1 machine
      if (venueId && areaId && machineId && venue.id === venueId) {
        const { Areas } = venue;

        return {
          ...venue,
          isChecked: false,
          Areas: Areas.map((area) => {
            if (area.id === areaId) {
              const { Machines } = area;
              return {
                ...area,
                isChecked: false,
                Machines: Machines.map((machine) => {
                  if (machine.id === machineId) {
                    return {
                      ...machine,
                      isChecked,
                    };
                  }
                  return machine;
                }),
              };
            }
            return area;
          }),
        };
      }

      return venue;
    });

    setVenueListWithChecks(newVenueList);
  };

  const handleClickUpdateArea = (area) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, area.venueId)) {
      setAreaNameObj({ id: area.id, areaName: area.areaName });
      setShowUpdateAreaModal((prev) => !prev);
    } else {
      addToast("You must be an Admin to edit area!", "error");
    }
  };

  const handleClickUpdateVenue = (venue) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venue.id)) {
      setVenueObj({ venueName: venue.venueName, venueId: venue.id });
      setShowUpdateVenueModal((prev) => !prev);
    } else {
      addToast("You must be an Admin to update Venue!", "error");
    }
  };

  const handleClickAddUpdateCamera = (venue, machine) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venue?.id || machine.venueId)) {
      // sets payload for create.update
      if (machine) {
        // find the venue.Areas that has the machine
        const venueFound = venueListWithChecks.find((venue) => {
          return venue.id === machine.venueId;
        });
        setCreateUpdateObj({ ...machine, venueAreas: venueFound.Areas });
      } else if (venue && !machine) {
        setCreateUpdateObj({ venueId: venue.id, venueAreas: venue.Areas });
      }

      setShowAddUpdateCameraModal((prev) => !prev);
    } else {
      addToast("You must be an Admin to update!", "error");
    }
  };

  const handleClickStartCamera = (venue) => {
    const { id: venueId, Areas } = venue;
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      // Check if any camera is checked, set message, and show modal
      const { checkedCameras, areasWithCheckedCameras } = getCheckedCameras(Areas);

      if (checkedCameras.length > 0) {
        let title = "";
        if (checkedCameras.length === 1) {
          title = `Start Inference ${checkedCameras[0].machineName} camera ?`;
        } else {
          title = `Start Inference ${checkedCameras.length} cameras ?`;
        }
        const machineIds = checkedCameras.map((machine) => machine.id);

        addModal(title, false, () => {
          startAllInference({
            variables: {
              venueId,
              machineIds: JSON.stringify(machineIds),
            },
          });
        });
      } else {
        addToast("Select a camera to start!", "error");
      }
    } else {
      addToast("You must be a Venue Admin to start camera!", "error");
    }
  };

  const handleClickStopCamera = (venue) => {
    const { id: venueId, Areas } = venue;
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      // Check if any camera is checked, set message, and show modal
      const { checkedCameras, areasWithCheckedCameras } = getCheckedCameras(Areas);

      if (checkedCameras.length > 0) {
        let title = "";
        if (checkedCameras.length === 1) {
          title = `Stop Inference ${checkedCameras[0].machineName} camera ?`;
        } else {
          title = `Stop Inference ${checkedCameras.length} cameras ?`;
        }
        const machineIds = checkedCameras.map((machine) => machine.id);

        addModal(title, false, () => {
          stopAllInference({
            variables: {
              venueId,
              machineIds: JSON.stringify(machineIds),
            },
          });
        });
      } else {
        addToast("Select a camera to stop!", "error");
      }
    } else {
      addToast("You must be a Venue Admin to stop camera!", "error");
    }
  };

  const handleAreaDeletePrompt = (areasWithCheckedCameras, venueId) => {
    // prompt for empty area delete if there are any areas with 0 count
    if (areasWithCheckedCameras.length > 0) {
      const areaIds = areasWithCheckedCameras.map((area) => area.id);
      addModal("You have areas without cameras, would you like to delete them?", false, () => {
        deleteAreas({
          variables: {
            areaIds: JSON.stringify(areaIds),
          },
          refetchQueries: [{ query: GET_VENUE, variables: { id: venueId } }],
        });
      });
    }
  };

  const handleClickDeleteCamera = (venue) => {
    const { id: venueId, Areas } = venue;
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      // Check if any camera is checked, set message, and show modal
      const { checkedCameras, areasWithCheckedCameras } = getCheckedCameras(Areas);

      // if only area is checked, only prompt for area delete
      if (checkedCameras.length === 0 && areasWithCheckedCameras.length > 0) {
        handleAreaDeletePrompt(areasWithCheckedCameras, venueId);
        return;
      }

      if (checkedCameras.length > 0) {
        let title = "";
        if (checkedCameras.length === 1) {
          title = `Delete ${checkedCameras[0].machineName} camera ?`;
        } else {
          title = `Delete ${checkedCameras.length} cameras ?`;
        }

        const machineIds = checkedCameras.map((machine) => machine.id);

        addModal(title, false, () => {
          deleteMachines({
            variables: {
              venueId,
              machineIds: JSON.stringify(machineIds),
            },
            refetchQueries: [{ query: GET_VENUE, variables: { id: venueId } }],
          });
        });
      } else {
        addToast("Select a camera to delete!", "error");
      }
    } else {
      addToast("You must be a Venue Admin to delete camera!", "error");
    }
  };

  const handleClickPreviewRTSP = (venueId) => {
    previewRTSP({
      variables: {
        venueId,
      },
    });
  };

  const closeModals = () => {
    setShowUpdateAreaModal(false);
    setShowUpdateVenueModal(false);
    setShowAddUpdateCameraModal(false);

    // clear state
    setAreaNameObj({});
    setVenueObj({});
    setCreateUpdateObj({});
  };

  const openCamera = (camera) => {
    // if manage-companies then navigate to manage-companies/companyId/venueId/cameraId
    if (window.location.pathname.includes("manage-companies")) {
      navigate(`${camera.venueId}/${camera.id}`);
    } else {
      navigate(`${camera.id}`);
    }
  };

  const renderCameraRows = (machines, areaId, areaName) => {
    return machines.map((machine) => {
      return (
        <SmallCameraCard
          key={machine.id}
          camera={{ ...machine, areaName }}
          onClick={openCamera}
          areaId={areaId}
          handleCameraCheckboxSelect={handleCheckBoxChange}
          handleClickUpdateCamera={() => handleClickAddUpdateCamera(null, machine)}
        />
      );
    });
  };

  const renderAreas = (areas) => {
    // if none lets render none msg
    if (areas.length === 0) {
      return (
        <Styles.AreaContainer>
          <Styles.RowHeader>
            <Styles.AreaName>There are no Cameras found! Please add one!</Styles.AreaName>
          </Styles.RowHeader>
        </Styles.AreaContainer>
      );
    }

    return areas.map((area) => {
      return (
        <Styles.AreaContainer key={area.id}>
          <Styles.RowHeader>
            <Styles.CheckboxNameContainer>
              <Checkbox
                id={area.id}
                onChange={(e) => handleCheckBoxChange(area.venueId, area.id, null, e)}
                checked={!!area.isChecked}
              />
              <Styles.AreaName>{area.areaName}</Styles.AreaName>
              <Styles.RowIconsContainer>
                <EditGrayIcon
                  onClick={() => {
                    handleClickUpdateArea(area);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Styles.RowIconsContainer>
            </Styles.CheckboxNameContainer>
          </Styles.RowHeader>
          <Styles.CamerasRows>
            {renderCameraRows(area.Machines, area.id, area.areaName)}
          </Styles.CamerasRows>
        </Styles.AreaContainer>
      );
    });
  };

  const renderVenues = () => {
    if (loading) {
      return (
        <Styles.SpinnerContainer>
          <SpinnerComponent width={"5rem"} height={"5rem"} />
        </Styles.SpinnerContainer>
      );
    }

    // if none lets render none msg
    if (venueListWithChecks.length === 0) {
      return (
        <Styles.VenueContainer>
          <Styles.RowHeader>
            <Styles.VenueName>There are no Venues found! Please add one!</Styles.VenueName>
          </Styles.RowHeader>
        </Styles.VenueContainer>
      );
    }

    return venueListWithChecks.map((venue) => {
      if (!venue || venue.isSelected === false) {
        return null;
      }
      return (
        <Styles.VenueContainer key={venue.id}>
          <Styles.RowHeader>
            <Styles.CheckboxNameContainer>
              <Checkbox
                id={venue.id}
                onChange={(e) => handleCheckBoxChange(venue.id, null, null, e)}
                checked={!!venue.isChecked}
              />
              <Styles.VenueName>{venue.venueName}</Styles.VenueName>
              <Styles.RowIconsContainer>
                <EditGrayIcon
                  onClick={() => {
                    handleClickUpdateVenue(venue);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Styles.RowIconsContainer>
            </Styles.CheckboxNameContainer>
            <Styles.ActionButtonsWrapper>
              <Styles.SmallButtonsContainer>
                <RoundButton
                  icon={<RefreshIcon />}
                  handleClick={() => handleClickPreviewRTSP(venue.id)}
                  tooltipText={"Refresh RTSP preview"}
                />
                <RoundButton
                  icon={<GarbageOrangeIcon />}
                  handleClick={() => handleClickDeleteCamera(venue)}
                  tooltipText={"Delete"}
                />
                <RoundButton
                  icon={<StopOrangeIcon />}
                  handleClick={() => handleClickStopCamera(venue)}
                  tooltipText={"Stop Inference"}
                />
                <RoundButton
                  icon={<PlayOrangeIcon />}
                  handleClick={() => handleClickStartCamera(venue)}
                  tooltipText={"Start Inference"}
                />
                <DefaultDashBtn
                  label={"Cameras"}
                  whitebg
                  mediumsize
                  orangecolor
                  icon={<AddIcon />}
                  handleClick={() => handleClickAddUpdateCamera(venue)}
                />
              </Styles.SmallButtonsContainer>
            </Styles.ActionButtonsWrapper>
          </Styles.RowHeader>
          {renderAreas(venue.Areas)}
          <Styles.Line />
        </Styles.VenueContainer>
      );
    });
  };

  return (
    <Styles.OuterContainer>
      {renderVenues()}
      <UpdateAreaModal
        title={"Update Area Name"}
        showModal={showUpdateAreaModal}
        setShowModal={setShowUpdateAreaModal}
        areaNameObj={areaNameObj}
      />
      <UpdateVenueModal
        showModal={showUpdateVenueModal}
        setShowModal={setShowUpdateVenueModal}
        venueObj={venueObj}
        title={"Update Venue Name"}
      />
      {showAddUpdateCameraModal && (
        <AddUpdateCameraModal
          showModal={showAddUpdateCameraModal}
          setShowModal={closeModals}
          cameraObj={createUpdateObj}
        />
      )}
    </Styles.OuterContainer>
  );
};

export default VenuesAreasCameras;

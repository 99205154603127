import React, { useContext } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useNavigate } from "react-router-dom";

//Context
import { AuthContext } from "../../context/authContext";

const MobileNav = ({ toggle, isOpen }) => {
  /*  const [dropdown, setDropdown] = useState(false); */
  const { isAuthenticated, handleLogOut } = useContext(AuthContext);
  const navigate = useNavigate();

  //TODO Handle dropdown mobile

  const handleClickLogOut = () => {
    handleLogOut();
    navigate("/");
    toggle();
  };

  return (
    <Styles.MobileNavContainer isOpen={isOpen}>
      <Styles.Icon onClick={toggle}>
        <Styles.CloseIcon />
      </Styles.Icon>

      <Styles.MobileNavMenu>
        {!isAuthenticated ? (
          <Styles.MobileNavLink to="signin" onClick={toggle}>
            Sign In
          </Styles.MobileNavLink>
        ) : (
          <>
            <Styles.MobileNavLink to="manage-companies" onClick={toggle}>
              Dashboard
            </Styles.MobileNavLink>
            <Styles.MobileNavLink to="/" onClick={handleClickLogOut}>
              Logout
            </Styles.MobileNavLink>
          </>
        )}
      </Styles.MobileNavMenu>
    </Styles.MobileNavContainer>
  );
};

export default MobileNav;

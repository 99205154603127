import React, { useContext } from "react";
import ModelRow from "./ModelRow";
import { DashboardContext } from "../../../../context/DashboardContext";

//Styles
import Styles from "./styles";

const ModelTable = ({ modelVersions, model, updateModel, addVersion }) => {
  const renderNestedRows = () => {
    return modelVersions.map((modelVersion) => {
      return (
        <Styles.TableRow key={modelVersion.id}>
          <ModelRow
            modelVersion={modelVersion}
            model={model}
            updateModel={updateModel}
            addVersion={addVersion}
          />
        </Styles.TableRow>
      );
    });
  };
  return (
    <Styles.Table>
      <Styles.TableHeader>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Version</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Note</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Actions</Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
      </Styles.TableHeader>
      {renderNestedRows()}
    </Styles.Table>
  );
};

const ModelTables = ({ dataRows, updateModel, addVersion }) => {
  const { companiesDict } = useContext(DashboardContext);
  const renderTables = (row) => {
    const { ModelVersions, ...model } = row;

    const mainTitle = `${model.modelName} ${model.modelStatus ? "(enabled)" : ""}`;
    let subTitle = `${model.modelBaseModel ? "Base " : ""}Model: ${model.modelType}`;

    if (model.companyId) {
      // add company name to subtitle
      subTitle = `${subTitle} | Company: ${companiesDict[model.companyId]}`;
    }

    return (
      <Styles.TableTitle key={model.id}>
        {mainTitle}
        <Styles.TableSubTitle>{subTitle}</Styles.TableSubTitle>
        <ModelTable
          modelVersions={ModelVersions}
          model={model}
          updateModel={updateModel}
          addVersion={addVersion}
        />
      </Styles.TableTitle>
    );
  };
  return <div>{dataRows.map((row) => renderTables(row))}</div>;
};

export default ModelTables;

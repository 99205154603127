import React from "react";
// Styles
import Styled from "./QuestionTable.styled";

const QuestionTable = ({ questions, handleDeleteQuestion }) => (
  <Styled.Table>
    <thead>
      <Styled.TableRow>
        <Styled.TableHeader>Name</Styled.TableHeader>
        <Styled.TableHeader>Description</Styled.TableHeader>
        <Styled.TableHeader>Actions</Styled.TableHeader>
      </Styled.TableRow>
    </thead>

    <tbody>
      {questions.length === 0 ? (
        <Styled.TableRow>
          <Styled.TableCell colSpan="3">No questions added.</Styled.TableCell>
        </Styled.TableRow>
      ) : (
        questions.map((question, index) => (
          <Styled.TableRow key={index}>
            <Styled.TableCell>{question.name}</Styled.TableCell>
            <Styled.TableCell>{question.description}</Styled.TableCell>
            <Styled.TableCell>
              <Styled.DeleteQuestionBtn type="button" onClick={() => handleDeleteQuestion(index)}>
                Delete
              </Styled.DeleteQuestionBtn>
            </Styled.TableCell>
          </Styled.TableRow>
        ))
      )}
    </tbody>
  </Styled.Table>
);

export default QuestionTable;

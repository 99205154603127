import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Styles from "../styles";

import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import ObjectSelector from "../../../components/ObjectSelector";

import {
  CREATE_COLLECTION_REQUEST,
  DELETE_COLLECTION_REQUEST,
  LIST_COLLECTION_REQUESTS,
  UPDATE_COLLECTION_REQUEST,
} from "../../../graphql/graph";
import { DashboardContext } from "../../../context/DashboardContext";
import { useToastModal } from "../../../context/ToastModalContext";
import RadioButton from "../../../components/RadioButton";

const inputFormSchema = yup.object().shape({
  machineId: yup.string().required("Camera is a required field."),
});

const StartCaptureRequest = ({
  venuesList,
  companyId,
  showModal,
  setShowModal,
  requestToUpdate,
  onCloseModal,
}) => {
  const { addToast, addModal } = useToastModal();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inputFormSchema),
    defaultValues: {
      machineId: null,
      crStartNow: 1,
      crStartTimestamp: null,
      crEndTimestamp: null,
      crMaxFrames: 500,
      crInterval: 1,
      crManualReview: 1,
      crDetectionItems: [],
    },
  });

  const [
    addCollectionMutation,
    { error: addCollectionError, loading: addLoading, data: addCollectionData },
  ] = useMutation(CREATE_COLLECTION_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COLLECTION_REQUESTS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const [
    updateCollectionMutation,
    { error: updateCollectionError, loading: updateLoading, data: updateCollectionData },
  ] = useMutation(UPDATE_COLLECTION_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COLLECTION_REQUESTS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const [deleteCollectionMutation, { data: deleteCollectionData, error: deleteCollectionError }] =
    useMutation(DELETE_COLLECTION_REQUEST, {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_COLLECTION_REQUESTS,
          context: { clientName: "graph" },
          variables: {
            companyId,
          },
        },
      ],
    });

  const loading = addLoading || updateLoading;

  const watchAll = watch();

  useEffect(() => {
    if (deleteCollectionData) {
      addToast("Collection deleted successfully!", "success");
      handleCloseModal();
    }
    if (deleteCollectionError) {
      addToast(
        `Something went wrong! Collection did not delete. ${deleteCollectionError.message}`,
        "error",
      );
    }
    if (addCollectionData) {
      addToast("Collection created successfully!", "success");
      handleCloseModal();
    }
    if (addCollectionError) {
      addToast(
        `Something went wrong! Collection did not create. ${addCollectionError.message}`,
        "error",
      );
    }
  }, [deleteCollectionData, deleteCollectionError, addCollectionData, addCollectionError]);

  useEffect(() => {
    if (updateCollectionData) {
      addToast("Collection updated successfully!", "success");
    }
    if (updateCollectionError) {
      console.log(updateCollectionError);
      addToast(
        `Something went wrong! Collection did not update. ${updateCollectionError.message}`,
        "error",
      );
    }
  }, [updateCollectionData, updateCollectionError]);

  useEffect(() => {
    reset(requestToUpdate);
    if (requestToUpdate) {
      console.log(requestToUpdate);
      if (!requestToUpdate.crStartNow) {
        setValue(
          "crStartTimestamp",
          new Date(requestToUpdate.crStartTimestamp * 1000).toISOString().slice(0, 16),
        );
      }
      if (requestToUpdate.crEndTimestamp !== 0) {
        setValue(
          "crEndTimestamp",
          new Date(requestToUpdate.crEndTimestamp * 1000).toISOString().slice(0, 16),
        );
      }
    }
  }, [reset, requestToUpdate]);

  const handleObjectChange = (objects) => {
    setValue("crDetectionItems", objects || []);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setValue(name, parseInt(value, 10));
  };

  const handleCloseModal = () => {
    reset();
    setShowModal((prev) => !prev);
    onCloseModal();
  };

  const handleClickDeleteBtn = async () => {
    addModal("Delete Collection Request?", false, () => {
      deleteCollectionMutation({ variables: { id: requestToUpdate.id } });
    });
  };

  const submitForm = async (form) => {
    if (loading) return;
    if (requestToUpdate) {
      updateCollectionMutation({
        variables: {
          id: requestToUpdate.id,
          companyId,
          machineId: form.machineId,
          crStartNow: parseInt(form.crStartNow, 10),
          crStartTimestamp: form.crStartTimestamp
            ? new Date(form.crStartTimestamp).getTime() / 1000
            : 0,
          crEndTimestamp: form.crEndTimestamp ? new Date(form.crEndTimestamp).getTime() / 1000 : 0,
          crMaxFrames: parseInt(form.crMaxFrames, 10),
          crManualReview: parseInt(form.crManualReview, 10),
          crInterval: parseInt(form.crInterval, 10),
          crDetectionItems: JSON.stringify([]),
        },
      });
    } else {
      addCollectionMutation({
        variables: {
          companyId,
          machineId: form.machineId,
          crStartNow: parseInt(form.crStartNow, 10),
          crStartTimestamp: form.crStartTimestamp
            ? new Date(form.crStartTimestamp).getTime() / 1000
            : 0,
          crEndTimestamp: form.crEndTimestamp ? new Date(form.crEndTimestamp).getTime() / 1000 : 0,
          crMaxFrames: parseInt(form.crMaxFrames, 10),
          crManualReview: parseInt(form.crManualReview, 10),
          crInterval: parseInt(form.crInterval, 10),
          crDetectionItems: JSON.stringify([]),
        },
      });
      handleCloseModal();
    }
  };

  usePreventEnterAction(submitForm);

  const { objectDetectionItems } = useContext(DashboardContext);

  const objectSuggestions = objectDetectionItems.map((item) => ({
    value: item.objectDetectionIndex,
    label: item.objectDetectionLabel,
  }));

  const modalTitle = requestToUpdate ? "Update capture" : "Start capture";

  return (
    <ModalWithChildren
      width={600}
      showModal={showModal}
      setShowModal={handleCloseModal}
      title={modalTitle}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Camera</Styles.Label>
          <Styles.Select {...register("machineId")}>
            <option value="">Select Camera</option>
            {venuesList.map((venue) => (
              <optgroup label={venue.venueName} key={venue.id}>
                {venue.Areas.map((area) =>
                  area.Machines.map((machine) => (
                    <option key={machine.id} value={machine.id}>
                      {area.areaName} - {machine.machineName}
                    </option>
                  )),
                )}
              </optgroup>
            ))}
          </Styles.Select>
          <Styles.SubmitError>{errors.machineId?.message}</Styles.SubmitError>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>Object Detection List</Styles.Label>
          <Styles.ComboSelect>
            <ObjectSelector
              values={watchAll.detectionObjects}
              onChange={handleObjectChange}
              suggestions={objectSuggestions}
            />
          </Styles.ComboSelect>
        </Styles.InputAndErrorContainer>

        <Styles.CheckboxAndIconContainer>
          <Styles.Label>Start data collection</Styles.Label>
          <Styles.Label>
            <RadioButton
              name="crStartNow"
              value="1"
              onChange={handleRadioChange}
              checked={watchAll.crStartNow}
            />
            Immediately
          </Styles.Label>
          <Styles.Label>
            <RadioButton
              name="crStartNow"
              value="0"
              onChange={handleRadioChange}
              checked={!watchAll.crStartNow}
            />
            <Styles.Input
              disabled={watchAll.crStartNow}
              type="datetime-local"
              narrow={true}
              {...register("crStartTimestamp")}
            />
          </Styles.Label>
        </Styles.CheckboxAndIconContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>Max frame to capture</Styles.Label>
          <Styles.Input type="text" narrow={true} {...register("crMaxFrames")} />
        </Styles.InputAndErrorContainer>
        <Styles.InputAndErrorContainer>
          <Styles.Label>End data capture on?</Styles.Label>
          <Styles.Label>
            <Styles.Input type="datetime-local" narrow={true} {...register("crEndTimestamp")} />
          </Styles.Label>
        </Styles.InputAndErrorContainer>

        <Styles.InputAndErrorContainer>
          <Styles.Label>How many seconds per frame</Styles.Label>
          <Styles.Input type="text" {...register("crInterval")} />
        </Styles.InputAndErrorContainer>

        <Styles.CheckboxAndIconContainer>
          <Styles.Label></Styles.Label>
          <Styles.Label>
            <RadioButton
              // fix name
              name="crManualReview"
              value="1"
              onChange={handleRadioChange}
              checked={watchAll.crManualReview}
            />
            Review captured data before sending to annotation
          </Styles.Label>
          <Styles.Label>
            <RadioButton
              name="crManualReview"
              value="0"
              onChange={handleRadioChange}
              checked={!watchAll.crManualReview}
            />
            Send data to annotation without review
          </Styles.Label>
        </Styles.CheckboxAndIconContainer>

        {loading ? (
          <>
            <Styles.SpinnerContainer>
              <SpinnerComponent width={"5rem"} height={"5rem"} />
            </Styles.SpinnerContainer>
          </>
        ) : (
          <Styles.ButtonsContainer>
            <DefaultDashBtn
              whitebg
              orangeborder
              orangecolor
              smallsize
              label="Close"
              handleClick={handleCloseModal}
            />
            <Styles.SubmitInput type="submit" id="submit" value={modalTitle} />
          </Styles.ButtonsContainer>
        )}
      </Styles.Form>
      {requestToUpdate && (
        <Styles.Button onClick={handleClickDeleteBtn}>[Cancel capture request]</Styles.Button>
      )}
    </ModalWithChildren>
  );
};

export default StartCaptureRequest;

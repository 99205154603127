import styled from "styled-components/macro";

const styles = {
  ManageVenueLicencesContainer: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
  LicencesTable: styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
  `,
  TableHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid #c4c4c4;
  `,
  TableHeaderEntriesContainer: styled.div`
    width: 33.33%;
    display: flex;
    justify-content: center;
  `,
  TableHeaderEntries: styled.span`
    font-family: "Poppins700";
    font-size: 14px;
    color: #263238;
  `,
  TableRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
  `,
  TableRowEntriesContainer: styled.div`
    width: 33.33%;
    display: flex;
    justify-content: center;
  `,
  TableRowEntries: styled.span`
    font-family: "PoppinsRegular";
    font-size: 16px;
    color: #455a64;
  `,
};

export default styles;

import styled from "styled-components/macro";

const styles = {
  ProfileContainer: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
    }
  `,
  FormAndTitleContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    width: 75%;
    background: #ebebeb;
    padding: 1.5rem;
    border-radius: 10px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `,
  Input: styled.input`
    height: 2rem;
    border: 1px solid #c4c4c4;
    background: transparent;
    outline: none;
    border-radius: 5px;
    padding: 0.5rem;
  `,
  SubmitInput: styled.input`
    background: #5ca6b3;
    border: none;
    border-radius: 100px;
    width: 175px;
    height: 40px;
    text-decoration: none;
    color: #ffffff;
    font-family: Poppins600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  `,
  Title: styled.h3`
    font-family: Poppins600;
    font-size: 18px;
    color: #455a64;
    margin: 0;
    padding-bottom: 2rem;
  `,
  Paragraph: styled.p`
    font-size: 1rem;
    margin: 0;
    padding: 0;
  `,
  SubmitError: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
    color: #ff3333;
  `,
  SubmitSuccess: styled.div`
    display: flex;
    justify-content: center;
    font-family: PoppinsRegular;
    font-size: 14px;
    margin-bottom: 1rem;
    color: green;
  `,
  IconWrapper: styled.div`
    position: absolute;
    right: 10px;
    top: 24px;
    cursor: pointer;
    @media screen and (max-width: 480px) {
      right: 10px;
      top: 27px;
    }
  `,
};

export default styles;

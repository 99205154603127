import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";

//Styles
import Styles from "./styles";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/chevron_up.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add_orange_icon.svg";
import { ReactComponent as DockerCmdIcon } from "../../../assets/icons/list-solid.svg";

import DockerCard from "../../../components/Shared/DockerCard";
import AddUpdateDockerModal from "../../Modals/AddUpdateDocker";
import DockerCmdLog from "../../Modals/DockerCmdLog";
import { checkUserPermissionIsAdmin } from "../../../configs/helpers";
import { AuthContext } from "../../../context/authContext";
import { useToastModal } from "../../../context/ToastModalContext";
import {
  LIST_DOCKERS,
  DOCKER_STATUS_UPDATED,
  DOCKER_CMD_LOG_UPDATE_SUBSCRIPTION,
} from "../../../graphql/graph";

const DockerControllers = ({ venuesList }) => {
  const { companyId } = useParams();
  const { addToast } = useToastModal();
  const { loggedUserAttributes } = useContext(AuthContext);
  const [showDockers, setShowDockers] = useState(false);
  const [showDockerCmdLog, setShowDockerCmdLog] = useState(false);
  const [dockerList, setDockerList] = useState([]);
  const [dockerToUpdate, setDockerToUpdate] = useState(null);
  const [showAddDockerModal, setShowAddDockerModal] = useState(false);
  const [dockerCmdLogData, setDockerCmdLogData] = useState([]);

  const { data: dockerCmdLogUpdated } = useSubscription(DOCKER_CMD_LOG_UPDATE_SUBSCRIPTION, {
    variables: { companyId },
  });

  const { data: dockerStatusUpdate } = useSubscription(DOCKER_STATUS_UPDATED, {
    variables: { companyId },
  });

  const { data: dockersData } = useQuery(LIST_DOCKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const handleAddOrUpdateLog = (updatedCmdLog) => {
    // find index of the dockerCmdLogData with the same dockerId
    const index = dockerCmdLogData.findIndex(
      (dockerCmdLog) => dockerCmdLog.id === updatedCmdLog.id,
    );

    // if index is -1, it means that the dockerCmdLogData is not in the array
    if (index === -1) {
      setDockerCmdLogData((prev) => [...prev, updatedCmdLog]);
    } else {
      const newDockerCmdLogData = [...dockerCmdLogData];
      newDockerCmdLogData[index] = updatedCmdLog;
      setDockerCmdLogData(newDockerCmdLogData);
    }
  };

  useEffect(() => {
    if (dockerCmdLogUpdated && dockerCmdLogUpdated.dockerCmdLogUpdated) {
      console.log("dockerCmdLogUpdated", dockerCmdLogUpdated.dockerCmdLogUpdated);
      handleAddOrUpdateLog(dockerCmdLogUpdated.dockerCmdLogUpdated);
    }
  }, [dockerCmdLogUpdated]);

  useEffect(() => {
    if (dockersData && dockersData.listDocker) {
      setDockerList(dockersData.listDocker.data);
    }
  }, [dockersData]);

  useEffect(() => {
    if (dockerStatusUpdate && dockerStatusUpdate.dockerStatusUpdated) {
      handleDockerTimeStampUpdate(dockerStatusUpdate.dockerStatusUpdated);
    }
  }, [dockerStatusUpdate]);

  const handleDockerTimeStampUpdate = ({ dockerId, dockerLastPingTime }) => {
    setDockerList(
      dockerList.map((docker) => {
        if (docker.id === dockerId) {
          return {
            ...docker,
            dockerLastPingTime,
          };
        }

        return {
          ...docker,
        };
      }),
    );
  };

  const handleClickAddUpdateDocker = (docker) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setDockerToUpdate(docker);
      setShowAddDockerModal((prev) => !prev);
    } else {
      addToast("You must be a Venue Admin to add or update Docker!", "error");
    }
  };

  const handleToggleDockerContainer = () => {
    setShowDockers(!showDockers);
  };

  const renderDockers = () => {
    if (!showDockers) {
      return null;
    }
    if (!dockerList.length) {
      return <DockerCard title="No Docker Controllers" />;
    }
    return dockerList.map((docker) => {
      return (
        <DockerCard
          key={docker.id}
          title={docker.dockerName}
          dockerLastPingTime={docker.dockerLastPingTime}
          updateDocker={() => handleClickAddUpdateDocker(docker)}
        />
      );
    });
  };

  const toggleDockerCmdLog = () => {
    setShowDockerCmdLog((prev) => !prev);
  };

  const renderAddDocker = () => {
    if (!showDockers) {
      return null;
    }
    return (
      <>
        <DockerCard title="Docker CMD Log" updateDocker={toggleDockerCmdLog} icon={DockerCmdIcon} />
        <DockerCard
          title="Add Controller"
          updateDocker={() => handleClickAddUpdateDocker(null)}
          icon={AddIcon}
        />
      </>
    );
  };

  console.log("dockerCmdLogData", dockerCmdLogData);

  return (
    <Styles.CompanyDashboardContainer>
      <Styles.Header>
        <Styles.SubTitle>Padme Controllers</Styles.SubTitle>
        <Styles.IconButton onClick={handleToggleDockerContainer}>
          {showDockers ? <ChevronDown /> : <ChevronUp />}
        </Styles.IconButton>
      </Styles.Header>
      <Styles.DockerContainer>
        {renderDockers()}
        {renderAddDocker()}
      </Styles.DockerContainer>
      {showAddDockerModal && (
        <AddUpdateDockerModal
          showModal={showAddDockerModal}
          setShowModal={setShowAddDockerModal}
          setDockerToUpdate={setDockerToUpdate}
          dockerToUpdate={dockerToUpdate}
          venues={venuesList}
          companyId={companyId}
        />
      )}
      {showDockerCmdLog && (
        <DockerCmdLog
          showModal={showDockerCmdLog}
          setShowModal={setShowDockerCmdLog}
          dockerCmdLogData={dockerCmdLogData}
        />
      )}
    </Styles.CompanyDashboardContainer>
  );
};

export default DockerControllers;

import React from "react";
//Styles
import Styles from "./styles";
//Components
import ProfileImage from "../../../../components/ProfileImage";
import { useToastModal } from "../../../../context/ToastModalContext";

const UserRow = ({
  user,
  removeUserVenuePermissions,
  venueId,
  setUserCurrentVenuePermission,
  setShowVenuePermissionTypeModal,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
  companyId,
}) => {
  const { addToast, addModal } = useToastModal();

  const handleClickRemoveUserFromVenue = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      addModal("Are you sure you want to remove this user from this venue?", false, () => {
        removeUserVenuePermissions({
          variables: {
            userId: user.userId,
            venueId: venueId,
          },
        });
      });
    } else {
      addToast("You must be a venue Admin to remove this user!", "error");
    }
  };

  const handleClickEditUserVenuePermissions = (id, permissionType) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      setUserCurrentVenuePermission({
        id,
        permissionType,
      });
      setShowVenuePermissionTypeModal((prev) => !prev);
    } else {
      addToast("You must be a venue Admin to edit this user permissions!", "error");
    }
  };

  return (
    <>
      <Styles.UserRowContainer>
        <Styles.UserInfoWrapper>
          <ProfileImage size={40} />
          <Styles.DetailsContainer>
            <Styles.NameAndRole>
              <Styles.Name>{user.userFirstName}</Styles.Name>
              <Styles.Name>{user.userLastName}</Styles.Name>
              <Styles.Role>{user.permissionType}</Styles.Role>
            </Styles.NameAndRole>
            <Styles.Email>{user.userEmail}</Styles.Email>
          </Styles.DetailsContainer>
        </Styles.UserInfoWrapper>
        <Styles.ActionIconsContainer>
          <Styles.EditIcon
            onClick={() => handleClickEditUserVenuePermissions(user.id, user.permissionType)}
          />
          <Styles.DeleteIcon onClick={handleClickRemoveUserFromVenue} />
        </Styles.ActionIconsContainer>
      </Styles.UserRowContainer>
    </>
  );
};

export default UserRow;

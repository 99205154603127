import styled from "styled-components/macro";

const styles = {
  InputsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  `,
  InputsInnerContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `,
  AddQuestionBtn: styled.button`
    font-size: 14px;
    background-color: #5ca6b3;
    color: #ffffff;
    width: 210px;
    height: 40px;
    align-self: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
  `,
};

export default styles;

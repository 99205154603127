import styled from "styled-components/macro";

const styles = {
  Card: styled.div`
    background: #ffffff;
    box-shadow: 0px 4.68571px 11.7143px rgb(0 0 0 / 10%);
    border-radius: 11.7143px;
    /* width: 349.09px; */
    /* height: 453.34px; */
    height: 300px;
    gap: 3rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  IconContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  InfoContainer: styled.div`
    height: 35%;
    width: 100%;
    border: 0.585714px solid #bbbbbb;
    filter: drop-shadow(0px 5.85714px 11.7143px rgba(0, 0, 0, 0.25));
    border-radius: 11.7143px;
  `,
  Title: styled.h2`
    font-family: "Poppins700";
    font-size: 17.5714px;
    line-height: 26px;
    color: #464646;
  `,
  Count: styled.span`
    font-family: "Poppins700";
    font-size: 35.1429px;
    line-height: 53px;
    color: #263238;
  `,

  //CardInfoTable
  InfoTable: styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
  `,
  InfoRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  StatusAndTitle: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  StatusCircle: styled.span`
    height: 12px;
    width: 12px;
    background-color: ${(props) => (props.active ? "#28BC4E" : "#C4C4C4;")};
    border-radius: 50%;
    display: inline-block;
  `,
  RowTitle: styled.span`
    font-family: "Poppins500";
    font-size: 11.7143px;
    line-height: 18px;
    color: #6b6f7b;
  `,
  RowCount: styled.span`
    font-family: "Poppins700";
    font-size: 17.5714px;
    line-height: 26px;
    color: #263238;
  `,
};

export default styles;

import styled from "styled-components/macro";

const styles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ wide }) => (wide ? "100%" : undefined)};
  `,
  Table: styled.table`
    background: #ffffff;
    padding: 1rem;
    table-layout: fixed;
    width: 800px;
    height: 40vh;
    overflow-y: scroll;
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0.75rem;
  `,
  TableHeaderEntries: styled.th`
    width: 100%;
    text-align: left;
    font-family: "Poppins700";
    font-size: 13px;
    color: #263238;
  `,
  TableRow: styled.tbody`
    width: 100%;
  `,
  TableRowEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    &:nth-child(even) {
      background-color: #efefef;
    }
  `,
  TableRowEntries: styled.td`
    width: 100%;
    font-family: "PoppinsRegular";
    font-size: 12px;
    color: #455a64;
    text-align: left;
    cursor: pointer;
  `,
};

export default styles;

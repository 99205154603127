import React, { useEffect } from "react";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Icons
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
//Styles
import Styles from "./styles";
//Components
import { FormGroup } from "../../Shared/FormGroup";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";

const updatePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  password: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .max(15)
    .required("Password is required"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});

const UpdatePassword = (props) => {
  const {
    updatePassword,
    updatePasswordData,
    updatePasswordSuccess,
    setUpdatePasswordSuccess,
    updatePasswordError,
    setUpdatePasswordError,
    showOldPassword,
    setShowOldPassword,
    showNewPassword,
    setShowNewPassword,
    showConfirmPassword,
    setShowConfirmPassword,
  } = props;

  //React-Hook-Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  //Effects
  useEffect(() => {
    if (updatePasswordData) {
      reset();
    }
  }, [updatePasswordData, reset]);

  useEffect(() => {
    if (
      errors.oldPassword?.message ||
      errors.password?.message ||
      errors.confirmPassword?.message
    ) {
      setUpdatePasswordSuccess(false);
      setUpdatePasswordError("");
    }
  }, [errors, setUpdatePasswordSuccess, setUpdatePasswordError]);

  //Handlers
  const handleUpdatePassword = (updatePasswordFormData) => {
    updatePassword({
      variables: {
        oldPassword: updatePasswordFormData.oldPassword.trim(),
        newPassword: updatePasswordFormData.password.trim(),
      },
    });
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  usePreventEnterAction(handleUpdatePassword);

  return (
    <Styles.FormAndTitleContainer>
      <Styles.Title>Update Your Password</Styles.Title>
      <Styles.Form onSubmit={handleSubmit(handleUpdatePassword)}>
        <FormGroup
          labelText={"Type your old password"}
          errorMessage={errors.oldPassword?.message}
          profileForm
        >
          <Styles.Input
            type={showOldPassword ? "text" : "password"}
            name="oldPassword"
            {...register("oldPassword")}
          />
          <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
            <Styles.IconWrapper onClick={handleShowOldPassword}>
              {showOldPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
            </Styles.IconWrapper>
          </IconContext.Provider>
        </FormGroup>
        <FormGroup
          labelText={"Type your new password"}
          errorMessage={errors.password?.message}
          profileForm
        >
          <Styles.Input
            type={showNewPassword ? "text" : "password"}
            name="password"
            {...register("password")}
          />
          <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
            <Styles.IconWrapper onClick={handleShowNewPassword}>
              {showNewPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
            </Styles.IconWrapper>
          </IconContext.Provider>
        </FormGroup>
        <FormGroup
          labelText={"Confirm your new password"}
          errorMessage={errors?.confirmPassword && "Passwords must match!"}
          profileForm
        >
          <Styles.Input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            {...register("confirmPassword")}
          />
          <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
            <Styles.IconWrapper onClick={handleShowConfirmPassword}>
              {showConfirmPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
            </Styles.IconWrapper>
          </IconContext.Provider>
        </FormGroup>
        {updatePasswordSuccess ? (
          <Styles.SubmitSuccess>Password updated successfully!</Styles.SubmitSuccess>
        ) : updatePasswordError ? (
          <Styles.SubmitError>{updatePasswordError}</Styles.SubmitError>
        ) : null}
        <Styles.SubmitInput type="submit" id="submitPassword" value="Update Password" />
      </Styles.Form>
    </Styles.FormAndTitleContainer>
  );
};

export default UpdatePassword;

import { createGlobalStyle } from "styled-components";
import styled, { css } from "styled-components/macro";

//Styles
import fonts from "../styles/fonts.css";
import reactTagAutocomplete from "../styles/react-tag-autocomplete.css";

export const GlobalStyles = createGlobalStyle`

* {
    box-sizing: border-box;   
}

html {
  scroll-behavior: smooth;
}
    
:root {
    --toastify-color-success: #5ca6b3;
}

body {
    font-size: 10px;
    margin: 0;
    padding: 0;
}
  
${fonts}
${reactTagAutocomplete}
`;

export const Content = styled.div`
  min-height: calc(100vh - 10rem);
`;

export const containerScrollbar = css`
  /* Track */
  ::-webkit-scrollbar {
    background: #e0e0e0;
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e6866e;
    border-radius: 5px;
    height: 8px;
    width: 8px;
  }
`;

export const containerGrayScrollbar = css`
  /* Track */
  ::-webkit-scrollbar {
    background: #e0e0e0;
    width: 4px;
    height: 4px;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
    height: 4px;
    width: 4px;
  }
`;

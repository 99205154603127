import React, { useState, useContext } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useParams, useOutletContext } from "react-router-dom";
//Components
import UserRow from "./UserRow";
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";
import SpinnerComponent from "../../../../components/Loader";
//Modals
import InviteUserToVenueModal from "../../../Modals/InviteUserToVenue";
import UpdateVenuePermissionModal from "../../../Modals/UpdateVenuePermission";
//Helpers
import { checkUserPermissionIsAdmin } from "../../../../configs/helpers";
//Context
import { AuthContext } from "../../../../context/authContext";
import { useToastModal } from "../../../../context/ToastModalContext";

const VenueUsers = () => {
  const { loggedUserAttributes } = useContext(AuthContext);
  const [showAddUserToVenueModal, setShowAddUserToVenueModal] = useState(false);
  const [userCurrentVenuePermission, setUserCurrentVenuePermission] = useState({});
  const [showVenuePermissionTypeModal, setShowVenuePermissionTypeModal] = useState(false);
  const { venueId, companyId } = useParams();
  //Trailing commas because order matters with OutletContext
  const [
    ,
    ,
    ,
    usersByVenueList,
    usersByCompanyList,
    usersByVenueDataLoading,
    inviteUserToVenueMutation,
    ,
    removeUserVenuePermissions,
    updateUserVenuePermissions,
  ] = useOutletContext();

  const { addToast } = useToastModal();

  const handleClickInviteUserToVenue = () => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId, venueId)) {
      setShowAddUserToVenueModal((prev) => !prev);
    } else {
      addToast("You must be a venue Admin to invite other users!", "error");
    }
  };

  const getUsersNotInVenue = () => {
    const usersNotInVenue = usersByCompanyList.filter(
      (user) => !usersByVenueList.some((userInVenue) => user.userId === userInVenue.userId),
    );
    return usersNotInVenue;
  };

  return (
    <Styles.ManageVenueUsersContainer>
      <Styles.Header>
        <Styles.Title>Manage Users</Styles.Title>
        <BlueBtn label={"Invite User"} handleClick={handleClickInviteUserToVenue} />
      </Styles.Header>
      {usersByVenueDataLoading ? (
        <Styles.LoaderContainer>
          <SpinnerComponent width={75} height={75} />
        </Styles.LoaderContainer>
      ) : usersByVenueList.length > 0 ? (
        usersByVenueList.map((user) => (
          <UserRow
            user={user}
            key={user.id}
            removeUserVenuePermissions={removeUserVenuePermissions}
            setUserCurrentVenuePermission={setUserCurrentVenuePermission}
            setShowVenuePermissionTypeModal={setShowVenuePermissionTypeModal}
            venueId={venueId}
            checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
            loggedUserAttributes={loggedUserAttributes}
            companyId={companyId}
          />
        ))
      ) : (
        <Styles.LoaderContainer>
          <Styles.NoUsersText>No Users on this venue!</Styles.NoUsersText>
        </Styles.LoaderContainer>
      )}
      <InviteUserToVenueModal
        showModal={showAddUserToVenueModal}
        setShowModal={setShowAddUserToVenueModal}
        users={getUsersNotInVenue()}
        venueId={venueId}
        companyId={companyId}
        title={"Invite a user to this Venue!"}
        inviteUserToVenueMutation={inviteUserToVenueMutation}
      />
      <UpdateVenuePermissionModal
        showModal={showVenuePermissionTypeModal}
        setShowModal={setShowVenuePermissionTypeModal}
        userCurrentVenuePermission={userCurrentVenuePermission}
        updateUserVenuePermissions={updateUserVenuePermissions}
        title={"Update venue permissions!"}
      />
    </Styles.ManageVenueUsersContainer>
  );
};

export default VenueUsers;

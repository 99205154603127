import React, { useState, useContext, useEffect } from "react";
//Styles
import Styles from "./styles";
//GraphQl
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_PASSWORD, UPDATE_PROFILE, ME } from "../../../graphql/graph";
//Context
import { AuthContext } from "../../../context/authContext";
//Components
import UpdateProfile from "./UpdateName";
import UpdatePassword from "./UpdatePassword";

const UpdateProfileAndPass = ({ loggedUserAttributes }) => {
  const [updateProfileError, setUpdateProfileError] = useState("");
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
  const [updatePasswordError, setUpdatePasswordError] = useState("");
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { loggedUserId, setLoggedUserAttributes } = useContext(AuthContext);

  //GraphQl
  const [
    updateProfile,
    {
      data: updateProfileData,
      loading: updateProfileLoading,
      /* error: updateNameGraphqlError, */ //TODO Handle graphql error
    },
  ] = useMutation(UPDATE_PROFILE, {
    context: { clientName: "graph" },
  });

  const [
    updatePassword,
    {
      data: updatePasswordData,
      loading: updatePasswordLoading,
      /* error: updatePasswordGraphqlError, */ //TODO Handle graphql error
    },
  ] = useMutation(UPDATE_PASSWORD, {
    context: { clientName: "graph" },
  });

  //Effects
  useEffect(() => {
    if (updateProfileData) {
      if (updateProfileData.updateUser.errors?.length > 0) {
        setUpdateProfileError(updateProfileData.updateUser.errors[0].detail);
      } else {
        setUpdateProfileSuccess(true);
        setLoggedUserAttributes({
          ...loggedUserAttributes,
          userFirstName: updateProfileData.updateUser.data.userFirstName,
          userLastName: updateProfileData.updateUser.data.userLastName,
          userPhone: updateProfileData.updateUser.data.userPhone,
        });
        localStorage.setItem(
          "user_attributes",
          JSON.stringify({
            ...loggedUserAttributes,
            userFirstName: updateProfileData.updateUser.data.userFirstName,
            userLastName: updateProfileData.updateUser.data.userLastName,
            userPhone: updateProfileData.updateUser.data.userPhone,
          }),
        );
      }
    }
  }, [updateProfileData, setLoggedUserAttributes]);

  useEffect(() => {
    if (updatePasswordData) {
      if (updatePasswordData.updatePassword.errors?.length > 0) {
        setUpdatePasswordError(updatePasswordData.updatePassword.errors[0].detail);
      } else {
        setUpdatePasswordSuccess(true);
      }
    }
  }, [updatePasswordData]);

  return (
    <Styles.ProfileContainer>
      <UpdateProfile
        updateProfile={updateProfile}
        loggedUserId={loggedUserId}
        updateProfileData={updateProfileData}
        updateProfileLoading={updateProfileLoading}
        updateProfileError={updateProfileError}
        updateProfileSuccess={updateProfileSuccess}
        setUpdateProfileSuccess={setUpdateProfileSuccess}
        setUpdateProfileError={setUpdateProfileError}
        loggedUserAttributes={loggedUserAttributes}
      />
      <UpdatePassword
        updatePassword={updatePassword}
        loggedUserId={loggedUserId}
        updatePasswordData={updatePasswordData}
        updatePasswordLoading={updatePasswordLoading}
        updatePasswordError={updatePasswordError}
        updatePasswordSuccess={updatePasswordSuccess}
        setUpdatePasswordSuccess={setUpdatePasswordSuccess}
        setUpdatePasswordError={setUpdatePasswordError}
        showOldPassword={showOldPassword}
        setShowOldPassword={setShowOldPassword}
        showNewPassword={showNewPassword}
        setShowNewPassword={setShowNewPassword}
        showConfirmPassword={showConfirmPassword}
        setShowConfirmPassword={setShowConfirmPassword}
      />
    </Styles.ProfileContainer>
  );
};

export default UpdateProfileAndPass;

import React, { useState, useEffect } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useOutletContext } from "react-router-dom";
//Icons
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera_icon.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/users_icon.svg";
import { ReactComponent as LicensesIcon } from "../../../assets/icons/licenses_icon.svg";
import { Helmet } from "react-helmet-async";

//Components
import Card from "../../../components/Shared/Card";
import CountCard from "../../../components/Shared/CountCard";
import { useQuery } from "@apollo/client";
import { LIST_COMPANY_STATS } from "../../../graphql/graph";

const ManageCompanies = () => {
  /* const [camerasCardRowInfo, setCamerasCardRowInfo] = useState([]); */
  const [companiesStateData] = useOutletContext();
  const [companiesCount, setCompaniesCount] = useState("");
  const [camerasCount, setCamerasCount] = useState("");
  const [allCompaniesData, setAllCompaniesData] = useState(null);
  const {
    loading: allCompanyStatsLoading,
    error: allCompanyStatsError,
    data: allCompanyStatsData,
  } = useQuery(LIST_COMPANY_STATS, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    if (!allCompanyStatsLoading) {
      if (!allCompanyStatsError) {
        setAllCompaniesData(allCompanyStatsData.listAllCompanyStats.data);
      } else {
        console.error(allCompanyStatsError);
      }
    }
  }, [allCompanyStatsData]);

  useEffect(() => {
    setCompaniesCount(companiesStateData.length);
    const totalNumberOfCameras = [];
    companiesStateData.map((company) => {
      return totalNumberOfCameras.push(company.machineCount);
    });
    setCamerasCount(
      totalNumberOfCameras.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0),
    );
  }, [companiesStateData]);

  return (
    <>
      <Helmet>
        <title>Edge AI platform with pre-trained machine learning models - Padmé</title>
        <meta
          name="description"
          content="Deploy cutting-edge machine learning models effortlessly on your edge devices. Configure a venue and its edge devices to run any machine learning model in a matter of a few clicks."
        />
        <link rel="canonical" href="/manage-companies" />
      </Helmet>
      <Styles.ManageCompaniesContainer>
        <Styles.CardsContainer>
          <CountCard title={"No of companies"} count={companiesCount} blueBack />
          <CountCard title={"No of venues"} count={allCompaniesData?.venueCount} blueBack />
          <CountCard
            title={"No of Active Inferences"}
            count={allCompaniesData?.inferenceCount}
            blueBack
          />
          <CountCard
            title={"Total No of Cameras"}
            count={camerasCount}
            icon={<CameraIcon />}
            enableNavigation={false}
            /* cardRowInfo={} */
          />
          <CountCard
            title={"Total No of Users"}
            count={allCompaniesData?.userCount}
            icon={<UsersIcon />}
            enableNavigation={false}
          />

          <CountCard
            title={"Total No of Licenses"}
            count={0} //update once we get license data
            icon={<LicensesIcon />}
            enableNavigation={false}
          />
        </Styles.CardsContainer>
      </Styles.ManageCompaniesContainer>
    </>
  );
};

export default ManageCompanies;

import React, { useState, useEffect, useContext } from "react";
//Tools
import { Outlet, useLocation, useParams } from "react-router-dom";
//Styles
import Styles from "./styles";
//Icons
/* import { ReactComponent as AddIcon } from "../../assets/icons/add_orange_icon.svg"; */
import { ReactComponent as ManageCompaniesWhiteIcon } from "../../assets/icons/manage_companies_white.svg";
import { ReactComponent as ManageCompaniesGrayIcon } from "../../assets/icons/manage_companies_gray.svg";
import { ReactComponent as SettingsWheelWhiteIcon } from "../../assets/icons/settings_wheel_white.svg";
import { ReactComponent as SettingsWheelGrayIcon } from "../../assets/icons/settings_wheel_gray.svg";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

//Components
import SearchBar from "../../components/SearchBar";
import Drawer from "../../components/Drawer";
/* import RoundButton from "../../components/Shared/Buttons/RoundButton"; */
import AddVenueModal from "../../containers/Modals/AddUpdateVenue/AddVenue";
import { useToastModal } from "../../context/ToastModalContext";
//GraphQl
import { useQuery, useMutation } from "@apollo/client";
import {
  LIST_COMPANY,
  CREATE_VENUE,
  LIST_OBJECT_DETECTION_ITEMS,
  LIST_CONFIG,
} from "../../graphql/graph";

import { DashboardContext } from "../../context/DashboardContext";

const Padme = () => {
  const {
    setObjectDetectionItems,
    companiesList,
    setCompaniesList,
    setPadmeModelColors,
    setPadmeModelTypes,
    setCompaniesDict,
  } = useContext(DashboardContext);

  const [activeLocation, setActiveLocation] = useState("");
  // const [companiesStateData, setCompaniesStateData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const location = useLocation();
  const { companyId } = useParams();
  const [showSideMenu, setShowSideMenu] = useState(true);

  const {
    data: companiesData,
    loading: companiesLoading,
    error: companiesError,
  } = useQuery(LIST_COMPANY, {
    context: { clientName: "graph" },
  });

  const { data: objectDetectionData } = useQuery(LIST_OBJECT_DETECTION_ITEMS, {
    context: { clientName: "graph" },
  });

  const { data: configData } = useQuery(LIST_CONFIG, {
    context: { clientName: "graph" },
  });

  const [
    createVenue,
    //TODO: Handle GraphQl loading (if needed)
    {
      data: createVenueData,
      /* loading: createVenueLoading, */
      error: createVenuelError,
    },
  ] = useMutation(CREATE_VENUE, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COMPANY,
        context: { clientName: "graph" },
      },
    ],
  });

  useEffect(() => {
    // Event handler to toggle based on window width
    const handleResize = () => {
      setShowSideMenu(window.innerWidth > 953); // Adjust the window width condition as needed
    };

    // Initial call to set the initial toggle state
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup: Remove the event listener when component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setActiveLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (configData && configData.listConfig) {
      setPadmeModelColors(
        configData.listConfig.data
          .filter((config) => config.configType === "MASKING_COLORS")
          .map((config) => ({
            label: config.configName,
            stroke: config.configValue,
            fill: config.configValue2,
          })),
      );

      setPadmeModelTypes(
        configData.listConfig.data
          .filter((config) => config.configType === "MASKING_TYPES")
          .map((config) => ({
            modelId: config.id,
            label: config.configName,
            value: config.configValue,
          })),
      );
    }
  }, [configData, setPadmeModelColors, setPadmeModelTypes]);

  useEffect(() => {
    if (companiesData) {
      setCompaniesList(companiesData.listCompany.data);
      // setCompaniesStateData(companiesData.listCompany.data);

      // write company dict as well
      const companyDict = [];

      companiesData.listCompany.data.forEach((row) => {
        companyDict[row.id] = row.companyName;
      });
      setCompaniesDict(companyDict);
    }
  }, [companiesData, setCompaniesDict, setCompaniesList]);

  useEffect(() => {
    if (objectDetectionData) {
      setObjectDetectionItems(objectDetectionData.listObjectDetectionItems.data);
    }
  }, [objectDetectionData, setObjectDetectionItems]);

  const { addToast } = useToastModal();

  //Toast Create Venue
  useEffect(() => {
    if (createVenueData) {
      addToast("Successfully added!", "success");
    }
    if (createVenuelError) {
      addToast("Something went wrong!", "error");
    }
  }, [createVenueData, createVenuelError]);

  //TODO: Handle GraphQl errors
  if (companiesError) return `Error! ${companiesError}`;

  const handleActiveTab = (route) => {
    if (activeLocation.includes(route)) {
      return +true;
    }
  };

  const handleClickAddVenue = (companyName) => {
    setSelectedCompanyName(companyName);
    setShowAddVenueModal((prev) => !prev);
  };

  const handleAddVenueModalClose = (modalState) => {
    setShowAddVenueModal(modalState);
    setSelectedCompanyName("");
  };

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };

  return (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.DashboardContainer>
          <Styles.SideMenuContainer showSideMenu={showSideMenu}>
            <Styles.ToggleMenuContainer showSideMenu={showSideMenu}>
              {showSideMenu ? (
                <AiOutlineMenuFold onClick={toggleSideMenu} size={25} />
              ) : (
                <AiOutlineMenuUnfold onClick={toggleSideMenu} size={25} />
              )}
            </Styles.ToggleMenuContainer>
            <Styles.SideMenu showSideMenu={showSideMenu}>
              <Styles.LinkContainer activetab={handleActiveTab("manage-companies")}>
                <Styles.Link to="manage-companies" activetab={handleActiveTab("manage-companies")}>
                  <Styles.IconAndLink>
                    {handleActiveTab("manage-companies") ? (
                      <ManageCompaniesWhiteIcon />
                    ) : (
                      <ManageCompaniesGrayIcon />
                    )}
                    <span>Companies</span>
                  </Styles.IconAndLink>
                </Styles.Link>
              </Styles.LinkContainer>
              <Styles.SearchAndAddContainer>
                <SearchBar handleOnChange={(event) => setSearchTerm(event.target.value)} />
                {/*  <RoundButton icon={<AddIcon />} boxshadow size={34} /> */}
              </Styles.SearchAndAddContainer>
              <Drawer
                handleClickAddVenue={handleClickAddVenue}
                companiesLoading={companiesLoading}
                companiesList={companiesList}
                searchTerm={searchTerm}
              />
              <Styles.LinkContainer activetab={handleActiveTab("settings")}>
                <Styles.Link to="settings/company-settings" activetab={handleActiveTab("settings")}>
                  <Styles.IconAndLink>
                    {handleActiveTab("settings") ? (
                      <SettingsWheelWhiteIcon />
                    ) : (
                      <SettingsWheelGrayIcon />
                    )}
                    <span>Settings</span>
                  </Styles.IconAndLink>
                </Styles.Link>
              </Styles.LinkContainer>
            </Styles.SideMenu>
          </Styles.SideMenuContainer>
          <Styles.MainOutletContainer>
            <Outlet context={[companiesList]} />
          </Styles.MainOutletContainer>
        </Styles.DashboardContainer>
        <AddVenueModal
          showModal={showAddVenueModal}
          setShowModal={handleAddVenueModalClose}
          createVenue={createVenue}
          title={`Add Venue to ${selectedCompanyName}`}
          companyId={companyId}
        />
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default Padme;

import React from "react";
//Styles
import Styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
//Components
/* import CardInfoTable from "./CardInfoTable"; */

const Card = ({ title, count, icon, cardRowInfo, enableNavigation = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (enableNavigation) {
      const currentPath = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
      navigate(currentPath + "/venue-" + title.toLowerCase());
    }
  };

  return (
    <Styles.Card
      style={{
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Styles.Header>
        <Styles.Title>{title}</Styles.Title>
        <Styles.Count>{count}</Styles.Count>
      </Styles.Header>
      <Styles.IconContainer>{icon}</Styles.IconContainer>
      {/* <Styles.InfoContainer>
        <CardInfoTable cardRowInfo={cardRowInfo} />
      </Styles.InfoContainer> */}
    </Styles.Card>
  );
};

export default Card;

import React from "react";
//Styles
import Styles from "./styles";
//Components
import { onlineThreshold } from "../../../configs/configEnviroment";

const DockerCard = ({ title, dockerLastPingTime, updateDocker, icon: Icon }) => {
  const renderStatusIcon = (lastPingTime) => {
    if (Icon) {
      return <Icon />;
    }

    let dockerStatus = false;

    if (lastPingTime) {
      const lastPing = parseInt(lastPingTime);
      const currentTime = Math.floor(Date.now() / 1000);
      const diffInMinutes = currentTime - lastPing;
      if (diffInMinutes < onlineThreshold) {
        dockerStatus = true;
      }
    }

    return (
      <Styles.StatusContainer>
        <Styles.StatusCircle active={dockerStatus} />
      </Styles.StatusContainer>
    );
  };

  return (
    <Styles.DockerCard
      style={{
        cursor: "pointer",
      }}
      onClick={updateDocker}
    >
      <Styles.Title>{title}</Styles.Title>
      <Styles.IconContainer>{renderStatusIcon(dockerLastPingTime)}</Styles.IconContainer>
    </Styles.DockerCard>
  );
};

export default DockerCard;

import React, { useEffect, useState } from "react";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import Styles from "./styles";
import { timeAgo } from "../../../configs/helpers";

const StatusEnum = ["Sent", "Received", "Complete"];
StatusEnum[-1] = "Failed";

const DockerCmdLog = ({ showModal, setShowModal, dockerCmdLogData }) => {
  const renderRows = () => {
    return dockerCmdLogData.map((row) => renderRow(row));
  };

  const renderRow = (row) => {
    return (
      <Styles.TableRowEntriesContainer key={row.id}>
        <Styles.TableRowEntries>{row.machineId}</Styles.TableRowEntries>
        <Styles.TableRowEntries>
          {row.dockerCmd}, {StatusEnum[row.dockerCmdStatus]}
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>{timeAgo(row.updatedAt)}</Styles.TableRowEntries>
        <Styles.TableRowEntries>{row.dockerCmdResponse}</Styles.TableRowEntries>
        <Styles.TableRowEntries></Styles.TableRowEntries>
      </Styles.TableRowEntriesContainer>
    );
  };

  return (
    <ModalWithChildren
      slim
      showModal={showModal}
      setShowModal={setShowModal}
      title="Docker CMD Log"
    >
      <Styles.Container wide>
        <Styles.Table>
          <Styles.TableHeader>
            <Styles.TableHeaderEntriesContainer>
              <Styles.TableHeaderEntries>Machine</Styles.TableHeaderEntries>
              <Styles.TableHeaderEntries>Cmd, Status</Styles.TableHeaderEntries>
              <Styles.TableHeaderEntries>Time</Styles.TableHeaderEntries>
              <Styles.TableHeaderEntries>Resp</Styles.TableHeaderEntries>
              <Styles.TableHeaderEntries>Notes</Styles.TableHeaderEntries>
            </Styles.TableHeaderEntriesContainer>
          </Styles.TableHeader>
          <Styles.TableRow>{renderRows()}</Styles.TableRow>
        </Styles.Table>
      </Styles.Container>
    </ModalWithChildren>
  );
};

export default DockerCmdLog;

import styled, { css } from "styled-components";

export const Button = styled.button`
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  text-decoration: none;
  background: ${({ cameraInferenceBtn }) => (cameraInferenceBtn ? "#e6866e" : "#ffffff")};
  color: #e6866e;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: ${({ boxshadow }) => (boxshadow ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none")};
  ${({ size }) => {
    if (size) {
      return css`
        height: ${size}px;
        width: ${size}px;
      `;
    }
    return css`
      height: 40px;
      width: 40px;
    `;
  }}
  .customeTooltipTheme {
    color: ${({ cameraInferenceBtn }) =>
      cameraInferenceBtn ? "#ffffff !important" : "#e6866e !important"};
    font-family: Poppins600 !important;
    font-size: 12px !important;
    background-color: ${({ cameraInferenceBtn }) =>
      cameraInferenceBtn ? "#e6866e !important" : "#ffffff !important"};
    pointer-events: auto !important;
    & :hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
`;

import React from "react";
import Styles from "../Settings/ModelManager/styles";
import { timeAgo } from "../../../configs/helpers";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import { mtStatus } from "../../../configs/configEnviroment";
import { timeStampToDateString } from "../../../configs/helpers";

const Row = ({ requestItem, updateItem }) => {
  // console.log("requestItem", requestItem);
  const startDate = timeStampToDateString(requestItem.mtStartedDate);

  const endDate = timeStampToDateString(requestItem.mtCompleteDate);

  const timeAgoText = requestItem.mtStartedDate ? timeAgo(requestItem.mtStartedDate) : null;
  return (
    <Styles.TableRowEntriesContainer>
      <Styles.TableRowEntries>{requestItem.mtName}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{startDate}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{endDate}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{timeAgoText}</Styles.TableRowEntries>
      <Styles.TableRowEntries>{mtStatus[requestItem.mtStatus]}</Styles.TableRowEntries>
      <Styles.TableRowEntries align="center" narrow="true">
        <BlueBtn width={"70px"} label="Edit" handleClick={() => updateItem(requestItem)} />
      </Styles.TableRowEntries>
    </Styles.TableRowEntriesContainer>
  );
};

export const TrainingRequestsTable = ({ requestsList, updateItem }) => {
  const renderNestedRows = () => {
    return requestsList.map((requestItem) => {
      return (
        <Styles.TableRow key={requestItem.id}>
          <Row requestItem={requestItem} updateItem={updateItem} />
        </Styles.TableRow>
      );
    });
  };

  return (
    <Styles.Table>
      <Styles.TableHeader>
        <Styles.TableHeaderEntriesContainer>
          <Styles.TableHeaderEntries>Name</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Start</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>End</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Elapsed</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries>Status</Styles.TableHeaderEntries>
          <Styles.TableHeaderEntries narrow="true"></Styles.TableHeaderEntries>
        </Styles.TableHeaderEntriesContainer>
      </Styles.TableHeader>
      {renderNestedRows()}
    </Styles.Table>
  );
};

export default TrainingRequestsTable;

import styled from "styled-components/macro";
//Icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/garbage_gray_icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow_down_gray.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow_up_gray.svg";

const styles = {
  //Icons
  DeleteIcon: styled(DeleteIcon)`
    cursor: pointer;
  `,
  EditIcon: styled(EditIcon)`
    cursor: pointer;
  `,
  ArrowDown: styled(ArrowDown)`
    cursor: pointer;
  `,
  ArrowUp: styled(ArrowUp)`
    cursor: pointer;
  `,
  //Styles
  ManageUsersContainer: styled.div`
    display: flex;
    flex-direction: column;
    background: white;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #c4c4c4;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
  UserRowContainer: styled.div`
    background: #ebebeb;
    border-bottom: ${(props) => (props.rowToggle ? "none" : "1px solid #c4c4c4")};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  `,
  UserInfoWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @media screen and (max-width: 480px) {
      align-items: center;
    }
  `,
  NameAndRole: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  Name: styled.span`
    font-family: "Poppins600";
    font-size: 14px;
    color: #c4c4c4;
  `,
  Role: styled.span`
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 2px 5px;
    font-family: "SFProText";
    font-size: 12px;
    color: #2770e0;
  `,
  Email: styled.span`
    font-family: "PoppinsRegular";
    font-size: 14px;
    color: #888888;
  `,
  ActionIconsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  SearchContainer: styled.div`
    padding: 1.5rem 0;
  `,
  SelectAllContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  CheckBoxLabel: styled.label`
    font-family: "SFProText";
    font-size: 14px;
    color: #888888;
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  NoUsersText: styled.span`
    color: #888888;
    font-family: "Poppins700";
    font-size: 14px;
    padding: 0.5rem;
  `,
  VenueRowContainer: styled.div`
    /* background: #f1f1f1; */
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
  `,
  VenuesRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #c4c4c4;
    border-bottom: ${(props) => (props.lastRow ? "none" : "1px solid #b0bec5")};
  `,
  VenueName: styled.h4`
    margin: 0;
    color: #888888;
    font-family: "Poppins700";
    font-size: 14px;
  `,
  Select: styled.select`
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    height: 30px;
    background: transparent;
    font-family: "Roboto400";
    font-size: 14px;
    padding: 0 0.3rem;
    color: #37474f;
    outline: none;
    cursor: pointer;
  `,
  AssignVenueBtnContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0;
  `,
  UserRole: styled.span`
    font-family: "PoppinsRegular";
    font-size: 14px;
    color: #667085;
  `,
};

export default styles;

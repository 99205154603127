import React, { useState, useContext } from "react";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
//Styles
import Styles from "./styles";
//Icons
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
//Components
import { FormGroup } from "../Shared/FormGroup";
import SpinnerComponent from "../Loader";
//Context
import { AuthContext } from "../../context/authContext";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";

const signInSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email.").required("Email is a required field."),
  password: yup.string().min(4).max(15).required(),
});

const SignInComponent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { handleSignIn, signInGraphQlError, singInLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const submitForm = async (signInFormData) => {
    const loginResults = await handleSignIn(signInFormData.email, signInFormData.password);
    if (loginResults) {
      setLoginErrors(loginResults);
      reset();
    } else {
      navigate("/manage-companies");
      reset();
    }
  };

  usePreventEnterAction(submitForm);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Styles.AuthContainer>
      <Styles.FormContainer>
        <Styles.Title>Sign In</Styles.Title>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup labelText={"Email"} errorMessage={errors.email?.message}>
            <Styles.Input type="text" name="email" {...register("email")} />
          </FormGroup>
          <FormGroup labelText={"Password"} errorMessage={errors.password?.message}>
            <Styles.Input
              type={showPassword ? "text" : "password"}
              name="password"
              onKeyUp={(e) => {
                // if password is filled out and enter is pressed, submit form
                if (e.key === "Enter" && e.target.value.length > 0) {
                  handleSubmit(submitForm)();
                }
              }}
              {...register("password")}
            />
            <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
              <Styles.IconWrapper onClick={handleShowPassword}>
                {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </Styles.IconWrapper>
            </IconContext.Provider>
          </FormGroup>
          {loginErrors ? (
            <Styles.SubmitServerError>{loginErrors}</Styles.SubmitServerError>
          ) : signInGraphQlError ? (
            <Styles.SubmitServerError>{signInGraphQlError}</Styles.SubmitServerError>
          ) : null}
          {singInLoading ? (
            <SpinnerComponent width={40} height={40} />
          ) : (
            <Styles.SubmitInput type="submit" id="submit" value="Sign In" />
          )}
          <Styles.Paragraph forgotPassword>
            <Link to="/forgot-password">Forgot your password?</Link>
          </Styles.Paragraph>
        </Styles.Form>
        <Styles.Paragraph>
          Don&apos;t have an account? <Link to="/request-account">Request an account!</Link>
        </Styles.Paragraph>
      </Styles.FormContainer>
    </Styles.AuthContainer>
  );
};

export default SignInComponent;

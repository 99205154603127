import React from "react";
import Styles from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";

const SearchBar = ({ handleOnChange }) => {
  return (
    <Styles.SearchContainer>
      <Styles.SearchInput type="text" placeholder="Search..." onChange={handleOnChange} />
      <Styles.IconContainer>
        <SearchIcon />
      </Styles.IconContainer>
    </Styles.SearchContainer>
  );
};

export default SearchBar;

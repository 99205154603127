import React from "react";
// Tools
import { Controller } from "react-hook-form";
// Styles
import Styled from "./FormField.styled";

const FormField = ({ control, name, label, placeholder }) => {
  return (
    <Styled.FieldContainer>
      <Styled.InputLabel>{label}</Styled.InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Styled.Input {...field} placeholder={placeholder} />}
      />
    </Styled.FieldContainer>
  );
};

export default FormField;

import React, { useEffect, useState } from "react";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import BlueBtn from "../../../components/Shared/Buttons/BlueBtn";
import { useToastModal } from "../../../context/ToastModalContext";
import "./styles.css";

function CopyWssModal({ showModal, setShowModal, link }) {
  const [isCopied, setIsCopied] = useState(false);
  let cameraLink = "";

  useEffect(() => {
    return setIsCopied(false);
  }, []);

  const { addToast } = useToastModal();

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      addToast("Output Link Copied successfully!", "success");
      setIsCopied(true);
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  if (link.length > 60) {
    cameraLink = link.substring(0, 60).concat("...");
  } else {
    cameraLink = link;
  }

  return (
    <ModalWithChildren
      showModal={showModal}
      setShowModal={setShowModal}
      title={"Copy Output WSS link"}
    >
      <p id="link">{cameraLink}</p>

      <BlueBtn
        handleClick={() => copyTextToClipboard(link)}
        label={!isCopied ? "Copy Link" : "Copied"}
      />
    </ModalWithChildren>
  );
}

export default CopyWssModal;

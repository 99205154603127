import styled from "styled-components/macro";

const styles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Label: styled.label`
    text-align: center;
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
  `,
  DragAndDropContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed;
    border-radius: 5px;
    padding: 15px 0px 15px 0px;
  `,
  DragAndDropFileContainer: styled.div`
    display: flex;
    padding-top: 10px;
    width: 90%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
  `,
  SubmitError: styled.span`
    font-family: "Roboto400";
    font-size: 14px;
    color: #ff3333;
    padding-left: 5px;
    padding-top: 5px;
  `,
};

export default styles;

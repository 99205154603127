import styled from "styled-components/macro";

const styles = {
  UserProfileContainer: styled.div`
    display: flex;
    flex-direction: column;
    background: white;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #c4c4c4;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
};

export default styles;

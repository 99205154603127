import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";

const styles = {
  Background: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,
  // 2rem 5rem
  ModalWrapper: styled.div`
    padding: ${({ slim }) => (slim ? "0.5rem 3rem" : "2rem 5rem")};
    max-width: ${({ maxWidth50, maxWidth500 }) =>
      maxWidth50 ? "50%" : maxWidth500 ? "500px" : null};
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 2rem 3rem;
    }
    max-height: 100vh;
    width: ${({ width }) => (width ? `${width}px` : undefined)};
  `,

  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-height: 95vh;
    overflow-y: scroll;
  `,
  CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    color: #464646;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  `,

  Title: styled.h1`
    font-family: "Poppins700";
    font-size: 20px;
    color: #464646;
    margin: 0;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
};

export default styles;

import React from "react";
//Styles
import BlueBtn from "../../../../components/Shared/Buttons/BlueBtn";

import Styles from "./styles";

const ModelRow = ({ modelVersion, model, updateModel, addVersion }) => {
  return (
    <Styles.TableRowEntriesContainer>
      <Styles.TableRowEntries>
        Ver. {modelVersion.modelVersionNo} {"   "}
        {modelVersion.modelVersionStatus ? "(enabled)" : "(disabled)"}
      </Styles.TableRowEntries>
      <Styles.TableRowEntries>{modelVersion.modelVersionNotes}</Styles.TableRowEntries>
      <Styles.TableRowEntries>
        <Styles.ButtonWrapper>
          <BlueBtn
            label={`Update Ver ${modelVersion.modelVersionNo}`}
            fontSize={11}
            fontFamily="Arial"
            width={80}
            height={20}
            handleClick={() => updateModel({ model, modelVersion })}
          />
          <BlueBtn
            label="Add Ver."
            fontSize={11}
            fontFamily="Arial"
            width={80}
            height={20}
            handleClick={() => addVersion({ model })}
          />
        </Styles.ButtonWrapper>
      </Styles.TableRowEntries>
    </Styles.TableRowEntriesContainer>
  );
};

export default ModelRow;
